const constant =
{
        // baseurl: 'http://' + window.location.hostname + ':8003/api/', // :82
    baseurl: 'https://' + window.location.hostname + '/kids-study-backend/api/', // :82
    urlmail: 'https://' + window.location.hostname + '/#/show-email-instrumentlist/',
    consentMailUrl: 'https://' + window.location.hostname + '/#/interview-email-consent/',
    giftCardMailUrl: 'https://' + window.location.hostname + '/#/gift-card/',
    app_name: 'New Jersey Kids Study (NJKS)',
    app_name_full: 'New Jersey Kids Study',
    app_phone: '(732) 659-4673',
    app_email: 'njks@cabm.rutgers.edu',
    email_app_sign_full: 'The New Jersey Kids Study Team',
    email_app_sign_abbr: 'The NJKS Team',
    email_app_email: 'njks@cabm.rutgers.edu',
    email_app_phone: '(732) 659-4673',
    email_app_public_website: 'https://njks.rutgers.edu',
    english_resource_guide_link: 'https://rutgers.app.box.com/s/i30pvyf83klel81yzp98kmfoin9hmm8m',
    spanish_resource_guide_link: 'https://rutgers.app.box.com/s/by2j1onv7lkjywu8w3iqlloi45vcwgls',
    biospecimen_box_opening_video_link: 'https://www.youtube.com/watch?v=Un2ofPoEkPM&ab_channel=NewJerseyKidsStudy',
    app_link: 'https://njkidsstudy.rutgers.edu/#/login',
    research_study_name: 'New Jersey Kids Study (NJKS)',
    

    tinymce_api_key: '0is7s9qho1wxew3x7qic0mpwhx59vnyy4x2wwef4l325s55d',
    // baseurl : 'https://'+window.location.hostname+':6443/chap/api/', // :82
    // urlmail : 'https://'+window.location.hostname+'/#/show-email-instrumentlist/',
    timezone: 'America/New_York',
    bloodsample: [
        { 'id': 1, 'title': 'Yes' },
        { 'id': 2, 'title': 'No' }
    ],
    watch: [
        { 'id': 1, 'title': 'Undecided' },
        { 'id': 2, 'title': 'Pick' },
        { 'id': 3, 'title': 'Drop' }
    ],
    studytype: [
        { 'id': 1, 'title': 'Regular' },
        { 'id': 2, 'title': 'Kiosk Test' },
        { 'id': 3, 'title': 'Recurring' }
    ],
    testname: [
        { 'id': 1, 'title': 'Test 1' },
        { 'id': 2, 'title': 'Test 2' },
        { 'id': 3, 'title': 'Test 3' }
    ],
    testcompleted: [
        { 'id': 1, 'title': 'Yes' },
        { 'id': 2, 'title': 'No' }
    ],
    languagesArr: [
        { id: 1, language_name: "English", language_display_name: "English" },
        { id: 2, language_name: "Spanish", language_display_name: "Espanol" },
        // { id: 3, language_name: "Chinese", language_display_name: "简体中文" },
        // { id: 4, language_name: "Hindi", language_display_name: "हिन्दी" },
        // { id: 5, language_name: "Korean", language_display_name: "한국어" },
        // { id: 6, language_name: "Gujarati", language_display_name: "ગુજરાતી" },
        // { id: 7, language_name: "Pidgin", language_display_name: "Pidgin" },
        // { id: 8, language_name: "Tagalog", language_display_name: "Tagalog" },
        // { id: 9, language_name: "Urdu", language_display_name: "اُردُو" },
    ],

    consentQuesType: [
        { value: 'radio', text: 'Single Choice' },
        { value: 'checkbox', text: 'Multiple Choice' },
        { value: 'text', text: 'Fill in the blank(text)' },
        { value: 'textarea', text: 'Fill in the blank(textarea)' },
        { value: 'signature', text: 'Signature' },
        { value: 'eligibility', text: 'Eligibility single choice question' }
    ],
    checklistType: [
        { value: 'file', text: 'File' },
        { value: 'checkbox', text: 'Checkbox' }
    ],
    checklistStatis: [
        { value: 0, text: 'No' },
        { value: 1, text: 'Yes' }
    ],
    allowImgExtn: [
        'png', 'jpg', 'jpeg'
    ],
    allowPdfExtn: [
        'pdf'
    ],
    tubeUnitArr: [
        'ml', 'mm'
    ],
    tubeTypeArr: [
        't1', 't2'
    ],
    ltime: 150, // in second divided by 2 ( for 5 minutes 300/2 = 150) (In use only when react_idle_time not received from backend)
    //ltime : 200 // in second divided by 2 ( for 2 minutes 120/2 = 60)
    perPage: 10,

    insuranceArr: [
        { id: 1, insurance: 'Aetna', value: 'aetna' },
        { id: 2, insurance: 'AmeriHealth', value: 'ameri_health' },
        { id: 3, insurance: 'Anthem', value: 'anthem' },
        { id: 4, insurance: 'Centene', value: 'centene' },
        { id: 5, insurance: 'Cigna', value: 'cigna' },
        { id: 6, insurance: 'Horizon Blue Cross/Blue Shield', value: 'horizon_blue_shield' },
        { id: 7, insurance: 'Humana', value: 'humana' },
        { id: 8, insurance: 'Kaiser Permanente', value: 'kaiser_permanente' },
        { id: 9, insurance: 'United Healthcare', value: 'united_healthcare' },
        { id: 10, insurance: 'AmeriChoice', value: 'ameri_choice' },
        { id: 11, insurance: 'AmeriGroup', value: 'ameri_group' },
        { id: 12, insurance: 'AARP', value: 'aarp' },
        { id: 13, insurance: 'Clover Health', value: 'clover_health' },
        { id: 14, insurance: 'Oscar', value: 'oscar' },
        { id: 15, insurance: 'Wellcare', value: 'wellcare' },
        { id: 16, insurance: 'Other (Please specify in comment box)', value: 'other' },
        { id: 17, insurance: 'N/A- Not currently insured', value: 'n/a' },
    ],

    validationArr: [
        { id: 1, label: 'Date (DD-MM-YYYY)', value: 'date_dmy' },
        { id: 2, label: 'Date (MM-DD-YYYY)', value: 'date_mdy' },
        { id: 3, label: 'Date (YYYY-MM-DD)', value: 'date_ymd' },
        { id: 4, label: 'Datetime (MM-DD-YYYY HH:MM)', value: 'datetime_mdy' },
        { id: 5, label: 'Datetime (YYYY-MM-DD HH:MM)', value: 'datetime_ymd' },
        { id: 6, label: 'Datetime w/ seconds (MM-DD-YYYY HH:MM:SS)', value: 'datetime_seconds_mdy' },
        { id: 7, label: 'Datetime w/ seconds (YYYY-MM-DD HH:MM:SS)', value: 'datetime_seconds_ymd' },
        { id: 8, label: 'Email', value: 'email' },
        { id: 9, label: 'Integer', value: 'integer' },
        { id: 10, label: 'Letters Only', value: 'letters_only' },
        { id: 11, label: 'MRN (10 digits)', value: 'mrn_10' },
        { id: 12, label: 'MRN (generic)', value: 'mrn_generic' },
        { id: 13, label: 'Number', value: 'number' },
        { id: 14, label: 'Number (1 decimal place - comma as decimal)', value: 'number_1dp_comma_decimal' },
        { id: 15, label: 'Number (1 decimal place)', value: 'number_1dp' },
        { id: 16, label: 'Number (2 decimal places - comma as decimal)', value: 'number_2dp_comma_decimal' },
        { id: 17, label: 'Number (2 decimal places)', value: 'number_2dp' },
        { id: 18, label: 'Number (3 decimal places - comma as decimal)', value: 'number_3dp_comma_decimal' },
        { id: 19, label: 'Number (3 decimal places)', value: 'number_3dp' },
        { id: 20, label: 'Number (4 decimal places - comma as decimal)', value: 'number_4dp_comma_decimal' },
        { id: 21, label: 'Number (4 decimal places)', value: 'number_4dp' },
        { id: 22, label: 'Phone (North America)', value: 'phone' },
        { id: 23, label: 'Time (HH:MM)', value: 'time' },
        { id: 24, label: 'Time (MM:SS)', value: 'time_mm_ss' },
        { id: 25, label: 'Zipcode (US)', value: 'zipcode' },
    ],
    purpose_of_contact_subquestion_options: [
        { id: 1, label: 'Survey', value: 'Survey' },
        { id: 2, label: 'Saliva', value: 'Saliva' },
        { id: 3, label: 'Actigraphy', value: 'Actigraphy' },
        { id: 4, label: 'Blood Draw', value: 'Blood Draw' },
        { id: 5, label: 'HIPAA', value: 'HIPAA' },
        { id: 6, label: 'Other', value: 'Other' },
    ],

    component_options: [
        { id: 1, label: 'Consent', value: 'Consent' },
        { id: 2, label: 'Survey', value: 'Survey' },
        { id: 3, label: 'Saliva', value: 'Saliva' },
        { id: 4, label: 'Actigraphy', value: 'Actigraphy' },
        { id: 5, label: 'Blood Draw', value: 'Blood Draw' },
        { id: 6, label: 'HIPAA', value: 'HIPAA' },
        { id: 7, label: 'Other', value: 'Other' },
    ],
    contactQuesType: [
        { id: 1, value: 'radio', text: 'Single Choice' },
        { id: 2, value: 'checkbox', text: 'Multiple Choice' },
        { id: 3, value: 'text', text: 'Fill in the blank(text)' },
        { id: 4, value: 'textarea', text: 'Fill in the blank(textarea)' },
        { id: 5, value: 'signature', text: 'Signature' },
        { id: 6, value: 'select', text: 'Select input field' },
    ],
    kit_content_arr: [
        { id: 1, value: 'Urine', label: 'Urine' },
        { id: 2, value: 'Blood', label: 'Blood' },
        { id: 3, value: 'Oral Swab', label: 'Oral Swab' },
        // { id: 4, value: 'Saliva', label: 'Saliva' },
        // { id: 5, value: 'Skin Swab', label: 'Skin Swab' },
        { id: 6, value: 'Vaginal Swab', label: 'Vaginal Swab' },
        { id: 7, value: 'Nasal Swab', label: 'Nasal Swab' },
        { id: 8, value: 'Stool', label: 'Stool' },
        { id: 9, value: 'Forehead', label: 'Forehead' },
        { id: 10, value: 'Forearm', label: 'Forearm' },
    ],
    utm_source_arr: [
        { id: 1, value: 'EHR', label: 'EHR' },
        { id: 2, value: 'Google', label: 'Google' },
        { id: 3, value: 'Facebook', label: 'Facebook' },
        { id: 4, value: 'WhatsApp', label: 'WhatsApp' },
        { id: 5, value: 'Instagram', label: 'Instagram' },
        { id: 6, value: 'TikTok', label: 'TikTok' },
        { id: 7, value: 'LinkedIn', label: 'LinkedIn' },
        { id: 8, value: 'Pinterest', label: 'Pinterest' },
        { id: 9, value: 'YouTube', label: 'YouTube' },
        { id: 10, value: 'Website', label: 'Website' },
        { id: 11, value: 'Word of Mouth', label: 'Word of Mouth' },
        { id: 12, value: 'CBO', label: 'CBO' },
    ],
    tinymce_config: {
        selector: 'textarea#open-source-plugins',
        plugins: 'preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons accordion',
        editimage_cors_hosts: ['picsum.photos'],
        menubar: 'file edit view insert format tools table help',
        toolbar: "undo redo | accordion accordionremove | blocks fontfamily fontsize | bold italic underline strikethrough | align numlist bullist | link image | table media | lineheight outdent indent| forecolor backcolor removeformat | charmap emoticons | code fullscreen preview | save print | pagebreak anchor codesample | ltr rtl",
        autosave_ask_before_unload: true,
        autosave_interval: '30s',
        autosave_prefix: '{path}{query}-{id}-',
        autosave_restore_when_empty: false,
        autosave_retention: '2m',
        image_advtab: true,
        importcss_append: true,
        templates: [
            { title: 'New Table', description: 'creates a new table', content: '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td> </td><td> </td></tr></table></div>' },
            { title: 'Starting my story', description: 'A cure for writers block', content: 'Once upon a time...' },
            { title: 'New list with dates', description: 'New List with dates', content: '<div class="mceTmpl"><span class="cdate">cdate</span><br><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>' }
        ],
        template_cdate_format: '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
        template_mdate_format: '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
        height: 800,
        image_caption: true,
        quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
        noneditable_class: 'mceNonEditable',
        toolbar_mode: 'sliding',
        contextmenu: 'link image table',
        skin: 'oxide',
        content_css: 'default',
        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }',
        automatic_uploads: true,
        images_reuse_filename: true,
        media_live_embeds: true,
        iframe_template_callback: (data) => `<iframe class='tinymce-media-responsive' title="${data.title}" width="${data.width}" height="${data.height}" src="${data.source}"></iframe>`,
        image_class_list: [
            { title: 'none', value: 'tinymce-media-responsive' }
        ],
        video_template_callback: (data) =>
            `<video class='tinymce-media-responsive' style="object-fit:cover;" width="${data.width}" height="${data.height}"${data.poster ? ` poster="${data.poster}"` : ''} controls="controls">\n` +
            `<source src="${data.source}"${data.sourcemime ? ` type="${data.sourcemime}"` : ''} />\n` +
            (data.altsource ? `<source src="${data.altsource}"${data.altsourcemime ? ` type="${data.altsourcemime}"` : ''} />\n` : '') +
            '</video>',
    },
    communicationMethodsArr: [
        { id: 1, value: 'Email', label: 'Email' },
        { id: 2, value: 'Phone', label: 'Phone' },
        { id: 3, value: 'Text Message', label: 'Text Message' },
    ],
}

export default constant
