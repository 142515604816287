export async function AuthApiHelper(url, data = {}, method = 'POST') {
    const token = localStorage.getItem('user_token') ?? ''
    let bearer = 'Bearer ' + token;
    try {
        const res = await fetch(url, {
            method: method,
            withCredentials: true,
            // credentials: 'include',
            headers: {
                'Authorization': token ? bearer : null,
                'X-FP-API-KEY': 'chaptoken',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        const result = await res.json();
        return result;
    }
    catch (error) {
        if (error.toString() === 'SyntaxError: Unexpected token < in JSON at position 0') {
            /* localStorage.removeItem('user_token')
            localStorage.removeItem('role_id')
            let replaceUrl = `${window.location.origin}/login`
            window.location.replace(replaceUrl);
            window.location.reload(); */
        }
    }
}

export function AuthApiCall(url, method, data = {}) {
    let bearer = 'Bearer ' + localStorage.getItem('user_token');
    return fetch(url, {
        method: method,
        withCredentials: true,
        headers: {
            'Authorization': bearer,
            'X-FP-API-KEY': 'chaptoken',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });
}

export async function AuthFileApiHelper(url, data = {}, method = 'POST') {
    const token = localStorage.getItem('user_token') ?? ''
    let bearer = 'Bearer ' + token;
    try {
        const res = await fetch(url, {
            method: method,
            withCredentials: true,
            // credentials: 'include',
            headers: {
                'Authorization': token ? bearer : null,
                'X-FP-API-KEY': 'chaptoken'
            },
            body: data
        });
        const result = await res.json();
        return result;
    }
    catch (error) {
        if (error.toString() === 'SyntaxError: Unexpected token < in JSON at position 0') {
            /* localStorage.removeItem('user_token')
            localStorage.removeItem('role_id')
            let replaceUrl = `${window.location.origin}/login`
            window.location.replace(replaceUrl);
            window.location.reload(); */
        }
    }
}

export async function AsyncAuthApiHelper(url, data = {}, method = 'POST') {
    let bearer = 'Bearer ' + localStorage.getItem('user_token');
    let response = await fetch(url, {  // wait for the promise to be resolved
        method: method,
        withCredentials: true,
        headers: {
            'Authorization': bearer,
            'X-FP-API-KEY': 'chaptoken',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });
    let result = await response.json(); // wait for the to resolved in json
    return result;
}

export function xhrcall(url, method = 'Post') {
    let apirequest = new XMLHttpRequest();
    let bearer = 'Bearer ' + localStorage.getItem('user_token');
    let sdata = { 'language_id': localStorage.getItem('languageid') };
    apirequest.open(method, url, false);
    apirequest.setRequestHeader('Authorization', bearer);
    apirequest.setRequestHeader('Content-Type', 'application/json');
    apirequest.send(JSON.stringify(sdata));
    return apirequest;
}

export async function ApiHelper(url, data = {}, method = 'POST') {
    try {
        const res = await fetch(url, {
            method: method,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        const result = await res.json();
        return result;
    }
    catch (error) { }
}

export function showErrorMsg(errObj, validateInputOnly = []) {
    const errObject = {}
    errObject.isvalid = {};
    errObject.errormsgs = {};
    validateInputOnly.map(function (name) {
        errObject.isvalid[name] = true;
    });
    for (var namekey in errObj) {
        errObject.isvalid[namekey] = false;
        errObject.errormsgs[namekey] = errObj[namekey][0];
    }
    return errObject;
}

export function dualListFilter(curOption, curfilterInput) {
    if (curfilterInput.trim() === '') return true;
    return (new RegExp(curfilterInput, 'i')).test(curOption.label);
}

// export function cryptId(id, act = 'd'){
//     // if(act === 'd'){ // binary to decimal
//     //     var result = parseInt((id.toString()).substring(8), 2);
//     // }else if(act === 'e'){ // decimal to binary
//     //     let randomNum = Math.floor(Math.random()*90000000) + 10000001;
//     //     let binaryNum = (id).toString(2);
//     //     var result = randomNum + binaryNum;
//     // }

//     if(act === 'd'){ // binary to decimal
//         var result = parseInt(id.toString(), 2);
//     }else if(act === 'e'){ // decimal to binary
//         let binaryNum = (id).toString(2);
//         var result = binaryNum;
//     }
//     return result;
// }

// above is previous enc code
export function cryptId(id = 0, act = 'd') {
    if (act === 'd') { // binary to decimal
        var result = atob(decodeURIComponent(id));
    } else if (act === 'e') { // decimal to binary
        var result = encodeURIComponent(btoa(id.toString()));
    }
    return result;
}

// export function newCryptId(str, act = 'd'){
//     let result = '';
//     const mySecretKey = 'CHAPENCKEYCUSTOM';
//     const iv = 'abcdef9876543210abcdef9876543210';
//     const encStr = CryptoJS.AES.encrypt(str.toString(), mySecretKey, { iv}).toString();
//     result = encodeURIComponent(encStr);
//     console.log(result);
// }

// export function newCryptId(str, act = 'd'){
//     const mySecretKey = 'CHAPENCKEYCUSTOM';
//     let result = '';
//     if(act === 'd'){
//         const encDecString = decodeURIComponent(str);
//         result = CryptoJS.AES.decrypt(encDecString, mySecretKey).toString(CryptoJS.enc.Utf8);
//     }else if(act === 'e'){
//         const encStr = CryptoJS.AES.encrypt(str.toString(), mySecretKey).toString();
//         result = encodeURIComponent(encStr);
//     }
//     return result;
// }

//
export function redAsterick(inputNameArray = []) {
    var fieldObj = JSON.parse(localStorage.getItem('mandetoryfield'));
    let sendObj = {};
    inputNameArray.map(function (val) {
        sendObj[val] = fieldObj[val];
    });
    return sendObj;
}

export function randomizeQuestions(array) {

    const questions = array
    let parentQuestions = questions.filter(q => q.is_parent === 1);
    let childQuestions = questions.filter(q => q.is_parent === 0);

    //randomizing parent questions
    for (let i = parentQuestions.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        const temp = parentQuestions[i];
        parentQuestions[i] = parentQuestions[j];
        parentQuestions[j] = temp;
    }

    //arranging child questions to their parent questions
    let allQuestions = manageChildQuestions(parentQuestions, childQuestions)
    return allQuestions;
}

function manageChildQuestions(parentQuestions, childQuestions) {
    let newQuestionsArr = parentQuestions
    childQuestions.forEach((question) => {
        if (question.is_parent === 0) {
            let seq = question.sq - 1;
            let parentQuestionIndex = parentQuestions.findIndex(q => q.sq == seq)
            newQuestionsArr.splice(parentQuestionIndex + 1, 0, question);
        }
    })
    return newQuestionsArr
}

export function shuffleArray(array) {

    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        const temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }
    return array;
}

export function arrayInSequence(sequnceArray, arrayToArrangeInSequence) {

    let finalArray = [];

    sequnceArray.map((id) => {
        let itemToPush = arrayToArrangeInSequence.find(item => item.id == id)
        itemToPush && finalArray.push(itemToPush);
    })
    return finalArray;

}

export const getNonEmptyValues = (obj) => {
    //
    Object.keys(obj).forEach(function (key) {
        if (obj[key] === null) {
            delete obj[key];
        }
    });

    return obj;
};

export async function AuthApiHelperGET(url, method = 'GET') {
    const token = localStorage.getItem('user_token') ?? ''
    let bearer = 'Bearer ' + token;
    try {
        const res = await fetch(url, {
            method: method,
            withCredentials: true,
            // credentials: 'include',
            headers: {
                'Authorization': token ? bearer : null,
                'X-FP-API-KEY': 'chaptoken',
                'Content-Type': 'application/json'
            },
        });
        const result = await res.json();
        return result;
    }
    catch (error) {
        if (error.toString() === 'SyntaxError: Unexpected token < in JSON at position 0') {
            /* localStorage.removeItem('user_token')
            localStorage.removeItem('role_id')
            let replaceUrl = `${window.location.origin}/login`
            window.location.replace(replaceUrl);
            window.location.reload(); */
        }
    }
}

export const findIfVariableExist = (questionContent) => {
    let extractedString = null;
    const inputString = questionContent;
    const regex = /\[([^\]]+)\]/;
    const match = inputString.match(regex);

    if (match) {
        extractedString = match[1];
    }

    return extractedString;
}

export const getAge = (dob) => {
    var ageDifMs = Date.now() - dob.getTime();
    var ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
}

export async function AuthApiHelperBlob(url, data = {}, method = 'POST') {
    let bearer = 'Bearer ' + localStorage.getItem('user_token');
    try {
        const res = await fetch(url, {
            method: method,
            withCredentials: true,
            headers: {
                'Authorization': bearer,
                'X-FP-API-KEY': 'chaptoken',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        const result = await res.blob();
        return result;
    }
    catch (error) {
        if (error.toString() === 'SyntaxError: Unexpected token < in JSON at position 0') {
        }
    }
}

export const GetSpecimenTypeColor = (specimen_type) => {
    let icon_color = '';

    switch (specimen_type) {

        case "Forehead":
            icon_color = "#8548ab"; //purple
            break;

        case "Forearm":
            icon_color = "#5367fc"; //blue
            break;

        case "Urine":
            icon_color = "#FFA500"; //orange
            break;

        case "Blood":
            icon_color = "#f562e6"; //pink
            break;

        case "Oral Swab":
            icon_color = "#f7f765"; //yellow
            break;

        case "Saliva":
            icon_color = "#5367fc"; //blue
            break;

        case "Skin Swab":
            icon_color = "#8548ab"; // purple
            break;

        case "Vaginal Swab":
            icon_color = "#f54540"; //red
            break;

        case "Nasal Swab":
            icon_color = "#66d466"; //green
            break;

        case "Stool":
            // icon_color = "#FFFFFF" //white
            icon_color = "#F2F3F5" //white
            break;

        default:
            break;
    }
    return icon_color;


}

export function isInterviewPercentageGreaterOrEqual100(interview_percentage) {
    if (interview_percentage === null) {
        return false;
    }

    // If the value is a number, directly compare it
    if (typeof interview_percentage === 'number') {
        return interview_percentage >= 100;
    }

    // Use a regular expression to extract the number part if it's a string
    const match = interview_percentage.match(/(\d+)/);
    if (match === null) {
        return false;
    }

    // Convert the extracted part to a number
    const percentage = parseInt(match[0], 10);

    // Check if the percentage is greater than or equal to 100
    return percentage >= 100;
}

export const decryptID = (id) => {
    if (!id) {
        return null;
    }
    return cryptId(id, 'd');

}

export const cryptIdWithException = (id = 0, action = 'd') => {
    try {
        if (id) return cryptId(id, action);
        else return null;
    } catch (error) {
        return null;
    }
}




