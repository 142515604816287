import React from 'react';
import locale from './views/Js/Language';

let ln = localStorage.getItem('language');
ln = (ln == undefined) ? 'english' : ln;
ln = (locale[ln] !== undefined) ? ln : 'english';

/* custom routes from here */

const ShowInstrumentList = React.lazy(() => import('./views/EmailInterviewManagement/ShowInstrumentList'));
const InterviewConsent = React.lazy(() => import('./views/EmailInterviewManagement/InterviewConsent'));
const InterviewStart = React.lazy(() => import('./views/EmailInterviewManagement/InterviewStart'));
const ThankyouPage = React.lazy(() => import('./views/EmailInterviewManagement/ThankyouPage'));
const PreInterviewScreen = React.lazy(() => import('./views/EmailInterviewManagement/PreInterviewScreen'));

//gift card
const GiftCard = React.lazy(() => import('./views/GiftCard/GiftCard'));
const ThankYouGiftCardPage = React.lazy(() => import('./views/GiftCard/ThankYouGiftCardPage'));

//workflow
const NoAuthWokflowInterview = React.lazy(() => import('./views/Workflows/NoAuthWokflowInterview'));
const NoAuthParticipantStudy = React.lazy(() => import('./views/Workflows/NoAuthParticipantStudy'));
const NoAuthEConsent = React.lazy(() => import('./views/Workflows/NoAuthEConsent'));
const ParticipantContactForm = React.lazy(() => import('./views/Workflows/ParticipantContactForm'));
const NoAuthParticipationClosePage = React.lazy(() => import('./views/Workflows/NoAuthParticipationClosePage'));
const NoAuthWorkflowInterviewLinkExpirePage = React.lazy(() => import('./views/Workflows/NoAuthWorkflowInterviewLinkExpirePage'));
const NoAuthWorkflowInterviewCompletePage = React.lazy(() => import('./views/Workflows/NoAuthWorkflowInterviewCompletePage'));
const NoAuthWorkflowIneligibleForStudyPage = React.lazy(() => import('./views/Workflows/NoAuthWorkflowIneligibleForStudyPage'));
const NoAuthWorkflowInterviewLocked = React.lazy(() => import('./views/Workflows/NoAuthWorkflowInterviewLocked'));

const ResetPassword = React.lazy(() => import('./views/GeneralSetting/ResetPassword'));



/* custom paths */

const routes = [

    // For Email Interview Management
    { path: '/show-email-instrumentlist/:aid/:pid/:eid', exact: true, name: 'Instrument List', component: ShowInstrumentList },
    { path: '/interview-email-consent/:aid/:pid/:eid/:mid', exact: true, name: 'Interview Consent', component: InterviewConsent },
    { path: '/start-email-interview/:aid/:pid/:eid/:mid', exact: true, name: 'Start Interview', component: InterviewStart },
    { path: '/thankyou-email-page', exact: true, name: `${locale[ln].thankyou}`, component: ThankyouPage },
    { path: '/interview-introduction/:aid/:pid/:eid/:mid', exact: true, name: `${locale[ln].thankyou}`, component: PreInterviewScreen },

    //direct email interview consent
    { path: '/interview-email-consent/:aid/:pid/:eid', exact: true, name: 'Interview Consent', component: InterviewConsent },

    //gift card
    { path: '/gift-card/:gift_card_id', component: GiftCard },
    { path: '/thank-you-gift-card', component: ThankYouGiftCardPage },

    //workflow
    { path: '/start-study/:participant_id?/:view_screen_index?', component: NoAuthParticipantStudy },
    { path: '/workflow-econsent/:aid/:pid/:eid/:mid?', exact: true, name: 'Interview Consent', component: NoAuthEConsent },
    { path: '/workflow-start-interview/:aid/:pid/:eid/:mid', exact: true, name: 'Start Interview', component: NoAuthWokflowInterview },
    { path: '/view-screening-tool-interview/:aid/:pid/:eid/:mid', exact: true, name: 'Screening Interview', component: NoAuthWokflowInterview },
    { path: '/workflow-participant-contact/:workflow_id/:participant_id/:interview_id/:instrument_id', exact: true, name: 'Participant Contact Form', component: ParticipantContactForm },
    { path: '/workflow-participation-close', exact: true, name: 'Participation Close', component: NoAuthParticipationClosePage },
    { path: '/workflow-link-expired', exact: true, name: 'Link Expired', component: NoAuthWorkflowInterviewLinkExpirePage },
    { path: '/workflow-interview-complete/:interview_id?', exact: true, name: 'Interview Completed', component: NoAuthWorkflowInterviewCompletePage },
    { path: '/ineligible-for-study', exact: true, name: 'Ineligible for study', component: NoAuthWorkflowIneligibleForStudyPage },
    { path: '/interview-locked', exact: true, name: 'Interview Temporarily Locked', component: NoAuthWorkflowInterviewLocked },

    { path: '/reset-password/:token', exact: true, name: 'Reset Password', component: ResetPassword },



];

export default routes;