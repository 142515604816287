import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { renderRoutes } from 'react-router-config';
import './App.scss';
import routes from './emailroutes';
import TagManager from 'react-gtm-module'
import { AuthApiHelper } from './views/Js/Helper';
import constant from './views/Js/Constant';
import ReactGA from "react-ga4";

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

const Login = React.lazy(() => import('./views/Pages/Login'));
// const Register = React.lazy(() => import('./views/Pages/Register'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));

const tagManagerArgs = {
  gtmId: 'YOUR GTM ID',
}

TagManager.initialize(tagManagerArgs)

class App extends Component {

  constructor(props) {
    super(props)
    this.state = {
      measurement_ids: [],
    }
  }

  getGoogleAnalyticsMeasurementIds = async () => {
    let url = `${constant.baseurl}get-google-analytics-measurement-ids`;
    AuthApiHelper(url, {})
      .then(response => {
        if (response !== undefined && response.status) {
          const measurement_ids = response.measurement_ids ? response.measurement_ids : [];
          this.setState({ measurement_ids: measurement_ids });
          if (measurement_ids && measurement_ids.length > 0) {
            ReactGA.initialize(measurement_ids);
            ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Home" });
          }
        }
      });
  }

  componentDidMount() {
    this.getGoogleAnalyticsMeasurementIds();
  }

  render() {
    return (
      <>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover
          theme="dark"
        />
        <HashRouter>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
              {/* <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} /> */}
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
              {routes.map((route, idx) => {
                return route.component ? (
                  <Route
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    render={props => (
                      <route.component {...props} />
                    )} />
                ) : (null);
              })}

              <Route path="/" name="Home" render={props => <DefaultLayout {...props} />} />
            </Switch>
          </React.Suspense>
        </HashRouter>

      </>
    );
  }
}

export default App;
