import constant from "./Constant"

const language = {
  english: {
    dashboard: 'Dashboard',
    system: 'SYSTEM',
    master: 'Master',
    language: 'Language',
    languages: 'Languages',
    category: 'Category',
    country: 'Country',
    countries: 'Countries',
    state: 'State',
    states: 'States',
    city: 'City',
    streettype: 'Street Type',
    street: 'Street',
    direction: 'Direction',
    residence: 'Residence',
    residences: 'Residences',
    recruitment: 'Recruitment',
    recruitments: 'Recruitments',
    socialgroup: 'Social Group',
    socialgroups: 'Social Groups',
    speakinglanguages: 'Speaking Languages',
    purpose: 'Purpose',
    purposes: 'Purposes',
    typeofcontact: 'Type Of Contact',
    typeofcontacts: 'Type Of Contacts',
    syssetting: 'System Settings',
    contactsetting: 'Contact Settings',
    user: 'User',
    users: 'Users',
    role: 'Role',
    gensetting: 'General Settings',
    myprofile: 'My Profile',
    changepassword: 'Change Password',
    databank: 'INTERVIEW AND DATABANK',
    participant: 'Participant',
    participants: 'Participants',
    instrument: 'Instrument',
    interview: 'Interview',
    name: 'Name',
    action: 'Action',
    edit: 'Edit',
    delete: 'Delete',
    add: 'Add',
    back: 'Back',
    update: 'Update',
    srno: 'Sr. No',
    submit: 'Submit',
    reset: 'Reset',
    enter: 'Enter',
    categories: 'Categories',
    select: 'Select',
    email: 'Email',
    first: 'First',
    last: 'Last',
    username: 'Username',
    other: 'Other',
    regulation: 'Regulation',
    id: 'id',
    dob: 'Date of birth',
    gender: 'Gender',
    address: 'Address',
    address2: 'Address 2',
    zip: 'Zip',
    home: 'Home',
    work: 'Work',
    phone: 'Phone',
    cell: 'Cell',
    phone_1: 'Phone No. 1',
    phone_2: 'Phone No. 2',
    phone_3: 'Phone No. 3',
    phone_4: 'Phone No. 4',
    comment: 'Comment',
    waveidentifier: 'Wave Identifier',
    puma: 'PUMA',
    psu: 'PSU',
    ssu: 'SSU',
    ifh_id: 'IFH ID',
    profile: 'Profile',
    picture: 'Picture',
    password: 'Password',
    confirm: 'Confirm',
    old: 'Old',
    new: 'New',
    age: 'Age',
    speaking: 'Speaking',
    reading: 'Reading',
    writing: 'Writing',
    preference: 'Preference',
    ifother: 'If Other',
    abbreviation: 'Abbreviation',
    content: 'Content',
    question: 'Question',
    questions: 'Questions',
    details: 'Details',
    up: 'up',
    down: 'down',
    copy: 'Copy',
    option: 'Option',
    from: 'From',
    options: 'Options',
    label: 'Label',
    help: 'Help',
    parent: 'Parent',
    istrigger: 'Is Trigger',
    abbrivation: 'Abbrivation',
    for: 'for',
    assigned: 'Assigned',
    assign: 'Assign',
    staff: 'Staff',
    status: 'Status',
    type: 'Type',
    contact: 'Contact',
    contacts: 'Contacts',
    previous: 'Previous',
    date: 'Date',
    time: 'Time',
    start: 'Start',
    next: 'Next',
    save: 'Save',
    list: 'List',
    kinship: 'Kinship',
    kinships: 'kinships',
    tag: 'Tag',
    tags: 'Tags',
    schedule: 'Schedule',
    scheduled: 'Scheduled',
    field: 'Field',
    mandatory: 'Mandatory',
    isrequire: 'Is Required?',
    conduct: 'Conduct',
    pause: 'Pause',
    assignment: 'Assignment',
    relationship: 'Relationship',
    with: 'With',
    method: 'Method',
    bloodsample: 'Blood Sample',
    tubecount: 'Tube Count',
    bsd: 'Date',
    watch: 'Watch',
    pddate: 'Date',
    code: 'Code',
    coding: 'Coding',
    compltreport: 'Completion Report',
    percentage: 'Percentage',
    answer: 'Answer',
    thankyou: 'Thankyou',
    display: 'Display',
    sendemail: 'Send Email',
    emailsent: 'Email Sent',
    preview: 'Preview',
    consent: 'Consent',
    studyid: 'Study Status',
    familyid: 'FamilyId',
    hascomment: 'Has Comment',
    participantid: 'ParticipantId',
    section: 'Section',
    child: 'Child',
    parent: 'Parent',
    speaking_language: 'Speaking Language',
    study_type: 'Study Type',
    test_name: 'Test Name',
    test_completed: 'Completed',
    view_details: 'View Details',
    apt: 'Apt',
    total: 'Total',
    assignments: 'Assignments',
    mockparticipant: 'Mock Participant',
    reassign: 'Reassign',
    completion: 'Completion',
    clone: 'Clone',
    sync: 'Sync',
    contacted: 'Contacted',
    not_contacted: 'Not Yet Contacted',
    history: 'History',
    device: 'Device',
    sms_template: 'SMS Template',
    description: 'Description',
    checklist: 'Checklist',
    history: 'History',
    item: 'Item',
    price: 'Price',
    quantity: 'Quantity',
    purchase: 'Purchase',
    ref_no: 'Ref No.',
    purchase_by: 'Purchased By',
    dispatch: 'Dispatch',
    receive: 'Receive',
    phlebotomy: 'Phlebotomy and Saliva Collection',
    equipment: 'Equipment',
    tube: 'Tube',
    test: 'Test',
    barcode: 'Barcode',
    qrcode: 'QR code',
    capacity: 'Capacity',
    unit: 'Unit',
    inventory_no: 'Inventory No',
    draw_from: 'Blood Drawn From',
    filled_to_capacity: 'Filled To Capacity',
    second_attempt: 'Second Attempt',
    end: 'End',
    result: 'Result',
    result_recorded_date: 'Result Recorded Date',
    result_recorded_time: 'Result Recorded Time',
    anomalies: 'Anomalies',
    normal_range: 'Normal Range',
    notes: 'Notes',
    need_review: 'Need Review',
    ldd: 'LDD',
    lloq: 'LLOQ',
    selected: 'Selected',
    current: 'Current',
    start_time: 'Start Time',
    end_time: 'End Time',
    assign_date: 'Assign Date',
    unassign_date: 'Unassign Date',
    number: "Number",
    canvassing: 'Canvassing',
    association: 'Association',
    rds: 'RDS',
    coupon: 'Coupon',
    recruitment: 'Recruitment',
    mark: 'Mark',
    as: 'as',
    complete: 'Complete',
    remove: 'Remove',
    completion: 'Completion',
    of: 'of',
    reason: 'Reason',
    isCompleted: 'Is Completed',
    transfer: 'Transfer',
    householdid: 'Household ID',
    familyid: 'Family ID',
    date_consent_taken: 'Date Consent Taken',
    date_scanned: 'Date Scanned',
    file: 'File',
    upload: 'Upload',
    paper: 'Paper',
    remind: 'Remind',
    me: 'me',
    notifications: 'Notifications',
    reminders: 'Reminders',
    alerts: 'Alerts',
    title: 'Title',
    alertTopic: 'Alert topic',
    sendHowManyTimes: 'Send it how many times?',
    whenToSendAlert: 'When to send alert?',
    message: 'Message',
    settings: 'Settings',
    to: 'To',
    subject: 'Subject',
    alertExpiration: 'Alert Expiration',
    lot_number: 'Lot Number',
    tube_status: 'Tube Status',
    ups_tracking_no: 'UPS Tracking Number',
    date: 'Date',
    tube_fill_status: 'Tube Fill Status',
    phlebotomy_saliva: 'Phlebotomy/Saliva',
    tube_container: 'Tube/Container',
    data_entry_blood_saliva: 'DATA ENTRY FOR BLOOD DRAW AND SALIVA',
    data_entry_for: 'Data Entry For',
    eligibility: 'Eligibility',
    randomize_questions: 'Randomize Questions',
    randomize_options: 'Randomize Options',
    ethnicity: 'Ethnicity',
    sendConsentEmail: 'Send Consent Email',
    consentEmailSent: 'Consent Email Sent',
    data_collection_type: 'Data Collection Type',
    view: 'View',
    generate: 'Generate',
    triageReport: 'Triage Report',
    open: 'Open',
    time_point: 'Time Point',
    staff_id: 'Staff ID',
    participant_id: 'Participant ID',
    reviewed_by_partner: 'Reviewed By Partner',
    interview1_date_time: 'Interview 1: Date and time',
    interview1_completion_status: 'Interview 1: Completion Status',
    interview2_date_time: 'Interview 2: Date and time',
    interview2_completion_status: 'Interview 2: Completion Status',
    interview3_date_time: 'Interview 3: Date and time',
    interview3_completion_status: 'Interview 3: Completion Status',
    interview4_date_time: 'Interview 4: Date and time',
    interview4_completion_status: 'Interview 4: Completion Status',
    interview5_date_time: 'Interview 5: Date and time',
    interview5_completion_status: 'Interview 5: Completion Status',
    interview6_date_time: 'Interview 6: Date and time',
    interview6_completion_status: 'Interview 6: Completion Status',
    gift_card: 'Gift Card',
    transcribed: 'Transcribed',
    referral: 'Referral',
    race: 'Race',
    reason: 'Reason',
    interview1_staff: 'Interview 1: Staff',
    interview2_staff: 'Interview 2: Staff',
    interview3_staff: 'Interview 3: Staff',
    interview4_staff: 'Interview 4: Staff',
    interview5_staff: 'Interview 5: Staff',
    interview6_staff: 'Interview 6: Staff',
    shelf_number: 'Shelf Number',
    tube_rack_number: 'Tube Rack Number',
    sample_position_in_tube_rack: 'Sample Position in Tube Rack',
    link_or_card: 'Link or Card',
    card: 'Card',
    ra_staff: 'RA/ Staff',
    date_sent: 'Date Sent',
    date_receipt_received: 'Date Receipt Received',
    date_of_activation: 'Date Of Activation If Sent By Mail',
    gift_card_voucher: 'Gift Card & Voucher',
    tracking: 'Tracking',
    unassigned_report: 'Unassigned Report',
    insurance: 'Insurance',
    participant_info_report: 'Participant Info Report',
    validate: 'Validate',
    verify: 'Verify',
    answer_id: 'Answer ID',
    instrument_id: 'Instrument ID',
    language_id: 'Language ID',
    question_id: 'Question ID',
    deleted_quetions_answers: 'Deleted Questions Answers',
    triggered_quetions_answers: 'Triggered Questions Answers',
    no_parent_quetions_answers: 'No Parent Triggered Questions Answers',
    deleted_options_answers: 'Deleted Options Answers',
    incomp_report: 'Incompletion Report',
    partial_comp_report: 'Partially Completed Report',
    last: 'Last',
    contact: 'Contact',
    details: 'Details',
    of: 'Of',
    reluctance: 'Reluctance',
    severity: 'Severity',
    contacted: 'Contacted',
    who: 'Who',
    not_contacted_yet: 'Not contacted yet',
    not_interviewed_yet: 'Not interviewed yet',
    total_assigned: 'Total Assigned',
    not_contacted_and_interviewed_report: 'Not Contacted & Interviewed Report',
    send: 'Send',
    cancel: 'Cancel',
    amount: 'Amount',
    event: 'Event',
    survey: 'Survey',
    coordinator_only: 'Coordinator Only',
    date_of_signature: 'Date of Signature',
    date_of_filing: 'Date of Filing',
    date_of_interview: 'Date of Interview',
    name_of_subject: 'Name of Subject',
    signature_of_subject: 'Signature of Subject',
    interviewer_initials: 'Interviewer Initials',
    dear: 'Dear',
    gift_card_para_1: 'Thank you for your recent participation in our research! Community members like you play an important role in our work, and we thank you again for adding your voice to our community. We could not do this without you!',
    gift_card_para_2_sentence_1: 'As discussed, enclosed is your gift card in the amount of ',
    gift_card_para_2_sentence_2: ' This card can be used at any store or online merchant nationwide. To use the card, follow the instructions emailed to you and sign the receipt below to acknowledge receiving the card. Should you have any questions or difficulties in completing the form, please contact us at: ',
    gift_card_para_2_sentence_3: ' or call us at ',
    gift_card_para_3_sentence_1: "And don't forget to join our community. From seminars, to outreach, to our ongoing research, there is always something happening. If you are interested in learning more about us and future events, just visit our website, ",
    gift_card_para_3_sentence_2: " We couldn't do what we do without your support!",
    gift_card_para_4_sentence_1: "Thank you for taking part in this study. By signing below, you acknowledge receiving ",
    gift_card_para_4_sentence_2: " for participation in this study.",
    sincerely: "Sincerely",
    gift_card: 'Gift Card',
    organization_line: 'Organization Line',
    address_line: 'Address Line',
    website: 'Website',
    handle: 'Handle',
    fax: 'Fax',
    logo: 'Logo',
    team: "Team",
    review: 'Review',
    would_you_like_to_review: "Would you like to review the consent before submitting the interview?",
    consent_warning_note: "Note: After interview completion, the consent will be locked, and you will not be able to modify the answers. Aww yeah, you successfully read this important alert message. This example text is going to run a bit longer so that you can see how spacing within an alert works with this kind of content.",
    updated_at: 'Updated At',
    recent: 'Recent',
    social_media: 'Social Media',
    url: 'URL',
    image: 'Image',
    interview_expiration_date: 'Interview Expiration Date',
    redirect_url: 'Redirect Url',
    saliva: 'Saliva',
    ID: 'ID',
    collected: 'Collected',
    in_person_or_mail: 'In person or Mail',
    arrival_date: 'Arrival Date',
    mandatory_survey: 'Mandatory Survey',
    supplemental_survey: 'Supplemental Survey',
    actigraphy: 'Actigraphy',
    blood_draw: 'Blood Draw',
    signature: 'Signature',
    mail: 'Mail',
    errors: 'Errors',
    oa_ma_report: 'OA & MA Report',
    percent_completion_of: 'Percent Completion Of',
    wave: 'Wave',
    criteria: 'Criteria',
    report: 'Report',
    physical: 'Physical',
    cognitive_assessment: 'Cognitive Assessment',
    assessment: 'Assessment',
    lab: 'Lab',
    sent: 'Sent',
    signature_of_investigator: 'Signature of Investigator/Individual Obtaining Consent/Assent',
    to_the_best_ability: 'To the best of my ability, I have explained and discussed the full contents of the study including all of the information contained in this consent form. All questions of the research subject and those of his/her parent or legal guardian have been accurately answered.',
    investigator_person: 'Investigator/Person Obtaining Consent Name (Print)',
    outbound_tracking_number: 'Outbound Tracking Number',
    outbound_ship_date: 'Outbound Ship Date',
    inbound_tracking_number: 'Inbound Tracking Number',
    inbound_ship_date: 'Inbound Ship Date',
    storage_date: 'Storage Date',
    shelf: 'Shelf',
    additional_details: 'Additional Details',
    sent_via_email: "if sent via email",
    signed: 'Signed',
    responded: 'Responded',
    family: 'Family',
    cohort: 'Cohort',
    close: 'Close',
    tree: 'Tree',
    validation: 'Validation',
    variable: 'Variable',
    duplicate: 'Duplicate',
    hospital: 'Hospital',
    field_is_embedded: 'Field is embedded elsewhere',
    enumeration: "Enumeration",
    eligible: 'Eligible',
    not_available: 'Not Available',
    follow_up: 'Follow Up',
    reminder: 'Reminder',
    scheduling: 'Scheduling',
    location: 'Location',
    component: 'Component',
    trigger: 'Trigger',
    show: "Show",
    is_enumeration_informant: 'Is Enumeration Informant?',
    probability_or_immigrant_sample: 'Probability or Immigrant sample?',
    full: 'Full',
    total_household_members: 'Total Household Members',
    total_family_members: 'Total Family Members',
    download: 'Download',
    progress: "Progress",
    additional_display_information: 'Additionl Display Information',
    self_directed_interview: "Self Directed Interview Completion Time Display Screen",
    required: 'Required',
    this_questionnaire: "This Questionnaire will take approximately",
    to_complete: "to complete.",
    complete_questionnaire: "Complete Questionnaire",
    now: 'Now',
    later: 'Later',
    web_or_phone: 'Web Or Phone',
    in_person_dispatch: 'In Person Dispatch',
    ssrs: 'SSRS',
    biospecimen: 'Biospecimen',
    kit: 'Kit',
    shipment_carrier: 'Shipment Carrier',
    outbound: 'Outbound',
    inbound: 'Inbound',
    condition_on_receipt: 'Condition on Receipt',
    laboratory_receipt_date: 'Laboratory Receipt Date',
    laboratory_receipt_condition: 'Laboratory Receipt Condition',
    specimen_type: 'Specimen Type',
    lab_biospecimen_processing: 'Lab Biospecimen Processing',
    storage_information: 'Storage Information',
    storage_location: 'Storage Location',
    storage_temperature: 'Storage Temperature',
    storage_start_date: 'Storage Start Date',
    review_date: 'Review Date',
    spinning_date: 'Spinning Date',
    aliquoting_date: 'Aliquoting Date',
    sample_position_in_rack: 'Sample Position in Rack',
    rack_number: 'Rack Number',
    google_analytics: 'Google Analytics',
    measurement: 'Measurement',
    update_and_resend: 'Update and Resend Gift Card',
    update_without_resend: 'Update Without Resending Gift Card',
    multimedia: 'Multimedia',
    format: 'Format',
    optional_file_attachment: 'File attachment image, audio or video (Optional):',
    provide_video_url: 'Provide video URL',
    workflows: 'Workflows',
    workflow: 'Workflow',
    add_interview_workflow: 'Add Interviews in the workflow',
    active_after_completion: 'Active after completion of previous questionnaire.',
    flow: 'Flow',
    econsent: 'E-consent',
    duration: 'Duration',
    unit: 'Unit',
    active_after_consent: 'Active after completion of e-consent',
    active_in: 'Active in',
    after_completion: 'after completion of previous questionnaire',
    active_on_after: 'Active on/after',
    active_condition: 'Active condition',
    active_on_date: 'Active On Date',
    biospecimen_kit_status: 'Biospecimen Kit Status',
    and_kit_status: "and when previous interview's biospecimen kit status is",
    active_instantly: 'Active <strong> instantly </strong>',
    screening_tool: 'Screening Tool',
    information: 'Information',
    study: 'Study',
    video_acknowledgment: 'Have you completed watching the entire video?',
    yes: 'Yes',
    no: 'No',
    step: 'Step',
    study_overview: 'Study Overview Video',
    continue: 'Continue',
    not_activated_yet: 'Interview Not Activated Yet!',
    not_finished: 'Not Finished!',
    stage: 'Stage',
    disagreed: 'Disagreed',
    eligible_for_study: 'Eligible for study!',
    ineligible_for_study: 'Ineligible for study!',
    agreed: 'Agreed',
    optional_screening_tool_eligibility_condition: 'Screening Tool Eligibility Condition (Optional)',
    condition: 'Condition',
    value: 'Value',
    seperate_multiple_values: 'Separate multiple values with commas. The condition will be true if any one matches.',
    overview: 'Overview',
    video: 'Video',
    print: 'Print',
    individual: 'Individual',
    please_enter_email: 'Please enter your email address',
    change: 'Change',
    self_collect_blood_permission: 'Are you interested in a self-collected blood draw using the TAP Micro device.',
    i_am_in: 'I am in',
    i_am_out: 'I am out',
    member: 'Member',
    and_when_econsent_agreed: 'and when e-consent is agreed.',
    text: 'Text',
    hint: 'Hint',
    placeholder: 'Placeholder',
    default: 'Default',
    introduction: 'Introduction',
    identifier_info_text: 'Does the field contain identifying information (e.g. name, SSN, address)',
    required_info_text: '* Prompt if field is blank',
    variable_name_info_text: 'Only letters, numbers & underscores',
    disable_info_text: "Choose 'Yes' to hide, 'No' to keep this question active.",
    help_info_text: "Additional information below the question",
    placeholder_info_text: "Enter text to display inside the field.",
    default_value_info_text: "Automatically fill this field with a preset value.",
    auto: 'Auto',
    check_errors_other_lang_instruments: "Check for errors in interview's other language instruments!",
    check_errors_other_lang_instruments_info: "Ensure accuracy in interview instruments by reviewing for potential issues such as mismatched question counts in other language instruments, discrepancies in question types, options count, and validations like whether a question is required, has comments, or serves as an identifier.",
    participants_having_deleted_questions_answers: "Participants having deleted question's answer in the interview!",
    participants_having_deleted_questions_answers_info: "Verify if participants have submitted answers for questions that were deleted from the instruments in the interview.",
    check_errors_other_lang_consent: "Check for errors in other language consent!",
    check_errors_other_lang_consent_info: "Ensure accuracy in consent by reviewing for potential issues such as mismatched section or question counts in other language consent, discrepancies in question types, options count, and validations like whether a question is required.",
    multilingual: 'Multilingual',
    stay: 'Stay',
    leave: 'Leave',
    workflow_not_eligible_text: `Thank you for your interest in the ${constant.app_name}! Unfortunately, at this time, your responses indicate that you would not be eligible to participate in the study. Please feel free to help spread the word about this study to friends or family members that you think may be eligible to join the study.`,
    congratulations: 'Congratulations',
    congratulations_screen_text: `Based on the answers you provided in the screening survey, <h5 class='d-inline'> you are eligible to participate in the study. </h5> Be sure to watch the ${constant.app_name} overview video to learn more about the study and what’s involved in participating to see if the study is the right fit for you.`,
    best_wishes: 'Best Wishes',
    the_ks_team: `${constant.email_app_sign_full}`,
    show_input_field: 'Show Input Field',
    aliquot: 'Aliquot',
    specimen: 'Specimen',
    freezer: 'Freezer',
    tube_freezer_storage_box_name: 'Tube Freezer Storage Box Name',
    tube_freezer_storage_box_position_name: 'Tube Freezer Storage Box Position Name',
    source: 'Source',
    pregnant: 'Pregnant',
    rows: 'Rows',
    columns: 'Columns',
    create: 'Create',
    storage: 'Storage',
    box: 'Box',
    leave_empty_to_auto_generate: 'Leave blank to automatically generate value',
    please_select_freezer_stoarage_box: 'Please select freezer storage box',
    click_icon_create_storage_box: 'Click the icon to create a new freezer storage box',
    click_icon_select_storage_position: 'Click the icon to select a freezer storage box position',
    participation_in_study_closed_out: "Your participation in the study has been closed out.",
    please_enter_email_or_phone: "Please enter your email address or phone number",
    preferred_method_of_communication: 'Preferred Method Of Communication',
    opt_in: 'Opt In',
    opt_out: 'Opt Out',
    cheatblocker: "Cheatblocker",
    compare_dates_by: 'Compare Dates By',
    time_period: 'Time Period',
    criteria: 'Criteria',
    field: 'Field',
    in: 'In',
    interview_answer_condition: 'Interview Answers Condition',
    after_previous_answer_provided: "questionnaire's <strong> required answer provided </strong>",
    even_after_previous_answer_missing: "even after previous questionnaire's <strong> required answer missing </strong>",
    depends_on_previous_interview: 'Depends On Previous Interview',
    answer_provided_for_interview: 'Answer Provided For Interview',
    required_interview: 'Required Interview',
    after: 'after',
    its_kit_status: "and when it's biospecimen kit status is",
    after_completion_of_econsent: 'after completion of econsent',
    to_activate_this_interview: 'to activate this interview',
    filled_returned: 'Filled & Returned',
    urine: 'Urine',
    browse_files: 'Browse Files',
    upload_files: 'Upload Files',
    media: 'Media',
    path: 'Path',
    resolution: 'Resolution',
    size: 'Size',
    uploading: 'Uploading',
    contact_form_message: `Welcome to the ${constant.app_name}! Please take a moment to share your contact information with us so that we can stay in touch with updates about the study.`,
    after_interviews_kit_status: "interviews's <strong> biospecimen kit status </strong> is",
    active_on_condition: 'Active on condition',
    active_without_answer_kit_submission: 'Active without interview answer and biospecimen kit submission',
    active_after_4_weeks_final_reminder: "Or active after 4 weeks from final reminder of required interview's survey / kit",
    active_after_postnatal: "after postnatal period",
    zipcode: 'Zipcode',
    are_this_true: "Are either of these currently true?",
    no_longer_pregnant: "You are no longer pregnant",
    moved_out_of_state: "You have moved out of state",
    not_meet_criteria_for_study: "Thank you for your interest. Unfortunately, you do not meet the eligibility criteria for this study.",
    workflow_not_eligible_text_2: `Thank you for your interest in the ${constant.app_name}! Since our study has very specific requirements to participate, not everyone is eligible to join the study. Unfortunately, at this time, your answers indicate that the <h5 class='d-inline'> eligibility requirements for the study were not met. </h5> We wanted to send a special guide with helpful community resources for pregnant individuals and parents. You can download the guide here. A copy will also be sent to your email. We would love to stay in touch to let you know about the things we learn from this study. Be sure to visit us at ${constant.email_app_public_website}  for newsletters and updates.`,
    study_video_title: "Learn more about the study and what's involved by watching this short video.",
    email_app_sign_full: `The ${constant.email_app_sign_full}`,
    warmest_regards: 'Warmest Regards',
    questionnaire_completed_subtext: "Thank you for completing your questionnaire! Our team is getting your next sample kit ready for shipment. We'll let you know when your kit has shipped and is on its way to you. Wishing you a great week ahead.",
    questionnaire_completed: "Questionnaire Completed!",
    participation_close_subtext: `Thank you so much for participating in the ${constant.app_name}! On behalf of our team, we are very grateful that you spent some time participating in the study. We just wanted to let you know that your participation in the study has been closed out. If you have any questions for our team or would like to rejoin the study at some other time in the future, feel free to reach out via email at ${constant.app_email} or phone ${constant.app_phone}.`,
    participation_close_title: "Participation in the Study Closed Out!",
    link_expire_subtext: `Thank you so much for participating in the ${constant.app_name}! On behalf of our team, we are very grateful that you spent some time participating in the study. We wanted to inform you that the link to the interview you tried to access has expired. If you have any questions for our team feel free to reach out via email at ${constant.app_email} or phone ${constant.app_phone}.`,
    link_expire_title: "Interview Link Expired!",
    rollover_question: 'Rollover Question',
    rollover_question_info_text: "Is this question rollover from another interview?",
    rollover_question_variable_info_text: "Please enter the label/variable name for the question you wish to roll over.",
    rollover_question_interview_info_text: "Please select the interview of the question you wish to roll over.",
    workflow_rollover_question_settings: "Workflow Rollover Question Settings (Optional)",
    interview_completed_message: "Congratulations! Your interview is 100% completed. Thank you for your time and participation!",
    triggers_no_longer_pregnant: "Selecting 'No' will trigger email to participant that they are no longer pregnant.",
    no_files_to_show: "No Files to show!",
    drag_and_drop_files: "Drag 'n' drop some files here, or click to select files",
    file_upload_limit: "File upload limit: 500MB maximum per file.",
    files: "Files",
    loading: "Loading",
    are_you_sure_to_delete: "Are you sure you want to delete this entry?",
    something_went_wrong: "Something went wrong. Please try again later.",
    contact_information_updated_message: "Your contact information has been successfully updated!",
    answer_all_required_que: "Please answer all the (*) required questions!",
    your_consent_is_recorded: "Your consent has been successfully recorded!",
    max_files: "Max 5 files",
    each_file_less_than: "Each file must be less than 2Mb",
    interview_saved_successfully: "Interview Saved Successfully!",
    unable_to_save_interview: "Unable to save interview!",
    unable_to_save_contact_info: "Unable to Update Participant Contact Info! Try Again",
    i_agree: "I Agree",
    disagree: "Disagree",
    forgotten_password: "Forgotten password?",
    login: "Login",
    sign_in_to_your_account: "Sign In to your account",
    login_as: "Login as",
    login_welcome_text: `Welcome to the ${constant.app_name} Dashboard! Please login to your account with your credentials [username and password]. Thank you for your support in advancing child health!`,
    login_with_password: "Login with password",
    continue: "Continue",
    password_reset_link_sent: "Password reset link has been sent to your email. Please check your inbox to proceed.",
    link_valid_for_note: "Note that the link will be valid for one hour.",
    please_enter_email: "Please enter your email",
    my_profile: "My Profile",
    change_password: "Change password",
    logout: "Logout",
    password_changed_success: "Password successfully changed. Click the 'Login' button to log in with your new credentials.",
    password_reset_link_expire: "The password reset link has expired or contains an invalid token.",
    mark_as_bookmark_message: `To bookmark this page:\n - Press Ctrl/Cmd + D, or\n - Click on the star icon in the address bar, or\n - Open the browser menu and select 'Bookmark This Page'.`,
    lock_temporarily: "Lock Temporarily",
    interview_temporarily_locked: "Interview Temporarily Locked",
    interview_locked_subtext: `Thank you so much for participating in the ${constant.app_name}! On behalf of our team, we are very grateful that you spent some time participating in the study. We wanted to inform you that the interview you tried to access is currently locked. If you have any questions or need assistance, feel free to reach out to our team via email at ${constant.app_email} or phone ${constant.app_phone}.`,
    unsaved_changes_message: "You have unsaved changes. Are you sure you want to leave?",
    search: "Search",
    find: "Find",
    clear: "Clear",
    study_status: "Study Status",
    url_copied_success: "URL copied successfully!",
    send_immediately: "Send Immediately",
    send_after_lapse: "Send after lapse of time:",
    send_at_exact: "Send at exact date/time",
    send_before_six: "Send before 6 hours of the scheduled time",
    send_before_one: "Send before 1 hours of the scheduled time",
    just_once: "Just Once",
    multiple_times: "Multiple times: Send every",
    after_initially_sent: "after initially being sent",
    optional: "Optional",
    click_to_remove_email: "Click to remove email",
    or: "Or",
    take_picture: "Take Picture",
    camera: "Camera",
    selected_files: "Selected Files",
    retake: "Retake",
    capture_photo: "Capture Photo",
    switch_to: "Switch To",
    done: "Done",
    front: "Front",
    rear: "Rear",
    stop: "Stop",
    notification: "Notification",
    unsave_biospecimen_warning_message: "You have unsaved biospecimen changes. Are you sure you want to leave?",
    unsave_specimen_warning_message: "You have unsaved specimen type details changes. Are you sure you want to leave?",
    unsave_aliquoting_specimen_warning_message: "You have unsaved aliquoting specimen changes. Are you sure you want to leave?",
    biospecimen_saved: "Biospecimen saved successfully!",
    specimen_type_saved: "Specimen type details saved successfully!",
    aliquot_specimen_saved: "Aliquot Specimen type saved successfully!",
    are_you_sure_to_delete_this_entry: "Are you sure to delete this entry?",
    and: 'and',
    return_later: 'Return later',
    minimum: 'Minimum',
    maximum: "Maximum",
    minimum_value_info_text: "Required minimum value of the question",
    maximum_value_info_text: "Required maximum value of the question",
    error_messages: "Error Messages (Optional)",
    error: "Error",
    date_format: "Date/Datetime/Time",
    alpha: "Letters Only",
    numeric: "Number",
    digits: "MRN (10 digits/generic) / Phone",
    integer: "Integer",
    decimal: "Decimal Number",
    regex: "Zipcode",
    after_or_equal: "Minimum Date",
    before_or_equal: "Maximum Date",
    min: "Minimum Number",
    max: "Maximum Number",
    read_resource_guide: `- To read the English resource guide, <a href='${constant.english_resource_guide_link}'> click here </a>`,
    interview_complete_1: "You have completed your ",
    interview_complete_2: ` for the ${constant.app_name_full}. `,
    interview_complete_3: `To thank you for your participation, check out the NJKS resource guide, English <a href='${constant.english_resource_guide_link}'>click here</a>, Espanol <a href='${constant.spanish_resource_guide_link}'> click here </a> which is full of information to support healthy pregnancies and families in New Jersey.`,
    interview_complete_4: `You should receive your first Biospecimen Kit in the mail in 1-2 weeks. To learn more about what to expect, watch our ${constant.app_name} Biospecimen Box Opening Video <a href='${constant.biospecimen_box_opening_video_link}'>here</a>. If you have any questions or concerns, contact us at ${constant.email_app_email} or ${constant.email_app_phone}. You can access your Participant Dashboard at <a href='${constant.app_link}'>Login</a>. <br/> <br/> <strong> Thank you for supporting the ${constant.research_study_name}! </strong>`,
    questionnaire: 'Questionnaire',
    form: "Form",
    header: "Header",
    footer: "Footer",
    show_popup_message: "Show Popup Message",
    popup_message: "Popup Message",
    popup_condition: "Popup Condition",
    popup_message_info_text: "Show popup message when question is answered",
    operator: "Operator",
    please_enter_exact: "Please Enter Exact",
    ok: "Ok",
    url_copied: "Url copied successfully!",
    failed_url_copy: "Failed to copy URL!",
    remap_languages: "Remap Languages",
    remap_languages_successfully: "Remap languages successfully!",
    remap_languages_failed: "Failed to remap languages!",
    are_you_sure_to_remap: "Are you sure to remap languages of instruments?",
    study_introduction: "Study Introduction",
    first_name: "First Name",
    last_name: "Last Name",
    cell_phone: "Cell Phone",
    home_phone: "Home Phone",
    work_phone: "Work Phone",
    this_is_required_field: "This is a required field",
    if_you_are_not_eligible_for_study: "If you are not eligible for this study and believe this is an error, please don't hesitate to contact us.",
    to_continue_survey_check_email: "To continue your survey, log in using the credentials provided in the email.",
    login_credential_sent_to_your_below_contact_info: "Your login credentials have been sent to your registered email and phone number as shown below:",
    you_are_best_we_appreciate: "You’re the Best! We really appreciate you taking the time to complete this interview.",
    to_learn_more_about_study: "To learn more about the study,",
    click_here: "Click here.",
    kit_will_be_sent_to_address: "Your kit will be sent to the address you provided. If the address is not correct, please log in to your dashboard to update your contact information or reach out to the study team.",
    resources: "Resources",
resources_text: `<h3 class='text-center'> Resource Guides </h3>View English Resource Guide: <a target='__blank' href='https://njkidsstudy.rutgers.edu/kids-study-backend/public/media_uploads/1725304306_NJKS_Resource_Guide_2024.pdf'>Click Here</a> <br/> View Spanish Resource Guide: <a target='__blank' href='https://njkidsstudy.rutgers.edu/kids-study-backend/public/media_uploads/1725304325_NJKS_Spanish_Resource_Guide_2024.pdf'>Click Here</a>`,    
spanish_interview_name: "Spanish Interview Name",
    volume: "Volume",
    unsigned_giftcard_notifications_disabled: 'Disable Unsigned Gift Card Notifications',
    unsigned_giftcard_notificatipns_disabled_warning_message: "Please note that unsigned gift card notifications have been disabled, and participants will no longer receive gift card reminder notifications.",
    notification_history: "Notification History",
    sms: "SMS",
    sent_at: "Sent at",

  },

  chinese: {
    dashboard: '仪表板',
    system: '系统',
    master: '主',
    language: '语言',
    languages: '语言',
    category: '类别',
    country: '国家',
    countries: '国家',
    state: '州',
    states: '状态',
    city: '市',
    streettype: '街道类型',
    street: '街',
    direction: '方向',
    residence: '住宅',
    residences: '住宅',
    recruitment: '招聘',
    recruitments: '招聘',
    socialgroup: '社会群体',
    socialgroups: '社会团体',
    speakinglanguages: '口語',
    purpose: '目的',
    purposes: '目的',
    typeofcontact: '联系方式',
    typeofcontacts: '联系方式',
    syssetting: '系统',
    contactsetting: '联系人设置',
    user: '用户',
    users: '用户',
    role: '角色',
    gensetting: '通用设置',
    myprofile: '我的简历',
    changepassword: '更改密码',
    databank: '访问 和 数据银行',
    participant: '参与者',
    participants: '参与者',
    instrument: '仪器',
    interview: '访问',
    languages: '语言',
    name: '名称',
    action: '行动',
    edit: '编辑',
    delete: '删除',
    add: '加',
    back: '背部',
    update: '更新',
    srno: '老年人',
    submit: '提交',
    reset: '重启',
    enter: '输入',
    categories: '类别',
    select: '选择',
    email: '电子邮件',
    first: '第一',
    username: '用户名',
    last: '持续',
    other: '其他',
    regulation: '规',
    id: '身份证',
    dob: '出生日期',
    gender: '性别',
    address: '地址',
    address2: '地址 2',
    zip: '压缩',
    home: '家',
    work: '工作',
    phone: '电话',
    cell: '手机',
    phone_1: '电话号码1',
    phone_2: '电话号码2',
    phone_3: '电话号码3',
    phone_4: '电话号码4',
    comment: '评论',
    waveidentifier: 'Wave Identifier',
    puma: 'PUMA',
    psu: 'PSU',
    ssu: 'SSU',
    ifh_id: 'IFH ID',
    profile: '个人资料',
    picture: '图片',
    password: '密码',
    confirm: '确认',
    old: '旧',
    new: '新',
    age: '年龄',
    speaking: '请讲',
    reading: '读',
    writing: '写作',
    preference: '偏爱',
    ifother: '如果是其他',
    abbreviation: '缩写',
    content: '内容',
    question: '题',
    questions: '题',
    details: '细节',
    up: '向上',
    down: '下',
    copy: '复制',
    option: '选项',
    from: '从',
    options: '选项',
    label: '标签',
    help: '救命',
    parent: '亲',
    istrigger: '是触发器',
    abbrivation: '缩写',
    for: '对于',
    assigned: '分配',
    assign: '分配',
    staff: '员工',
    status: '状态',
    type: '类型',
    contact: '往来',
    contacts: '往来',
    previous: '以前',
    date: '日期',
    time: '时间',
    start: '开始',
    next: '下一个',
    save: '保存',
    list: '名单',
    kinship: '亲缘关系',
    kinships: '亲缘关系',
    tag: '标签',
    tags: '标签',
    schedule: '时间表',
    scheduled: '计划',
    field: '领域',
    mandatory: '强制性',
    isrequire: '是 需要',
    conduct: '进行',
    pause: '停顿',
    assignment: '分配',
    relationship: '关系',
    with: '用',
    method: '方法',
    bloodsample: '血液样本',
    tubecount: '管数',
    bsd: '血样日期',
    watch: '看',
    pddate: '日期',
    code: '码',
    compltreport: '报告',
    coding: '编码',
    percentage: '百分比',
    answer: '回答',
    thankyou: '谢谢',
    display: '显示',
    sendemail: '发送电子邮件',
    emailsent: '邮件已发送',
    preview: '预习',
    consent: '同意',
    studyid: 'Study Status',
    familyid: 'Family Id',
    hascomment: 'Has Comment',
    participantid: 'ParticipantId',
    section: 'Section',
    child: '儿童',
    parent: '父母',
    speaking_language: 'Speaking Language',
    study_type: 'Study Type',
    test_name: 'Test Name',
    test_completed: 'Completed',
    view_details: '查看详情',
    apt: '公寓式',
    total: '总',
    assignments: '作业',
    mockparticipant: '模拟参与者',
    reassign: '重新分配',
    completion: '完成',
    clone: '克隆',
    sync: '同步',
    contacted: '已联络',
    not_contacted: '尚未联系',
    history: '历史',
    device: '设备',
    sms_template: 'SMS Template',
    description: 'Description',
    checklist: 'Checklist',
    history: 'History',
    item: 'Item',
    price: 'Price',
    quantity: 'Quantity',
    purchase: 'Purchase',
    ref_no: 'Ref No.',
    purchase_by: 'Purchased By',
    dispatch: 'Dispatch',
    receive: 'Receive',
    phlebotomy: 'Phlebotomy and Saliva Collection',
    equipment: 'Equipment',
    tube: 'Tube',
    test: 'Test',
    barcode: 'Barcode',
    qrcode: 'QR code',
    capacity: 'Capacity',
    unit: 'Unit',
    inventory_no: 'Inventory No',
    draw_from: 'Blood Drawn From',
    filled_to_capacity: 'Filled To Capacity',
    second_attempt: 'Second Attempt',
    end: 'End',
    result: 'Result',
    result_recorded_date: 'Result Recorded Date',
    result_recorded_time: 'Result Recorded Time',
    anomalies: 'Anomalies',
    normal_range: 'Normal Range',
    notes: 'Notes',
    need_review: 'Need Review',
    ldd: 'LDD',
    lloq: 'LLOQ',
    selected: 'Selected',
    current: 'Current',
    start_time: 'Start Time',
    end_time: 'End Time',
    assign_date: 'Assign Date',
    unassign_date: 'Unassign Date',
    number: "Number",
    canvassing: 'Canvassing',
    association: 'Association',
    rds: 'RDS',
    coupon: 'Coupon',
    recruitment: 'Recruitment',
    mark: 'Mark',
    as: 'as',
    complete: 'Complete',
    remove: 'Remove',
    completion: 'Completion',
    of: 'of',
    reason: 'Reason',
    isCompleted: 'Is Completed',
    transfer: 'Transfer',
    householdid: 'Household ID',
    familyid: 'Family ID',
    date_consent_taken: 'Date Consent Taken',
    date_scanned: 'Date Scanned',
    file: 'File',
    upload: 'Upload',
    paper: 'Paper',
    remind: 'Remind',
    me: 'me',
    notifications: 'Notifications',
    reminders: 'Reminders',
    alerts: 'Alerts',
    title: 'Title',
    alertTopic: 'Alert topic',
    sendHowManyTimes: 'Send it how many times?',
    whenToSendAlert: 'When to send alert?',
    message: 'Message',
    settings: 'Settings',
    to: 'To',
    subject: 'Subject',
    alertExpiration: 'Alert Expiration',
    lot_number: 'Lot Number',
    tube_status: 'Tube Status',
    ups_tracking_no: 'UPS Tracking Number',
    date: 'Date',
    tube_fill_status: 'Tube Fill Status',
    phlebotomy_saliva: 'Phlebotomy/Saliva',
    tube_container: 'Tube/Container',
    data_entry_blood_saliva: 'DATA ENTRY FOR BLOOD DRAW AND SALIVA',
    data_entry_for: 'Data Entry For',
    eligibility: 'Eligibility',
    randomize_questions: 'Randomize Questions',
    randomize_options: 'Randomize Options',
    ethnicity: 'Ethnicity',
    sendConsentEmail: 'Send Consent Email',
    consentEmailSent: 'Consent Email Sent',
    data_collection_type: 'Data Collection Type',
    view: 'View',
    generate: 'Generate',
    triageReport: 'Triage Report',
    open: 'Open',
    time_point: 'Time Point',
    staff_id: 'Staff ID',
    participant_id: 'Participant ID',
    reviewed_by_partner: 'Reviewed By Partner',
    interview1_date_time: 'Interview 1: Date and time',
    interview1_completion_status: 'Interview 1: Completion Status',
    interview2_date_time: 'Interview 2: Date and time',
    interview2_completion_status: 'Interview 2: Completion Status',
    interview3_date_time: 'Interview 3: Date and time',
    interview3_completion_status: 'Interview 3: Completion Status',
    interview4_date_time: 'Interview 4: Date and time',
    interview4_completion_status: 'Interview 4: Completion Status',
    interview5_date_time: 'Interview 5: Date and time',
    interview5_completion_status: 'Interview 5: Completion Status',
    interview6_date_time: 'Interview 6: Date and time',
    interview6_completion_status: 'Interview 6: Completion Status',
    gift_card: 'Gift Card',
    transcribed: 'Transcribed',
    referral: 'Referral',
    race: 'Race',
    reason: 'Reason',
    interview1_staff: 'Interview 1: Staff',
    interview2_staff: 'Interview 2: Staff',
    interview3_staff: 'Interview 3: Staff',
    interview4_staff: 'Interview 4: Staff',
    interview5_staff: 'Interview 5: Staff',
    interview6_staff: 'Interview 6: Staff',
    shelf_number: 'Shelf Number',
    tube_rack_number: 'Tube Rack Number',
    sample_position_in_tube_rack: 'Sample Position in Tube Rack',
    link_or_card: 'Link or Card',
    card: 'Card',
    ra_staff: 'RA/ Staff',
    date_sent: 'Date Sent',
    date_receipt_received: 'Date Receipt Received',
    date_of_activation: 'Date Of Activation If Sent By Mail',
    gift_card_voucher: 'Gift Card & Voucher',
    tracking: 'Tracking',
    unassigned_report: 'Unassigned Report',
    insurance: 'Insurance',
    participant_info_report: 'Participant Info Report',
    validate: 'Validate',
    verify: 'Verify',
    answer_id: 'Answer ID',
    instrument_id: 'Instrument ID',
    language_id: 'Language ID',
    question_id: 'Question ID',
    deleted_quetions_answers: 'Deleted Questions Answers',
    triggered_quetions_answers: 'Triggered Questions Answers',
    no_parent_quetions_answers: 'No Parent Triggered Questions Answers',
    deleted_options_answers: 'Deleted Options Answers',
    incomp_report: 'Incompletion Report',
    partial_comp_report: 'Partially Completed Report',
    last: 'Last',
    contact: 'Contact',
    details: 'Details',
    of: 'Of',
    reluctance: 'Reluctance',
    severity: 'Severity',
    contacted: 'Contacted',
    who: 'Who',
    not_contacted_yet: 'Not contacted yet',
    not_interviewed_yet: 'Not interviewed yet',
    total_assigned: 'Total Assigned',
    not_contacted_and_interviewed_report: 'Not Contacted & Interviewed Report',
    send: 'Send',
    cancel: 'Cancel',
    amount: 'Amount',
    event: 'Event',
    survey: 'Survey',
    coordinator_only: 'Coordinator Only',
    date_of_signature: 'Date of Signature',
    date_of_filing: 'Date of Filing',
    date_of_interview: 'Date of Interview',
    name_of_subject: 'Name of Subject',
    signature_of_subject: 'Signature of Subject',
    interviewer_initials: 'Interviewer Initials',
    dear: 'Dear',
    gift_card_para_1: 'Thank you for your recent participation in our research! Community members like you play an important role in our work, and we thank you again for adding your voice to our community. We could not do this without you!',
    gift_card_para_2_sentence_1: 'As discussed, enclosed is your gift card in the amount of ',
    gift_card_para_2_sentence_2: ' This card can be used at any store or online merchant nationwide. To use the card, follow the instructions emailed to you and sign the receipt below to acknowledge receiving the card. Should you have any questions or difficulties in completing the form, please contact us at: ',
    gift_card_para_2_sentence_3: ' or call us at ',
    gift_card_para_3_sentence_1: "And don't forget to join our community. From seminars, to outreach, to our ongoing research, there is always something happening. If you are interested in learning more about us and future events, just visit our website, ",
    gift_card_para_3_sentence_2: " We couldn't do what we do without your support!",
    gift_card_para_4_sentence_1: "Thank you for taking part in this study. By signing below, you acknowledge receiving ",
    gift_card_para_4_sentence_2: " for participation in this study.",
    sincerely: "Sincerely",
    gift_card: 'Gift Card',
    organization_line: 'Organization Line',
    address_line: 'Address Line',
    website: 'Website',
    handle: 'Handle',
    fax: 'Fax',
    logo: 'Logo',
    team: "Team",
    review: 'Review',
    would_you_like_to_review: "Would you like to review the consent before submitting the interview?",
    consent_warning_note: "Note: After interview completion, the consent will be locked, and you will not be able to modify the answers. Aww yeah, you successfully read this important alert message. This example text is going to run a bit longer so that you can see how spacing within an alert works with this kind of content.",
    updated_at: 'Updated At',
    recent: 'Recent',
    social_media: 'Social Media',
    url: 'URL',
    image: 'Image',
    interview_expiration_date: 'Interview Expiration Date',
    redirect_url: 'Redirect Url',
    saliva: 'Saliva',
    ID: 'ID',
    collected: 'Collected',
    in_person_or_mail: 'In person or Mail',
    arrival_date: 'Arrival Date',
    mandatory_survey: 'Mandatory Survey',
    supplemental_survey: 'Supplemental Survey',
    actigraphy: 'Actigraphy',
    blood_draw: 'Blood Draw',
    signature: 'Signature',
    mail: 'Mail',
    errors: 'Errors',
    oa_ma_report: 'OA & MA Report',
    percent_completion_of: 'Percent Completion Of',
    wave: 'Wave',
    criteria: 'Criteria',
    report: 'Report',
    physical: 'Physical',
    cognitive_assessment: 'Cognitive Assessment',
    assessment: 'Assessment',
    lab: 'Lab',
    sent: 'Sent',
    signature_of_investigator: 'Signature of Investigator/Individual Obtaining Consent/Assent',
    to_the_best_ability: 'To the best of my ability, I have explained and discussed the full contents of the study including all of the information contained in this consent form. All questions of the research subject and those of his/her parent or legal guardian have been accurately answered.',
    investigator_person: 'Investigator/Person Obtaining Consent Name (Print)',
    outbound_tracking_number: 'Outbound Tracking Number',
    outbound_ship_date: 'Outbound Ship Date',
    inbound_tracking_number: 'Inbound Tracking Number',
    inbound_ship_date: 'Inbound Ship Date',
    storage_date: 'Storage Date',
    shelf: 'Shelf',
    additional_details: 'Additional Details',
    sent_via_email: "if sent via email",
    signed: 'Signed',
    responded: 'Responded',
    family: 'Family',
    cohort: 'Cohort',
    close: 'Close',
    tree: 'Tree',
    validation: 'Validation',
    variable: 'Variable',
    duplicate: 'Duplicate',
    hospital: 'Hospital',
    field_is_embedded: 'Field is embedded elsewhere',
    enumeration: "Enumeration",
    eligible: 'Eligible',
    not_available: 'Not Available',
    follow_up: 'Follow Up',
    reminder: 'Reminder',
    scheduling: 'Scheduling',
    location: 'Location',
    component: 'Component',
    trigger: 'Trigger',
    show: "Show",
    is_enumeration_informant: 'Is Enumeration Informant?',
    probability_or_immigrant_sample: 'Probability or Immigrant sample?',
    full: 'Full',
    total_household_members: 'Total Household Members',
    total_family_members: 'Total Family Members',
    download: 'Download',
    progress: "Progress",
    additional_display_information: 'Additionl Display Information',
    self_directed_interview: "Self Directed Interview Completion Time Display Screen",
    required: 'Required',
    this_questionnaire: "This Questionnaire will take approximately",
    to_complete: "to complete.",
    complete_questionnaire: "Complete Questionnaire",
    now: 'Now',
    later: 'Later',
    web_or_phone: 'Web Or Phone',
    in_person_dispatch: 'In Person Dispatch',
    ssrs: 'SSRS',
    biospecimen: 'Biospecimen',
    kit: 'Kit',
    shipment_carrier: 'Shipment Carrier',
    outbound: 'Outbound',
    inbound: 'Inbound',
    condition_on_receipt: 'Condition on Receipt',
    laboratory_receipt_date: 'Laboratory Receipt Date',
    laboratory_receipt_condition: 'Laboratory Receipt Condition',
    specimen_type: 'Specimen Type',
    lab_biospecimen_processing: 'Lab Biospecimen Processing',
    storage_information: 'Storage Information',
    storage_location: 'Storage Location',
    storage_temperature: 'Storage Temperature',
    storage_start_date: 'Storage Start Date',
    review_date: 'Review Date',
    spinning_date: 'Spinning Date',
    aliquoting_date: 'Aliquoting Date',
    sample_position_in_rack: 'Sample Position in Rack',
    rack_number: 'Rack Number',
    google_analytics: 'Google Analytics',
    measurement: 'Measurement',
    update_and_resend: 'Update and Resend Gift Card',
    update_without_resend: 'Update Without Resending Gift Card',
    multimedia: 'Multimedia',
    format: 'Format',
    optional_file_attachment: 'File attachment image, audio or video (Optional):',
    provide_video_url: 'Provide video URL',
    workflows: 'Workflows',
    workflow: 'Workflow',
    add_interview_workflow: 'Add Interviews in the workflow',
    active_after_completion: 'Active after completion of previous questionnaire.',
    flow: 'Flow',
    econsent: 'E-consent',
    duration: 'Duration',
    unit: 'Unit',
    active_after_consent: 'Active after completion of e-consent',
    active_in: 'Active in',
    after_completion: 'after completion of previous questionnaire',
    active_on_after: 'Active on/after',
    active_condition: 'Active condition',
    active_on_date: 'Active On Date',
    biospecimen_kit_status: 'Biospecimen Kit Status',
    and_kit_status: "and when previous interview's biospecimen kit status is",
    active_instantly: 'Active <strong> instantly </strong>',
    screening_tool: 'Screening Tool',
    information: 'Information',
    study: 'Study',
    video_acknowledgment: 'Have you completed watching the entire video?',
    yes: 'Yes',
    no: 'No',
    step: 'Step',
    study_overview: 'Study Overview Video',
    continue: 'Continue',
    not_activated_yet: 'Interview Not Activated Yet!',
    not_finished: 'Not Finished!',
    stage: 'Stage',
    disagreed: 'Disagreed',
    eligible_for_study: 'Eligible for study!',
    ineligible_for_study: 'Ineligible for study!',
    agreed: 'Agreed',
    optional_screening_tool_eligibility_condition: 'Screening Tool Eligibility Condition (Optional)',
    condition: 'Condition',
    value: 'Value',
    seperate_multiple_values: 'Separate multiple values with commas. The condition will be true if any one matches.',
    overview: 'Overview',
    video: 'Video',
    print: 'Print',
    individual: 'Individual',
    please_enter_email: 'Please enter your email address',
    change: 'Change',
    self_collect_blood_permission: 'Are you interested in a self-collected blood draw using the TAP Micro device.',
    i_am_in: 'I am in',
    i_am_out: 'I am out',
    member: 'Member',
    and_when_econsent_agreed: 'and when e-consent is agreed.',
    text: 'Text',
    hint: 'Hint',
    placeholder: 'Placeholder',
    default: 'Default',
    introduction: 'Introduction',
    identifier_info_text: 'Does the field contain identifying information (e.g. name, SSN, address)',
    required_info_text: '* Prompt if field is blank',
    variable_name_info_text: 'Only letters, numbers & underscores',
    disable_info_text: "Choose 'Yes' to hide, 'No' to keep this question active.",
    help_info_text: "Additional information below the question",
    placeholder_info_text: "Enter text to display inside the field.",
    default_value_info_text: "Automatically fill this field with a preset value.",
    auto: 'Auto',
    check_errors_other_lang_instruments: "Check for errors in interview's other language instruments!",
    check_errors_other_lang_instruments_info: "Ensure accuracy in interview instruments by reviewing for potential issues such as mismatched question counts in other language instruments, discrepancies in question types, options count, and validations like whether a question is required, has comments, or serves as an identifier.",
    participants_having_deleted_questions_answers: "Participants having deleted question's answer in the interview!",
    participants_having_deleted_questions_answers_info: "Verify if participants have submitted answers for questions that were deleted from the instruments in the interview.",
    check_errors_other_lang_consent: "Check for errors in other language consent!",
    check_errors_other_lang_consent_info: "Ensure accuracy in consent by reviewing for potential issues such as mismatched section or question counts in other language consent, discrepancies in question types, options count, and validations like whether a question is required.",
    multilingual: 'Multilingual',
    stay: 'Stay',
    leave: 'Leave',
    workflow_not_eligible_text: `Thank you for your interest in the ${constant.app_name}! Unfortunately, at this time, your responses indicate that you would not be eligible to participate in the study. Please feel free to help spread the word about this study to friends or family members that you think may be eligible to join the study.`,
    congratulations: 'Congratulations',
    congratulations_screen_text: `Based on the answers you provided in the screening survey, <h5 class='d-inline'> you are eligible to participate in the study. </h5> Be sure to watch the ${constant.app_name} overview video to learn more about the study and what’s involved in participating to see if the study is the right fit for you.`,
    best_wishes: 'Best Wishes',
    the_ks_team: `${constant.email_app_sign_full}`,
    show_input_field: 'Show Input Field',
    aliquot: 'Aliquot',
    specimen: 'Specimen',
    freezer: 'Freezer',
    tube_freezer_storage_box_name: 'Tube Freezer Storage Box Name',
    tube_freezer_storage_box_position_name: 'Tube Freezer Storage Box Position Name',
    source: 'Source',
    pregnant: 'Pregnant',
    rows: 'Rows',
    columns: 'Columns',
    create: 'Create',
    storage: 'Storage',
    box: 'Box',
    leave_empty_to_auto_generate: 'Leave blank to automatically generate value',
    please_select_freezer_stoarage_box: 'Please select freezer storage box',
    click_icon_create_storage_box: 'Click the icon to create a new freezer storage box',
    click_icon_select_storage_position: 'Click the icon to select a freezer storage box position',
    participation_in_study_closed_out: "Your participation in the study has been closed out.",
    please_enter_email_or_phone: "Please enter your email address or phone number",
    preferred_method_of_communication: 'Preferred Method Of Communication',
    opt_in: 'Opt In',
    opt_out: 'Opt Out',
    cheatblocker: "Cheatblocker",
    compare_dates_by: 'Compare Dates By',
    time_period: 'Time Period',
    criteria: 'Criteria',
    field: 'Field',
    in: 'In',
    interview_answer_condition: 'Interview Answers Condition',
    after_previous_answer_provided: "questionnaire's <strong> required answer provided </strong>",
    even_after_previous_answer_missing: "even after previous questionnaire's <strong> required answer missing </strong>",
    depends_on_previous_interview: 'Depends On Previous Interview',
    answer_provided_for_interview: 'Answer Provided For Interview',
    required_interview: 'Required Interview',
    after: 'after',
    its_kit_status: "and when it's biospecimen kit status is",
    after_completion_of_econsent: 'after completion of econsent',
    to_activate_this_interview: 'to activate this interview',
    filled_returned: 'Filled & Returned',
    urine: 'Urine',
    browse_files: 'Browse Files',
    upload_files: 'Upload Files',
    media: 'Media',
    path: 'Path',
    resolution: 'Resolution',
    size: 'Size',
    uploading: 'Uploading',
    contact_form_message: `Welcome to the ${constant.app_name}! Please take a moment to share your contact information with us so that we can stay in touch with updates about the study.`,
    after_interviews_kit_status: "interviews's <strong> biospecimen kit status </strong> is",
    active_on_condition: 'Active on condition',
    active_without_answer_kit_submission: 'Active without interview answer and biospecimen kit submission',
    active_after_4_weeks_final_reminder: "Or active after 4 weeks from final reminder of required interview's survey / kit",
    active_after_postnatal: "after postnatal period",
    zipcode: 'Zipcode',
    are_this_true: "Are either of these currently true?",
    no_longer_pregnant: "You are no longer pregnant",
    moved_out_of_state: "You have moved out of state",
    not_meet_criteria_for_study: "Thank you for your interest. Unfortunately, you do not meet the eligibility criteria for this study.",
    workflow_not_eligible_text_2: `Thank you for your interest in the ${constant.app_name}! Since our study has very specific requirements to participate, not everyone is eligible to join the study. Unfortunately, at this time, your answers indicate that the <h5 class='d-inline'> eligibility requirements for the study were not met. </h5> We wanted to send a special guide with helpful community resources for pregnant individuals and parents. You can download the guide here. A copy will also be sent to your email. We would love to stay in touch to let you know about the things we learn from this study. Be sure to visit us at ${constant.email_app_public_website}  for newsletters and updates.`,
    study_video_title: "Learn more about the study and what's involved by watching this short video.",
    email_app_sign_full: `The ${constant.email_app_sign_full}`,
    warmest_regards: 'Warmest Regards',
    questionnaire_completed_subtext: "Thank you for completing your questionnaire! Our team is getting your next sample kit ready for shipment. We'll let you know when your kit has shipped and is on its way to you. Wishing you a great week ahead.",
    questionnaire_completed: "Questionnaire Completed!",
    participation_close_subtext: `Thank you so much for participating in the ${constant.app_name}! On behalf of our team, we are very grateful that you spent some time participating in the study. We just wanted to let you know that your participation in the study has been closed out. If you have any questions for our team or would like to rejoin the study at some other time in the future, feel free to reach out via email at ${constant.app_email} or phone ${constant.app_phone}.`,
    participation_close_title: "Participation in the Study Closed Out!",
    link_expire_subtext: `Thank you so much for participating in the ${constant.app_name}! On behalf of our team, we are very grateful that you spent some time participating in the study. We wanted to inform you that the link to the interview you tried to access has expired. If you have any questions for our team feel free to reach out via email at ${constant.app_email} or phone ${constant.app_phone}.`,
    link_expire_title: "Interview Link Expired!",
    rollover_question: 'Rollover Question',
    rollover_question_info_text: "Is this question rollover from another interview?",
    rollover_question_variable_info_text: "Please enter the label/variable name for the question you wish to roll over.",
    rollover_question_interview_info_text: "Please select the interview of the question you wish to roll over.",
    workflow_rollover_question_settings: "Workflow Rollover Question Settings (Optional)",
    interview_completed_message: "Congratulations! Your interview is 100% completed. Thank you for your time and participation!",
    triggers_no_longer_pregnant: "Selecting 'No' will trigger email to participant that they are no longer pregnant.",
    no_files_to_show: "No Files to show!",
    drag_and_drop_files: "Drag 'n' drop some files here, or click to select files",
    file_upload_limit: "File upload limit: 500MB maximum per file.",
    files: "Files",
    loading: "Loading",
    are_you_sure_to_delete: "Are you sure you want to delete this entry?",
    something_went_wrong: "Something went wrong. Please try again later.",
    contact_information_updated_message: "Your contact information has been successfully updated!",
    answer_all_required_que: "Please answer all the (*) required questions!",
    your_consent_is_recorded: "Your consent has been successfully recorded!",
    max_files: "Max 5 files",
    each_file_less_than: "Each file must be less than 2Mb",
    interview_saved_successfully: "Interview Saved Successfully!",
    unable_to_save_interview: "Unable to save interview!",
    unable_to_save_contact_info: "Unable to Update Participant Contact Info! Try Again",
    i_agree: "I Agree",
    disagree: "Disagree",
    forgotten_password: "Forgotten password?",
    login: "Login",
    sign_in_to_your_account: "Sign In to your account",
    login_as: "Login as",
    login_welcome_text: `Welcome to the ${constant.app_name} Dashboard! Please login to your account with your credentials [username and password]. Thank you for your support in advancing child health!`,
    login_with_password: "Login with password",
    continue: "Continue",
    password_reset_link_sent: "Password reset link has been sent to your email. Please check your inbox to proceed.",
    link_valid_for_note: "Note that the link will be valid for one hour.",
    please_enter_email: "Please enter your email",
    my_profile: "My Profile",
    change_password: "Change password",
    logout: "Logout",
    password_changed_success: "Password successfully changed. Click the 'Login' button to log in with your new credentials.",
    password_reset_link_expire: "The password reset link has expired or contains an invalid token.",
    mark_as_bookmark_message: `To bookmark this page:\n - Press Ctrl/Cmd + D, or\n - Click on the star icon in the address bar, or\n - Open the browser menu and select 'Bookmark This Page'.`,
    lock_temporarily: "Lock Temporarily",
    interview_temporarily_locked: "Interview Temporarily Locked",
    interview_locked_subtext: `Thank you so much for participating in the ${constant.app_name}! On behalf of our team, we are very grateful that you spent some time participating in the study. We wanted to inform you that the interview you tried to access is currently locked. If you have any questions or need assistance, feel free to reach out to our team via email at ${constant.app_email} or phone ${constant.app_phone}.`,
    unsaved_changes_message: "You have unsaved changes. Are you sure you want to leave?",
    search: "Search",
    find: "Find",
    clear: "Clear",
    study_status: "Study Status",
    url_copied_success: "URL copied successfully!",
    send_immediately: "Send Immediately",
    send_after_lapse: "Send after lapse of time:",
    send_at_exact: "Send at exact date/time",
    send_before_six: "Send before 6 hours of the scheduled time",
    send_before_one: "Send before 1 hours of the scheduled time",
    just_once: "Just Once",
    multiple_times: "Multiple times: Send every",
    after_initially_sent: "after initially being sent",
    optional: "Optional",
    click_to_remove_email: "Click to remove email",
    or: "Or",
    take_picture: "Take Picture",
    camera: "Camera",
    selected_files: "Selected Files",
    retake: "Retake",
    capture_photo: "Capture Photo",
    switch_to: "Switch To",
    done: "Done",
    front: "Front",
    stop: "Stop",
    notification: "Notification",
    unsave_biospecimen_warning_message: "You have unsaved biospecimen changes. Are you sure you want to leave?",
    unsave_specimen_warning_message: "You have unsaved specimen type details changes. Are you sure you want to leave?",
    unsave_aliquoting_specimen_warning_message: "You have unsaved aliquoting specimen changes. Are you sure you want to leave?",
    biospecimen_saved: "Biospecimen saved successfully!",
    specimen_type_saved: "Specimen type details saved successfully!",
    aliquot_specimen_saved: "Aliquot Specimen type saved successfully!",
    are_you_sure_to_delete_this_entry: "Are you sure to delete this entry?",
    and: 'and',
    return_later: 'Return later',
    minimum: 'Minimum',
    maximum: "Maximum",
    minimum_value_info_text: "Required minimum value of the question",
    maximum_value_info_text: "Required maximum value of the question",
    error_messages: "Error Messages (Optional)",
    error: "Error",
    date_format: "Date/Datetime/Time",
    alpha: "Letters Only",
    numeric: "Number",
    digits: "MRN (10 digits/generic) / Phone",
    integer: "Integer",
    decimal: "Decimal Number",
    regex: "Zipcode",
    after_or_equal: "Minimum Date",
    before_or_equal: "Maximum Date",
    min: "Minimum Number",
    max: "Maximum Number",
    read_resource_guide: `- To read the English resource guide, <a href='${constant.english_resource_guide_link}'> click here </a>`,
    interview_complete_1: "You have completed your ",
    interview_complete_2: ` for the ${constant.app_name_full}. `,
    interview_complete_3: `To thank you for your participation, check out the NJKS resource guide, English <a href='${constant.english_resource_guide_link}'>click here</a>, Espanol <a href='${constant.spanish_resource_guide_link}'> click here </a> which is full of information to support healthy pregnancies and families in New Jersey.`,
    interview_complete_4: `You should receive your first Biospecimen Kit in the mail in 1-2 weeks. To learn more about what to expect, watch our ${constant.app_name} Biospecimen Box Opening Video <a href='${constant.biospecimen_box_opening_video_link}'>here</a>. If you have any questions or concerns, contact us at ${constant.email_app_email} or ${constant.email_app_phone}. You can access your Participant Dashboard at <a href='${constant.app_link}'>Login</a>. <br/> <br/> <strong> Thank you for supporting the ${constant.research_study_name}! </strong>`,
    questionnaire: 'Questionnaire',
    form: "Form",
    header: "Header",
    footer: "Footer",
    show_popup_message: "Show Popup Message",
    popup_message: "Popup Message",
    popup_condition: "Popup Condition",
    popup_message_info_text: "Show popup message when question is answered",
    operator: "Operator",
    please_enter_exact: "Please Enter Exact",
    ok: "Ok",
    url_copied: "Url copied successfully!",
    failed_url_copy: "Failed to copy URL!",
    remap_languages: "Remap Languages",
    remap_languages_successfully: "Remap languages successfully!",
    remap_languages_failed: "Failed to remap languages!",
    are_you_sure_to_remap: "Are you sure to remap languages of instruments?",
    study_introduction: "Study Introduction",
    first_name: "First Name",
    last_name: "Last Name",
    cell_phone: "Cell Phone",
    home_phone: "Home Phone",
    work_phone: "Work Phone",
    this_is_required_field: "This is a required field",
    if_you_are_not_eligible_for_study: "If you are not eligible for this study and believe this is an error, please don't hesitate to contact us.",
    to_continue_survey_check_email: "To continue your survey, log in using the credentials provided in the email.",
    login_credential_sent_to_your_below_contact_info: "Your login credentials have been sent to your registered email and phone number as shown below:",
    you_are_best_we_appreciate: "You’re the Best! We really appreciate you taking the time to complete this interview.",
    to_learn_more_about_study: "To learn more about the study,",
    click_here: "Click here.",
    kit_will_be_sent_to_address: "Your kit will be sent to the address you provided. If the address is not correct, please log in to your dashboard to update your contact information or reach out to the study team.",
    resources: "Resources",
    resources_text: `<h3 class='text-center'> Lorem Ipsum </h3> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. <br/> Lorem ipsum dolor sit amet <a target='__blank' href='https://google.com'>Click Here</a> <br/> Lorem ipsum dolor sit amet <a target='__blank' href='https://google.com'>Click Here</a>`,
    spanish_interview_name: "Spanish Interview Name",
    volume: "Volume",
    unsigned_giftcard_notifications_disabled: 'Disable Unsigned Gift Card Notifications',
    unsigned_giftcard_notificatipns_disabled_warning_message: "Please note that unsigned gift card notifications have been disabled, and participants will no longer receive gift card reminder notifications.",
    notification_history: "Notification History",
    sms: "SMS",
    sent_at: "Sent at",





  },

  hindi: {
    dashboard: 'डैशबोर्ड',
    system: 'प्रणाली',
    master: 'मूल सूची',
    language: 'भाषा',
    languages: 'भाषा सूची',
    category: 'श्रेणी',
    country: 'देश',
    countries: 'देश की सूची',
    state: 'राज्य',
    states: 'राज्य सूची',
    city: 'शहर',
    streettype: 'सड़क का प्रकार',
    street: 'सड़क',
    direction: 'दिशा',
    residence: 'रहने का स्थान',
    residences: 'निवास सूची',
    recruitment: 'भर्ती',
    recruitments: 'भर्ती की सूची',
    socialgroup: 'सामाजिक वर्ग',
    socialgroups: 'सामाजिक समूह सूची',
    speakinglanguages: 'बोलती हुई भाषाएँ',
    purpose: 'उद्देश्य',
    purposes: 'उद्देश्य सूची',
    typeofcontact: 'संपर्क का प्रकार',
    typeofcontacts: 'संपर्क के प्रकार की सूची',
    syssetting: 'स्थापना',
    contactsetting: 'संपर्कों का विन्यास',
    user: 'उपभोक्ता',
    users: 'उपभोक्ता सूची',
    role: 'भूमिका',
    gensetting: 'सामान्य सेटिंग',
    myprofile: 'प्रोफ़ाइल',
    changepassword: 'पासवर्ड बदलें',
    databank: 'डेटा बैंक',
    participant: 'सहभागी',
    participants: 'प्रतिभागी सूची',
    instrument: 'उपकरण',
    interview: 'इंटरव्यू',
    languages: 'भाषा सूची',
    name: 'नाम',
    action: 'कार्य',
    edit: 'संपादित करें',
    delete: 'मिटाना',
    add: 'जोड़ना',
    back: 'वापस जाओ',
    update: 'अपडेट करें',
    srno: 'क्रमांक',
    submit: 'प्रस्तुत',
    reset: 'रीसेट करें',
    enter: 'दाखिल करना',
    categories: 'श्रेणी की सूची',
    select: 'चयन करे',
    email: 'ईमेल',
    first: 'उपनाम',
    last: 'सरनेम',
    username: 'नाम',
    other: 'अन्य',
    regulation: 'नियम',
    id: 'आईडी',
    dob: 'जन्म तिथि',
    gender: 'लिंग',
    address: 'पता',
    address2: 'पता 2',
    zip: 'पिन कोड',
    home: 'घर',
    work: 'काम',
    phone: 'फ़ोन',
    phone_1: 'फोन नंबर 1',
    phone_2: 'फोन नंबर 2',
    phone_3: 'फोन नंबर 3',
    phone_4: 'फोन नंबर 4',
    cell: 'मोबाइल फ़ोन',
    comment: 'टिप्पणी',
    waveidentifier: 'Wave Identifier',
    puma: 'PUMA',
    psu: 'PSU',
    ssu: 'SSU',
    ifh_id: 'IFH ID',
    profile: 'प्रोफ़ाइल',
    picture: 'चित्र',
    password: 'पासवर्ड',
    confirm: 'पुष्टि करें',
    old: 'पुराना',
    new: 'नया',
    age: 'आयु',
    speaking: 'बोलनेवाला',
    reading: 'पढ़ना',
    writing: 'लिखाई',
    preference: 'पसंद',
    ifother: 'अगर कोई और',
    abbreviation: 'संक्षिप्तीकरण',
    content: 'हिस्सा',
    question: 'सवाल',
    questions: 'प्रश्न सूची',
    details: 'विवरण',
    up: 'ऊपर',
    down: 'नीचे',
    copy: 'कापी',
    option: 'विकल्प',
    from: 'से',
    options: 'विकल्प की सूची',
    label: 'लेबल',
    help: 'मदद',
    parent: 'मूल',
    istrigger: 'ट्रिगर है',
    abbrivation: 'संक्षिप्तीकरण',
    for: 'के लिये',
    assigned: 'सौंपा गया',
    assign: 'सौंपना',
    staff: 'स्टाफ़',
    status: 'स्थिति',
    type: 'प्रकार',
    contact: 'संपर्क',
    contacts: 'संपर्क की सूची',
    previous: 'पूर्व',
    date: 'तारीख',
    time: 'समय',
    start: 'प्रारंभ करे',
    next: 'अगला',
    save: 'बचाना',
    list: 'सूची',
    kinship: 'सम्बन्ध',
    kinships: 'रिश्तेदारी की सूची',
    tag: 'टैग',
    tags: 'टैग',
    schedule: 'अनुसूची',
    scheduled: 'अनुसूचित',
    field: 'क्षेत्र',
    mandatory: 'अनिवार्य',
    isrequire: 'अपेक्षित',
    conduct: 'संचालन करें',
    pause: 'विश्राम',
    assignment: 'असाइनमेंट',
    relationship: 'रिश्ता',
    with: 'साथ',
    method: 'प्रणाली',
    bloodsample: 'खून का नमूना',
    tubecount: 'ट्यूब की संख्या',
    bsd: 'रक्त के नमूने की तारीख',
    watch: 'घड़ी',
    pddate: 'तारीख',
    code: 'कोड',
    compltreport: 'समाप्ति रिपोर्ट',
    coding: 'कोडिंग',
    percentage: 'प्रतिशत',
    answer: 'उत्तर',
    thankyou: 'धन्यवाद',
    display: 'प्रदर्शन',
    sendemail: 'ईमेल भेजें',
    emailsent: 'ईमेल भेजा',
    preview: 'पूर्वावलोकन',
    consent: 'Consent',
    studyid: 'Study Status',
    familyid: 'Family Id',
    hascomment: 'Has Comment',
    participantid: 'ParticipantId',
    section: 'Section',
    child: 'Child',
    parent: 'Parent',
    speaking_language: 'Speaking Language',
    study_type: 'Study Type',
    test_name: 'Test Name',
    test_completed: 'Completed',
    view_details: 'विस्तार से देखें',
    apt: 'अपार्टमेंट',
    total: 'समस्त',
    assignments: 'असाइनमेंट',
    mockparticipant: 'मॉक पार्टिसिपेंट',
    reassign: 'पुनः असाइन',
    completion: 'समापन',
    clone: 'क्लोन',
    sync: 'सिंक',
    contacted: 'संपर्क किया गया',
    not_contacted: 'अभी तक संपर्क नहीं किया गया',
    history: 'इतिहास',
    device: 'साधन',
    sms_template: 'SMS Template',
    description: 'Description',
    checklist: 'Checklist',
    history: 'History',
    item: 'Item',
    price: 'Price',
    quantity: 'Quantity',
    purchase: 'Purchase',
    ref_no: 'Ref No.',
    purchase_by: 'Purchased By',
    dispatch: 'Dispatch',
    receive: 'Receive',
    phlebotomy: 'Phlebotomy and Saliva Collection',
    equipment: 'Equipment',
    tube: 'Tube',
    test: 'Test',
    barcode: 'Barcode',
    qrcode: 'QR code',
    capacity: 'Capacity',
    unit: 'Unit',
    inventory_no: 'Inventory No',
    draw_from: 'Blood Drawn From',
    filled_to_capacity: 'Filled To Capacity',
    second_attempt: 'Second Attempt',
    end: 'End',
    result: 'Result',
    result_recorded_date: 'Result Recorded Date',
    result_recorded_time: 'Result Recorded Time',
    anomalies: 'Anomalies',
    normal_range: 'Normal Range',
    notes: 'Notes',
    need_review: 'Need Review',
    ldd: 'LDD',
    lloq: 'LLOQ',
    selected: 'Selected',
    current: 'Current',
    start_time: 'Start Time',
    end_time: 'End Time',
    assign_date: 'Assign Date',
    unassign_date: 'Unassign Date',
    number: "Number",
    canvassing: 'Canvassing',
    association: 'Association',
    rds: 'RDS',
    coupon: 'Coupon',
    recruitment: 'Recruitment',
    mark: 'Mark',
    as: 'as',
    complete: 'Complete',
    remove: 'Remove',
    completion: 'Completion',
    of: 'of',
    reason: 'Reason',
    isCompleted: 'Is Completed',
    transfer: 'Transfer',
    householdid: 'Household ID',
    familyid: 'Family ID',
    date_consent_taken: 'Date Consent Taken',
    date_scanned: 'Date Scanned',
    file: 'File',
    upload: 'Upload',
    paper: 'Paper',
    remind: 'Remind',
    me: 'me',
    notifications: 'Notifications',
    reminders: 'Reminders',
    alerts: 'Alerts',
    title: 'Title',
    alertTopic: 'Alert topic',
    sendHowManyTimes: 'Send it how many times?',
    whenToSendAlert: 'When to send alert?',
    message: 'Message',
    settings: 'Settings',
    to: 'To',
    subject: 'Subject',
    alertExpiration: 'Alert Expiration',
    lot_number: 'Lot Number',
    tube_status: 'Tube Status',
    ups_tracking_no: 'UPS Tracking Number',
    date: 'Date',
    tube_fill_status: 'Tube Fill Status',
    phlebotomy_saliva: 'Phlebotomy/Saliva',
    tube_container: 'Tube/Container',
    data_entry_blood_saliva: 'DATA ENTRY FOR BLOOD DRAW AND SALIVA',
    data_entry_for: 'Data Entry For',
    eligibility: 'Eligibility',
    randomize_questions: 'Randomize Questions',
    randomize_options: 'Randomize Options',
    ethnicity: 'Ethnicity',
    sendConsentEmail: 'Send Consent Email',
    consentEmailSent: 'Consent Email Sent',
    data_collection_type: 'Data Collection Type',
    view: 'View',
    generate: 'Generate',
    triageReport: 'Triage Report',
    open: 'Open',
    time_point: 'Time Point',
    staff_id: 'Staff ID',
    participant_id: 'Participant ID',
    reviewed_by_partner: 'Reviewed By Partner',
    interview1_date_time: 'Interview 1: Date and time',
    interview1_completion_status: 'Interview 1: Completion Status',
    interview2_date_time: 'Interview 2: Date and time',
    interview2_completion_status: 'Interview 2: Completion Status',
    interview3_date_time: 'Interview 3: Date and time',
    interview3_completion_status: 'Interview 3: Completion Status',
    interview4_date_time: 'Interview 4: Date and time',
    interview4_completion_status: 'Interview 4: Completion Status',
    interview5_date_time: 'Interview 5: Date and time',
    interview5_completion_status: 'Interview 5: Completion Status',
    interview6_date_time: 'Interview 6: Date and time',
    interview6_completion_status: 'Interview 6: Completion Status',
    gift_card: 'Gift Card',
    transcribed: 'Transcribed',
    referral: 'Referral',
    race: 'Race',
    reason: 'Reason',
    interview1_staff: 'Interview 1: Staff',
    interview2_staff: 'Interview 2: Staff',
    interview3_staff: 'Interview 3: Staff',
    interview4_staff: 'Interview 4: Staff',
    interview5_staff: 'Interview 5: Staff',
    interview6_staff: 'Interview 6: Staff',
    shelf_number: 'Shelf Number',
    tube_rack_number: 'Tube Rack Number',
    sample_position_in_tube_rack: 'Sample Position in Tube Rack',
    link_or_card: 'Link or Card',
    card: 'Card',
    ra_staff: 'RA/ Staff',
    date_sent: 'Date Sent',
    date_receipt_received: 'Date Receipt Received',
    date_of_activation: 'Date Of Activation If Sent By Mail',
    gift_card_voucher: 'Gift Card & Voucher',
    tracking: 'Tracking',
    unassigned_report: 'Unassigned Report',
    insurance: 'Insurance',
    participant_info_report: 'Participant Info Report',
    validate: 'Validate',
    verify: 'Verify',
    answer_id: 'Answer ID',
    instrument_id: 'Instrument ID',
    language_id: 'Language ID',
    question_id: 'Question ID',
    deleted_quetions_answers: 'Deleted Questions Answers',
    triggered_quetions_answers: 'Triggered Questions Answers',
    no_parent_quetions_answers: 'No Parent Triggered Questions Answers',
    deleted_options_answers: 'Deleted Options Answers',
    incomp_report: 'Incompletion Report',
    partial_comp_report: 'Partially Completed Report',
    last: 'Last',
    contact: 'Contact',
    details: 'Details',
    of: 'Of',
    reluctance: 'Reluctance',
    severity: 'Severity',
    contacted: 'Contacted',
    who: 'Who',
    not_contacted_yet: 'Not contacted yet',
    not_interviewed_yet: 'Not interviewed yet',
    total_assigned: 'Total Assigned',
    not_contacted_and_interviewed_report: 'Not Contacted & Interviewed Report',
    send: 'Send',
    cancel: 'Cancel',
    amount: 'Amount',
    event: 'Event',
    survey: 'Survey',
    coordinator_only: 'Coordinator Only',
    date_of_signature: 'Date of Signature',
    date_of_filing: 'Date of Filing',
    date_of_interview: 'Date of Interview',
    name_of_subject: 'Name of Subject',
    signature_of_subject: 'Signature of Subject',
    interviewer_initials: 'Interviewer Initials',
    dear: 'Dear',
    gift_card_para_1: 'Thank you for your recent participation in our research! Community members like you play an important role in our work, and we thank you again for adding your voice to our community. We could not do this without you!',
    gift_card_para_2_sentence_1: 'As discussed, enclosed is your gift card in the amount of ',
    gift_card_para_2_sentence_2: ' This card can be used at any store or online merchant nationwide. To use the card, follow the instructions emailed to you and sign the receipt below to acknowledge receiving the card. Should you have any questions or difficulties in completing the form, please contact us at: ',
    gift_card_para_2_sentence_3: ' or call us at ',
    gift_card_para_3_sentence_1: "And don't forget to join our community. From seminars, to outreach, to our ongoing research, there is always something happening. If you are interested in learning more about us and future events, just visit our website, ",
    gift_card_para_3_sentence_2: " We couldn't do what we do without your support!",
    gift_card_para_4_sentence_1: "Thank you for taking part in this study. By signing below, you acknowledge receiving ",
    gift_card_para_4_sentence_2: " for participation in this study.",
    sincerely: "Sincerely",
    gift_card: 'Gift Card',
    organization_line: 'Organization Line',
    address_line: 'Address Line',
    website: 'Website',
    handle: 'Handle',
    fax: 'Fax',
    logo: 'Logo',
    team: "Team",
    review: 'Review',
    would_you_like_to_review: "Would you like to review the consent before submitting the interview?",
    consent_warning_note: "Note: After interview completion, the consent will be locked, and you will not be able to modify the answers. Aww yeah, you successfully read this important alert message. This example text is going to run a bit longer so that you can see how spacing within an alert works with this kind of content.",
    updated_at: 'Updated At',
    recent: 'Recent',
    social_media: 'Social Media',
    url: 'URL',
    image: 'Image',
    interview_expiration_date: 'Interview Expiration Date',
    redirect_url: 'Redirect Url',
    saliva: 'Saliva',
    ID: 'ID',
    collected: 'Collected',
    in_person_or_mail: 'In person or Mail',
    arrival_date: 'Arrival Date',
    mandatory_survey: 'Mandatory Survey',
    supplemental_survey: 'Supplemental Survey',
    actigraphy: 'Actigraphy',
    blood_draw: 'Blood Draw',
    signature: 'Signature',
    mail: 'Mail',
    errors: 'Errors',
    oa_ma_report: 'OA & MA Report',
    percent_completion_of: 'Percent Completion Of',
    wave: 'Wave',
    criteria: 'Criteria',
    report: 'Report',
    physical: 'Physical',
    cognitive_assessment: 'Cognitive Assessment',
    assessment: 'Assessment',
    lab: 'Lab',
    sent: 'Sent',
    signature_of_investigator: 'Signature of Investigator/Individual Obtaining Consent/Assent',
    to_the_best_ability: 'To the best of my ability, I have explained and discussed the full contents of the study including all of the information contained in this consent form. All questions of the research subject and those of his/her parent or legal guardian have been accurately answered.',
    investigator_person: 'Investigator/Person Obtaining Consent Name (Print)',
    outbound_tracking_number: 'Outbound Tracking Number',
    outbound_ship_date: 'Outbound Ship Date',
    inbound_tracking_number: 'Inbound Tracking Number',
    inbound_ship_date: 'Inbound Ship Date',
    storage_date: 'Storage Date',
    shelf: 'Shelf',
    additional_details: 'Additional Details',
    sent_via_email: "if sent via email",
    signed: 'Signed',
    responded: 'Responded',
    family: 'Family',
    cohort: 'Cohort',
    close: 'Close',
    tree: 'Tree',
    validation: 'Validation',
    variable: 'Variable',
    duplicate: 'Duplicate',
    hospital: 'Hospital',
    field_is_embedded: 'Field is embedded elsewhere',
    enumeration: "Enumeration",
    eligible: 'Eligible',
    not_available: 'Not Available',
    follow_up: 'Follow Up',
    reminder: 'Reminder',
    scheduling: 'Scheduling',
    location: 'Location',
    component: 'Component',
    trigger: 'Trigger',
    show: "Show",
    is_enumeration_informant: 'Is Enumeration Informant?',
    probability_or_immigrant_sample: 'Probability or Immigrant sample?',
    full: 'Full',
    total_household_members: 'Total Household Members',
    total_family_members: 'Total Family Members',
    download: 'Download',
    progress: "Progress",
    additional_display_information: 'Additionl Display Information',
    self_directed_interview: "Self Directed Interview Completion Time Display Screen",
    required: 'Required',
    this_questionnaire: "This Questionnaire will take approximately",
    to_complete: "to complete.",
    complete_questionnaire: "Complete Questionnaire",
    now: 'Now',
    later: 'Later',
    web_or_phone: 'Web Or Phone',
    in_person_dispatch: 'In Person Dispatch',
    ssrs: 'SSRS',
    biospecimen: 'Biospecimen',
    kit: 'Kit',
    shipment_carrier: 'Shipment Carrier',
    outbound: 'Outbound',
    inbound: 'Inbound',
    condition_on_receipt: 'Condition on Receipt',
    laboratory_receipt_date: 'Laboratory Receipt Date',
    laboratory_receipt_condition: 'Laboratory Receipt Condition',
    specimen_type: 'Specimen Type',
    lab_biospecimen_processing: 'Lab Biospecimen Processing',
    storage_information: 'Storage Information',
    storage_location: 'Storage Location',
    storage_temperature: 'Storage Temperature',
    storage_start_date: 'Storage Start Date',
    review_date: 'Review Date',
    spinning_date: 'Spinning Date',
    aliquoting_date: 'Aliquoting Date',
    sample_position_in_rack: 'Sample Position in Rack',
    rack_number: 'Rack Number',
    google_analytics: 'Google Analytics',
    measurement: 'Measurement',
    update_and_resend: 'Update and Resend Gift Card',
    update_without_resend: 'Update Without Resending Gift Card',
    multimedia: 'Multimedia',
    format: 'Format',
    optional_file_attachment: 'File attachment image, audio or video (Optional):',
    provide_video_url: 'Provide video URL',
    workflows: 'Workflows',
    workflow: 'Workflow',
    add_interview_workflow: 'Add Interviews in the workflow',
    active_after_completion: 'Active after completion of previous questionnaire.',
    flow: 'Flow',
    econsent: 'E-consent',
    duration: 'Duration',
    unit: 'Unit',
    active_after_consent: 'Active after completion of e-consent',
    active_in: 'Active in',
    after_completion: 'after completion of previous questionnaire',
    active_on_after: 'Active on/after',
    active_condition: 'Active condition',
    active_on_date: 'Active On Date',
    biospecimen_kit_status: 'Biospecimen Kit Status',
    and_kit_status: "and when previous interview's biospecimen kit status is",
    active_instantly: 'Active <strong> instantly </strong>',
    screening_tool: 'Screening Tool',
    information: 'Information',
    study: 'Study',
    video_acknowledgment: 'Have you completed watching the entire video?',
    yes: 'Yes',
    no: 'No',
    step: 'Step',
    study_overview: 'Study Overview Video',
    continue: 'Continue',
    not_activated_yet: 'Interview Not Activated Yet!',
    not_finished: 'Not Finished!',
    stage: 'Stage',
    disagreed: 'Disagreed',
    eligible_for_study: 'Eligible for study!',
    ineligible_for_study: 'Ineligible for study!',
    agreed: 'Agreed',
    optional_screening_tool_eligibility_condition: 'Screening Tool Eligibility Condition (Optional)',
    condition: 'Condition',
    value: 'Value',
    seperate_multiple_values: 'Separate multiple values with commas. The condition will be true if any one matches.',
    overview: 'Overview',
    video: 'Video',
    print: 'Print',
    individual: 'Individual',
    please_enter_email: 'Please enter your email address',
    change: 'Change',
    self_collect_blood_permission: 'Are you interested in a self-collected blood draw using the TAP Micro device.',
    i_am_in: 'I am in',
    i_am_out: 'I am out',
    member: 'Member',
    and_when_econsent_agreed: 'and when e-consent is agreed.',
    text: 'Text',
    hint: 'Hint',
    placeholder: 'Placeholder',
    default: 'Default',
    introduction: 'Introduction',
    identifier_info_text: 'Does the field contain identifying information (e.g. name, SSN, address)',
    required_info_text: '* Prompt if field is blank',
    variable_name_info_text: 'Only letters, numbers & underscores',
    disable_info_text: "Choose 'Yes' to hide, 'No' to keep this question active.",
    help_info_text: "Additional information below the question",
    placeholder_info_text: "Enter text to display inside the field.",
    default_value_info_text: "Automatically fill this field with a preset value.",
    auto: 'Auto',
    check_errors_other_lang_instruments: "Check for errors in interview's other language instruments!",
    check_errors_other_lang_instruments_info: "Ensure accuracy in interview instruments by reviewing for potential issues such as mismatched question counts in other language instruments, discrepancies in question types, options count, and validations like whether a question is required, has comments, or serves as an identifier.",
    participants_having_deleted_questions_answers: "Participants having deleted question's answer in the interview!",
    participants_having_deleted_questions_answers_info: "Verify if participants have submitted answers for questions that were deleted from the instruments in the interview.",
    check_errors_other_lang_consent: "Check for errors in other language consent!",
    check_errors_other_lang_consent_info: "Ensure accuracy in consent by reviewing for potential issues such as mismatched section or question counts in other language consent, discrepancies in question types, options count, and validations like whether a question is required.",
    multilingual: 'Multilingual',
    stay: 'Stay',
    leave: 'Leave',
    workflow_not_eligible_text: `Thank you for your interest in the ${constant.app_name}! Unfortunately, at this time, your responses indicate that you would not be eligible to participate in the study. Please feel free to help spread the word about this study to friends or family members that you think may be eligible to join the study.`,
    congratulations: 'Congratulations',
    congratulations_screen_text: `Based on the answers you provided in the screening survey, <h5 class='d-inline'> you are eligible to participate in the study. </h5> Be sure to watch the ${constant.app_name} overview video to learn more about the study and what’s involved in participating to see if the study is the right fit for you.`,
    best_wishes: 'Best Wishes',
    the_ks_team: `${constant.email_app_sign_full}`,
    show_input_field: 'Show Input Field',
    aliquot: 'Aliquot',
    specimen: 'Specimen',
    freezer: 'Freezer',
    tube_freezer_storage_box_name: 'Tube Freezer Storage Box Name',
    tube_freezer_storage_box_position_name: 'Tube Freezer Storage Box Position Name',
    source: 'Source',
    pregnant: 'Pregnant',
    rows: 'Rows',
    columns: 'Columns',
    create: 'Create',
    storage: 'Storage',
    box: 'Box',
    leave_empty_to_auto_generate: 'Leave blank to automatically generate value',
    please_select_freezer_stoarage_box: 'Please select freezer storage box',
    click_icon_create_storage_box: 'Click the icon to create a new freezer storage box',
    click_icon_select_storage_position: 'Click the icon to select a freezer storage box position',
    participation_in_study_closed_out: "Your participation in the study has been closed out.",
    please_enter_email_or_phone: "Please enter your email address or phone number",
    preferred_method_of_communication: 'Preferred Method Of Communication',
    opt_in: 'Opt In',
    opt_out: 'Opt Out',
    cheatblocker: "Cheatblocker",
    compare_dates_by: 'Compare Dates By',
    time_period: 'Time Period',
    criteria: 'Criteria',
    field: 'Field',
    in: 'In',
    interview_answer_condition: 'Interview Answers Condition',
    after_previous_answer_provided: "questionnaire's <strong> required answer provided </strong>",
    even_after_previous_answer_missing: "even after previous questionnaire's <strong> required answer missing </strong>",
    depends_on_previous_interview: 'Depends On Previous Interview',
    answer_provided_for_interview: 'Answer Provided For Interview',
    required_interview: 'Required Interview',
    after: 'after',
    its_kit_status: "and when it's biospecimen kit status is",
    after_completion_of_econsent: 'after completion of econsent',
    to_activate_this_interview: 'to activate this interview',
    filled_returned: 'Filled & Returned',
    urine: 'Urine',
    browse_files: 'Browse Files',
    upload_files: 'Upload Files',
    media: 'Media',
    path: 'Path',
    resolution: 'Resolution',
    size: 'Size',
    uploading: 'Uploading',
    contact_form_message: `Welcome to the ${constant.app_name}! Please take a moment to share your contact information with us so that we can stay in touch with updates about the study.`,
    after_interviews_kit_status: "interviews's <strong> biospecimen kit status </strong> is",
    active_on_condition: 'Active on condition',
    active_without_answer_kit_submission: 'Active without interview answer and biospecimen kit submission',
    active_after_4_weeks_final_reminder: "Or active after 4 weeks from final reminder of required interview's survey / kit",
    active_after_postnatal: "after postnatal period",
    zipcode: 'Zipcode',
    are_this_true: "Are either of these currently true?",
    no_longer_pregnant: "You are no longer pregnant",
    moved_out_of_state: "You have moved out of state",
    not_meet_criteria_for_study: "Thank you for your interest. Unfortunately, you do not meet the eligibility criteria for this study.",
    workflow_not_eligible_text_2: `Thank you for your interest in the ${constant.app_name}! Since our study has very specific requirements to participate, not everyone is eligible to join the study. Unfortunately, at this time, your answers indicate that the <h5 class='d-inline'> eligibility requirements for the study were not met. </h5> We wanted to send a special guide with helpful community resources for pregnant individuals and parents. You can download the guide here. A copy will also be sent to your email. We would love to stay in touch to let you know about the things we learn from this study. Be sure to visit us at ${constant.email_app_public_website}  for newsletters and updates.`,
    study_video_title: "Learn more about the study and what's involved by watching this short video.",
    email_app_sign_full: `The ${constant.email_app_sign_full}`,
    warmest_regards: 'Warmest Regards',
    questionnaire_completed_subtext: "Thank you for completing your questionnaire! Our team is getting your next sample kit ready for shipment. We'll let you know when your kit has shipped and is on its way to you. Wishing you a great week ahead.",
    questionnaire_completed: "Questionnaire Completed!",
    participation_close_subtext: `Thank you so much for participating in the ${constant.app_name}! On behalf of our team, we are very grateful that you spent some time participating in the study. We just wanted to let you know that your participation in the study has been closed out. If you have any questions for our team or would like to rejoin the study at some other time in the future, feel free to reach out via email at ${constant.app_email} or phone ${constant.app_phone}.`,
    participation_close_title: "Participation in the Study Closed Out!",
    link_expire_subtext: `Thank you so much for participating in the ${constant.app_name}! On behalf of our team, we are very grateful that you spent some time participating in the study. We wanted to inform you that the link to the interview you tried to access has expired. If you have any questions for our team feel free to reach out via email at ${constant.app_email} or phone ${constant.app_phone}.`,
    link_expire_title: "Interview Link Expired!",
    rollover_question: 'Rollover Question',
    rollover_question_info_text: "Is this question rollover from another interview?",
    rollover_question_variable_info_text: "Please enter the label/variable name for the question you wish to roll over.",
    rollover_question_interview_info_text: "Please select the interview of the question you wish to roll over.",
    workflow_rollover_question_settings: "Workflow Rollover Question Settings (Optional)",
    interview_completed_message: "Congratulations! Your interview is 100% completed. Thank you for your time and participation!",
    triggers_no_longer_pregnant: "Selecting 'No' will trigger email to participant that they are no longer pregnant.",
    no_files_to_show: "No Files to show!",
    drag_and_drop_files: "Drag 'n' drop some files here, or click to select files",
    file_upload_limit: "File upload limit: 500MB maximum per file.",
    files: "Files",
    loading: "Loading",
    are_you_sure_to_delete: "Are you sure you want to delete this entry?",
    something_went_wrong: "Something went wrong. Please try again later.",
    contact_information_updated_message: "Your contact information has been successfully updated!",
    answer_all_required_que: "Please answer all the (*) required questions!",
    your_consent_is_recorded: "Your consent has been successfully recorded!",
    max_files: "Max 5 files",
    each_file_less_than: "Each file must be less than 2Mb",
    interview_saved_successfully: "Interview Saved Successfully!",
    unable_to_save_interview: "Unable to save interview!",
    unable_to_save_contact_info: "Unable to Update Participant Contact Info! Try Again",
    i_agree: "I Agree",
    disagree: "Disagree",
    forgotten_password: "Forgotten password?",
    login: "Login",
    sign_in_to_your_account: "Sign In to your account",
    login_as: "Login as",
    login_welcome_text: `Welcome to the ${constant.app_name} Dashboard! Please login to your account with your credentials [username and password]. Thank you for your support in advancing child health!`,
    login_with_password: "Login with password",
    continue: "Continue",
    password_reset_link_sent: "Password reset link has been sent to your email. Please check your inbox to proceed.",
    link_valid_for_note: "Note that the link will be valid for one hour.",
    please_enter_email: "Please enter your email",
    my_profile: "My Profile",
    change_password: "Change password",
    logout: "Logout",
    password_changed_success: "Password successfully changed. Click the 'Login' button to log in with your new credentials.",
    password_reset_link_expire: "The password reset link has expired or contains an invalid token.",
    mark_as_bookmark_message: `To bookmark this page:\n - Press Ctrl/Cmd + D, or\n - Click on the star icon in the address bar, or\n - Open the browser menu and select 'Bookmark This Page'.`,
    lock_temporarily: "Lock Temporarily",
    interview_temporarily_locked: "Interview Temporarily Locked",
    interview_locked_subtext: `Thank you so much for participating in the ${constant.app_name}! On behalf of our team, we are very grateful that you spent some time participating in the study. We wanted to inform you that the interview you tried to access is currently locked. If you have any questions or need assistance, feel free to reach out to our team via email at ${constant.app_email} or phone ${constant.app_phone}.`,
    unsaved_changes_message: "You have unsaved changes. Are you sure you want to leave?",
    search: "Search",
    find: "Find",
    clear: "Clear",
    study_status: "Study Status",
    url_copied_success: "URL copied successfully!",
    send_immediately: "Send Immediately",
    send_after_lapse: "Send after lapse of time:",
    send_at_exact: "Send at exact date/time",
    send_before_six: "Send before 6 hours of the scheduled time",
    send_before_one: "Send before 1 hours of the scheduled time",
    just_once: "Just Once",
    multiple_times: "Multiple times: Send every",
    after_initially_sent: "after initially being sent",
    optional: "Optional",
    click_to_remove_email: "Click to remove email",
    or: "Or",
    take_picture: "Take Picture",
    camera: "Camera",
    selected_files: "Selected Files",
    retake: "Retake",
    capture_photo: "Capture Photo",
    switch_to: "Switch To",
    done: "Done",
    front: "Front",
    rear: "Rear",
    stop: "Stop",
    notification: "Notification",
    unsave_biospecimen_warning_message: "You have unsaved biospecimen changes. Are you sure you want to leave?",
    unsave_specimen_warning_message: "You have unsaved specimen type details changes. Are you sure you want to leave?",
    unsave_aliquoting_specimen_warning_message: "You have unsaved aliquoting specimen changes. Are you sure you want to leave?",
    biospecimen_saved: "Biospecimen saved successfully!",
    specimen_type_saved: "Specimen type details saved successfully!",
    aliquot_specimen_saved: "Aliquot Specimen type saved successfully!",
    are_you_sure_to_delete_this_entry: "Are you sure to delete this entry?",
    and: 'and',
    return_later: 'Return later',
    minimum: 'Minimum',
    maximum: "Maximum",
    minimum_value_info_text: "Required minimum value of the question",
    maximum_value_info_text: "Required maximum value of the question",
    error_messages: "Error Messages (Optional)",
    error: "Error",
    date_format: "Date/Datetime/Time",
    alpha: "Letters Only",
    numeric: "Number",
    digits: "MRN (10 digits/generic) / Phone",
    integer: "Integer",
    decimal: "Decimal Number",
    regex: "Zipcode",
    after_or_equal: "Minimum Date",
    before_or_equal: "Maximum Date",
    min: "Minimum Number",
    max: "Maximum Number",
    read_resource_guide: `- To read the English resource guide, <a href='${constant.english_resource_guide_link}'> click here </a>`,
    interview_complete_1: "You have completed your ",
    interview_complete_2: ` for the ${constant.app_name_full}. `,
    interview_complete_3: `To thank you for your participation, check out the NJKS resource guide, English <a href='${constant.english_resource_guide_link}'>click here</a>, Espanol <a href='${constant.spanish_resource_guide_link}'> click here </a> which is full of information to support healthy pregnancies and families in New Jersey.`,
    interview_complete_4: `You should receive your first Biospecimen Kit in the mail in 1-2 weeks. To learn more about what to expect, watch our ${constant.app_name} Biospecimen Box Opening Video <a href='${constant.biospecimen_box_opening_video_link}'>here</a>. If you have any questions or concerns, contact us at ${constant.email_app_email} or ${constant.email_app_phone}. You can access your Participant Dashboard at <a href='${constant.app_link}'>Login</a>. <br/> <br/> <strong> Thank you for supporting the ${constant.research_study_name}! </strong>`,
    questionnaire: 'Questionnaire',
    form: "Form",
    header: "Header",
    footer: "Footer",
    show_popup_message: "Show Popup Message",
    popup_message: "Popup Message",
    popup_condition: "Popup Condition",
    popup_message_info_text: "Show popup message when question is answered",
    operator: "Operator",
    please_enter_exact: "Please Enter Exact",
    ok: "Ok",
    url_copied: "Url copied successfully!",
    failed_url_copy: "Failed to copy URL!",
    remap_languages: "Remap Languages",
    remap_languages_successfully: "Remap languages successfully!",
    remap_languages_failed: "Failed to remap languages!",
    are_you_sure_to_remap: "Are you sure to remap languages of instruments?",
    study_introduction: "Study Introduction",
    first_name: "First Name",
    last_name: "Last Name",
    cell_phone: "Cell Phone",
    home_phone: "Home Phone",
    work_phone: "Work Phone",
    this_is_required_field: "This is a required field",
    if_you_are_not_eligible_for_study: "If you are not eligible for this study and believe this is an error, please don't hesitate to contact us.",
    to_continue_survey_check_email: "To continue your survey, log in using the credentials provided in the email.",
    login_credential_sent_to_your_below_contact_info: "Your login credentials have been sent to your registered email and phone number as shown below:",
    you_are_best_we_appreciate: "You’re the Best! We really appreciate you taking the time to complete this interview.",
    to_learn_more_about_study: "To learn more about the study,",
    click_here: "Click here.",
    kit_will_be_sent_to_address: "Your kit will be sent to the address you provided. If the address is not correct, please log in to your dashboard to update your contact information or reach out to the study team.",
    resources: "Resources",
    resources_text: `<h3 class='text-center'> Lorem Ipsum </h3> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. <br/> Lorem ipsum dolor sit amet <a target='__blank' href='https://google.com'>Click Here</a> <br/> Lorem ipsum dolor sit amet <a target='__blank' href='https://google.com'>Click Here</a>`,
    spanish_interview_name: "Spanish Interview Name",
    volume: "Volume",
    unsigned_giftcard_notifications_disabled: 'Disable Unsigned Gift Card Notifications',
    unsigned_giftcard_notificatipns_disabled_warning_message: "Please note that unsigned gift card notifications have been disabled, and participants will no longer receive gift card reminder notifications.",
    notification_history: "Notification History",
    sms: "SMS",
    sent_at: "Sent at",




  },

  spanish: {
    dashboard: 'Tablero',
    system: 'Sistema',
    master: 'Lista',
    language: 'Idioma',
    languages: 'Idiomas',
    category: 'Categoría',
    country: 'País',
    countries: 'Países',
    state: 'Estado',
    states: 'Estados',
    city: 'Cuidad',
    streettype: 'Type',
    street: 'Nombre de la calle',
    direction: 'Dirección',
    residence: 'Residencia',
    residences: 'Residencias',
    recruitment: 'Reclutamiento',
    recruitments: 'Reclutamientos',
    socialgroup: 'Grupo Social',
    socialgroups: 'Grupos Sociales',
    speakinglanguages: 'Idiomas que hablan',
    purpose: 'Propósito',
    purposes: 'Propósitos',
    typeofcontact: 'Tipo de contacto',
    typeofcontacts: 'Tipo de contactos',
    syssetting: 'Configuración del sistema',
    contactsetting: 'Configuración de contacto',
    user: 'Usuario',
    users: 'Los usuarios',
    role: 'Papel',
    gensetting: 'Ajustes generales',
    myprofile: 'Perfil',
    changepassword: 'Cambia la contraseña',
    databank: 'Banco de datos',
    participant: 'participante',
    participants: 'participante',
    instrument: 'Instrumento',
    interview: 'Entrvista',
    languages: 'Idiomas',
    name: 'El nombre',
    action: 'Acción',
    edit: 'Corregir',
    delete: 'Borrar',
    add: 'Añadir',
    back: 'Atrás',
    update: 'Actualizar',
    srno: 'Número de serie',
    submit: 'Someter',
    reset: 'Reiniciar',
    enter: 'Entrar',
    categories: 'Categoría',
    select: 'Seleccione',
    email: 'Correo electrónico',
    first: 'Primer Nombre',
    last: 'Apellido',
    username: 'Nombre de usuario',
    other: 'Otro',
    regulation: 'Regulación',
    id: 'Identificacion',
    dob: 'Fecha de nacimiento',
    gender: 'Sexo',
    address: 'Dirección',
    address2: 'Dirección 2',
    zip: 'Codigo Postal',
    home: 'Teléfono de casa',
    work: 'Teléfono de trabajo',
    phone: 'Teléfono',
    phone_1: 'teléfono no 1',
    phone_2: 'teléfono no 2',
    phone_3: 'teléfono no 3',
    phone_4: 'teléfono no 4',
    cell: 'Teléfono cellular',
    comment: 'Comentario',
    waveidentifier: 'Wave Identifier',
    puma: 'PUMA',
    psu: 'PSU',
    ssu: 'SSU',
    ifh_id: 'IFH ID',
    profile: 'perfil',
    picture: 'imagen',
    password: 'contraseña',
    confirm: 'Confirmar',
    old: 'Viejo',
    new: 'Nuevo',
    age: 'Años',
    speaking: 'Hablando',
    reading: 'Leyendo',
    writing: 'Escritura',
    preference: 'Preferencias',
    ifother: 'Si otra',
    abbreviation: 'Abreviatura',
    content: 'Contenido',
    question: 'Pregunta',
    questions: 'Lista de preguntas ',
    details: 'Detalles',
    up: 'Subir',
    down: 'Bajar',
    copy: 'Copiar',
    option: 'Opciones',
    from: 'De',
    options: 'Lista de opciones',
    label: 'Etiqueta',
    help: 'Ayuda',
    parent: 'Original',
    istrigger: 'Es disparador',
    abbrivation: 'Abreviatura',
    for: 'Para',
    assigned: 'Asignado a',
    assign: 'Asignación',
    staff: 'Personal',
    status: 'Estado',
    type: 'Tipo',
    contact: 'Contacto',
    contacts: 'Contactos',
    previous: 'Previa',
    date: 'Fecha',
    time: 'Hora',
    start: 'Comienzo',
    next: 'Proximo',
    save: 'Guardar',
    list: 'Lista',
    kinship: 'Anciano',
    kinships: 'Parentescos',
    tag: 'Etiqueta',
    tags: 'Etiquetas',
    schedule: 'Calendario',
    scheduled: 'Programada',
    field: 'Campo',
    mandatory: 'Obligatoria',
    isrequire: 'Es requerido',
    conduct: 'Conducta',
    pause: 'Pausa',
    assignment: 'Asignación',
    relationship: 'Relación',
    with: 'Con',
    method: 'Método',
    bloodsample: 'Muestra de sangre',
    tubecount: 'Recuento de tubos',
    bsd: 'bsd',
    watch: 'Reloj',
    pddate: 'Fecha',
    code: 'Código',
    compltreport: 'Reporte',
    coding: 'Codificación',
    percentage: 'Porcentaje',
    answer: 'Responda',
    thankyou: 'Gracias',
    display: 'Monitor',
    sendemail: 'enviar correo electrónico',
    emailsent: 'email enviado',
    preview: 'Avance',
    consent: 'Consentir',
    studyid: 'Study Status',
    familyid: 'familiar Id',
    hascomment: 'Tiene comentario',
    participantid: 'ParticipantId',
    section: 'Sección',
    child: 'niña',
    parent: 'madre',
    speaking_language: 'Lenguaje hablado',
    study_type: 'Tipo de estudio',
    test_name: 'Nombre de la prueba',
    test_completed: 'Terminado',
    view_details: 'Ver detalles',
    apt: 'Departamento',
    total: 'Total',
    assignments: 'Asignaciones',
    mockparticipant: 'Participante simulada',
    reassign: 'Reasignar',
    completion: 'Terminación',
    clone: 'Clon',
    sync: 'Sincronizar',
    contacted: 'Contactada',
    not_contacted: 'Aún no contactado',
    history: 'Historia',
    device: 'Dispositivo',
    sms_template: 'Plantilla de SMS',
    description: 'Descripción',
    checklist: 'Lista de Verificación',
    history: 'Historia',
    item: 'Artículo',
    price: 'Precio',
    quantity: 'Cantidad',
    purchase: 'Compra',
    ref_no: 'Número de referencia.',
    purchase_by: 'Comprado por',
    dispatch: 'Despacho',
    receive: 'Recibir',
    phlebotomy: 'Flebotomía y recolección de saliva',
    equipment: 'Equipo',
    tube: 'Tubo',
    test: 'Prueba',
    barcode: 'Código de barras',
    qrcode: 'Código QR',
    capacity: 'Capacidad',
    unit: 'Unidad',
    inventory_no: 'Número de inventario',
    draw_from: 'Sangre extraída de',
    filled_to_capacity: 'Lleno a capacidad',
    second_attempt: 'Segundo intento',
    end: 'Fin',
    result: 'Resultado',
    result_recorded_date: 'Resultado Fecha de registro',
    result_recorded_time: 'Resultado Tiempo grabado',
    anomalies: 'Anomalías',
    normal_range: 'Rango normal',
    notes: 'Notas',
    need_review: 'Necesita revisión',
    ldd: 'LDD',
    lloq: 'LLOQ',
    selected: 'Seleccionado',
    current: 'Actual',
    start_time: 'Hora de inicio',
    end_time: 'Hora de finalización',
    assign_date: 'Asignar fecha',
    unassign_date: 'Desasignar fecha',
    number: "Número",
    canvassing: 'escrutinio',
    association: 'Asociación',
    rds: 'RDS',
    coupon: 'Cupón',
    recruitment: 'Reclutamiento',
    mark: 'Marca',
    as: 'como',
    complete: 'Completo',
    remove: 'Eliminar',
    completion: 'Terminación',
    of: 'de',
    reason: 'Razón',
    isCompleted: 'Está completado',
    transfer: 'Transferir',
    householdid: 'Familiar ID',
    familyid: 'Familia ID',
    date_consent_taken: 'Fecha de obtención del consentimiento',
    date_scanned: 'Fecha escaneada',
    file: 'Archivo',
    upload: 'Subir',
    paper: 'Papel',
    remind: 'Recordar',
    me: 'a mí',
    notifications: 'Notificaciones',
    reminders: 'Recordatorios',
    alerts: 'Alertas',
    title: 'Título',
    alertTopic: 'Tema de alerta',
    sendHowManyTimes: 'Enviarlo cuantas veces?',
    whenToSendAlert: '¿Cuándo enviar alerta?',
    message: 'Mensaje',
    settings: 'Ajustes',
    to: 'A',
    subject: 'Sujeto',
    alertExpiration: 'Caducidad de la alerta',
    lot_number: 'Numero de lote',
    tube_status: 'Estado del tubo',
    ups_tracking_no: 'Número de seguimiento de UPS',
    date: 'Fecha',
    tube_fill_status: 'Estado de llenado del tubo',
    phlebotomy_saliva: 'Flebotomía/Saliva',
    tube_container: 'Tubo/Contenedor',
    data_entry_blood_saliva: 'ENTRADA DE DATOS PARA EXTRACCIÓN DE SANGRE Y SALIVA',
    data_entry_for: 'Entrada de datos para',
    eligibility: 'Elegibilidad',
    randomize_questions: 'Preguntas aleatorias',
    randomize_options: 'Opciones aleatorias',
    ethnicity: 'Etnicidad',
    sendConsentEmail: 'Enviar correo electrónico de consentimiento',
    consentEmailSent: 'Correo electrónico de consentimiento enviado',
    data_collection_type: 'Tipo de recopilación de datos',
    view: 'Vista',
    generate: 'Generar',
    triageReport: 'Informe de clasificación',
    open: 'Abierto',
    time_point: 'Punto de tiempo',
    staff_id: 'Personal ID',
    participant_id: 'ID de participante',
    reviewed_by_partner: 'Revisado por socio',
    interview1_date_time: 'Entrevista 1: Fecha y hora',
    interview1_completion_status: 'Entrevista 1: Estado de finalización',
    interview2_date_time: 'Entrevista 2: Fecha y hora',
    interview2_completion_status: 'Entrevista 2: Estado de finalización',
    interview3_date_time: 'Entrevista 3: Fecha y hora',
    interview3_completion_status: 'Entrevista 3: Estado de finalización',
    interview4_date_time: 'Entrevista 4: Fecha y hora',
    interview4_completion_status: 'Entrevista 4: Estado de finalización',
    interview5_date_time: 'Entrevista 5: Fecha y hora',
    interview5_completion_status: 'Entrevista 5: Estado de finalización',
    interview6_date_time: 'Entrevista 6: Fecha y hora',
    interview6_completion_status: 'Entrevista 6: Estado de finalización',
    gift_card: 'Tarjeta de regalo',
    transcribed: 'Transcrito',
    referral: 'Remisión',
    race: 'Carrera',
    reason: 'Razón',
    interview1_staff: 'Entrevista 1: Personal',
    interview2_staff: 'Entrevista 2: Personal',
    interview3_staff: 'Entrevista 3: Personal',
    interview4_staff: 'Entrevista 4: Personal',
    interview5_staff: 'Entrevista 5: Personal',
    interview6_staff: 'Entrevista 6: Personal',
    shelf_number: 'Número de estante',
    tube_rack_number: 'Número de gradilla de tubos',
    sample_position_in_tube_rack: 'Posición de la muestra en el soporte para tubos',
    link_or_card: 'Enlace o Tarjeta',
    card: 'Tarjeta',
    ra_staff: 'RA/Personal',
    date_sent: 'Fecha de envío',
    date_receipt_received: 'Fecha de recepción',
    date_of_activation: 'Fecha de activación si se envía por correo',
    gift_card_voucher: 'Tarjeta de regalo y vale',
    tracking: 'Seguimiento',
    unassigned_report: 'Informe no asignado',
    insurance: 'Seguro',
    participant_info_report: 'Informe de información del participante',
    validate: 'Validar',
    verify: 'Verificar',
    answer_id: 'ID de respuesta',
    instrument_id: 'ID del instrumento',
    language_id: 'ID de idioma',
    question_id: 'ID de pregunta',
    deleted_quetions_answers: 'Preguntas eliminadas Respuestas',
    triggered_quetions_answers: 'Respuestas a preguntas activadas',
    no_parent_quetions_answers: 'No hay respuestas a preguntas activadas por los padres',
    deleted_options_answers: 'Opciones eliminadas Respuestas',
    incomp_report: 'Informe de finalización',
    partial_comp_report: 'Informe parcialmente completado',
    last: 'Último',
    contact: 'Contacto',
    details: 'Detalles',
    of: 'De',
    reluctance: 'Reluctancia',
    severity: 'Gravedad',
    contacted: 'Contactado',
    who: 'OMS',
    not_contacted_yet: 'Aún no contactado',
    not_interviewed_yet: 'Aún no entrevistado',
    total_assigned: 'Total asignado',
    not_contacted_and_interviewed_report: 'Informe no contactado ni entrevistado',
    send: 'Enviar',
    cancel: 'Cancelar',
    amount: 'Cantidad',
    event: 'Evento',
    survey: 'Encuesta',
    coordinator_only: 'Solo coordinador',
    date_of_signature: 'Fecha de la firma',
    date_of_filing: 'Fecha de presentación',
    date_of_interview: 'Fecha de entrevista',
    name_of_subject: 'Nombre del sujeto',
    signature_of_subject: 'Firma del sujeto',
    interviewer_initials: 'Iniciales del entrevistador',
    dear: 'Estimado',
    gift_card_para_1: '¡Gracias por su reciente participación en nuestra investigación! Los miembros de la comunidad como usted desempeñan un papel importante en nuestro trabajo y le agradecemos nuevamente por agregar su voz a nuestra comunidad. ¡No podríamos hacer esto sin ti!',
    gift_card_para_2_sentence_1: 'Como se mencionó, se adjunta su tarjeta de regalo por el monto de ',
    gift_card_para_2_sentence_2: ' Esta tarjeta se puede utilizar en cualquier tienda o comercio en línea a nivel nacional. Para usar la tarjeta, siga las instrucciones que se le enviaron por correo electrónico y firme el recibo a continuación para acusar recibo de la tarjeta. Si tiene alguna pregunta o dificultad para completar el formulario, comuníquese con nosotros a: ',
    gift_card_para_2_sentence_3: ' o llámanos al ',
    gift_card_para_3_sentence_1: "Y no olvides unirte a nuestra comunidad. Desde seminarios hasta actividades de divulgación y nuestra investigación en curso, siempre sucede algo. Si está interesado en saber más sobre nosotros y eventos futuros, simplemente visite nuestro sitio web, ",
    gift_card_para_3_sentence_2: " ¡No podríamos hacer lo que hacemos sin su apoyo!",
    gift_card_para_4_sentence_1: "Gracias por participar en este estudio. Al firmar a continuación, usted reconoce haber recibido ",
    gift_card_para_4_sentence_2: " por participar en este estudio.",
    sincerely: "Atentamente",
    gift_card: 'Tarjeta de regalo',
    organization_line: 'Línea de organización',
    address_line: 'Dirección',
    website: 'Sitio web',
    handle: 'Manejar',
    fax: 'Fax',
    logo: 'Logo',
    team: "Equipo",
    review: 'Revisar',
    would_you_like_to_review: "¿Le gustaría revisar el consentimiento antes de enviar la entrevista?",
    consent_warning_note: "Nota: Una vez finalizada la entrevista, el consentimiento se bloqueará y no podrá modificar las respuestas. Ah, sí, leíste correctamente este importante mensaje de alerta. Este texto de ejemplo se extenderá un poco más para que pueda ver cómo funciona el espaciado dentro de una alerta con este tipo de contenido.",
    updated_at: 'Actualizado en',
    recent: 'Reciente',
    social_media: 'Medios de comunicación social',
    url: 'URL',
    image: 'Imagen',
    interview_expiration_date: 'Fecha de vencimiento de la entrevista',
    redirect_url: 'Redireccionar URL',
    saliva: 'Saliva',
    ID: 'ID',
    collected: 'Recogido',
    in_person_or_mail: 'En persona o por correo',
    arrival_date: 'Fecha de llegada',
    mandatory_survey: 'Encuesta obligatoria',
    supplemental_survey: 'Encuesta complementaria',
    actigraphy: 'Actigrafía',
    blood_draw: 'Extracción de sangre',
    signature: 'Firma',
    mail: 'Correo',
    errors: 'Errores',
    oa_ma_report: 'Informe OA y MA',
    percent_completion_of: 'Porcentaje de finalización de',
    wave: 'Ola',
    criteria: 'Criterios',
    report: 'Informe',
    physical: 'Físico',
    cognitive_assessment: 'Evaluación cognitiva',
    assessment: 'Evaluación',
    lab: 'Laboratorio',
    sent: 'Enviado',
    signature_of_investigator: 'Firma del investigador/individuo que obtiene el consentimiento/asentimiento',
    to_the_best_ability: 'Lo mejor que pude, he explicado y discutido el contenido completo del estudio, incluida toda la información contenida en este formulario de consentimiento. Todas las preguntas del sujeto de investigación y las de sus padres o tutores legales han sido respondidas con precisión.',
    investigator_person: 'Investigador / persona que obtiene el nombre del consentimiento (letra de molde)',
    outbound_tracking_number: 'Número de seguimiento saliente',
    outbound_ship_date: 'Fecha de envío de salida',
    inbound_tracking_number: 'Número de seguimiento entrante',
    inbound_ship_date: 'Fecha de envío entrante',
    storage_date: 'Fecha de almacenamiento',
    shelf: 'Estante',
    additional_details: 'Detalles adicionales',
    sent_via_email: "si se envía por correo electrónico",
    signed: 'Firmado',
    responded: 'Respondido',
    family: 'Familia',
    cohort: 'Grupo',
    close: 'Cerca',
    tree: 'Árbol',
    validation: 'Validación',
    variable: 'Variable',
    duplicate: 'Duplicar',
    hospital: 'Hospital',
    field_is_embedded: 'El campo está incrustado en otro lugar.',
    enumeration: "Enumeración",
    eligible: 'Elegible',
    not_available: 'No disponible',
    follow_up: 'Hacer un seguimiento',
    reminder: 'Recordatorio',
    scheduling: 'Planificación',
    location: 'Ubicación',
    component: 'Componente',
    trigger: 'Desencadenar',
    show: "Espectáculo",
    is_enumeration_informant: '¿La enumeración es informante?',
    probability_or_immigrant_sample: '¿Probabilidad o muestra de inmigrantes?',
    full: 'Lleno',
    total_household_members: 'Total de miembros del hogar',
    total_family_members: 'Total de miembros de la familia',
    download: 'Descargar',
    progress: "Progreso",
    additional_display_information: 'Información de visualización adicional',
    self_directed_interview: "Pantalla de visualización del tiempo de finalización de la entrevista autodirigida",
    required: 'Requerido',
    this_questionnaire: "Este cuestionario tomará aproximadamente",
    to_complete: "completar.",
    complete_questionnaire: "Cuestionario completo",
    now: 'Ahora',
    later: 'Más tarde',
    web_or_phone: 'Web o teléfono',
    in_person_dispatch: 'Envío en persona',
    ssrs: 'SSRS',
    biospecimen: 'muestra biológica',
    kit: 'Equipo',
    shipment_carrier: 'Transportista de envío',
    outbound: 'saliente',
    inbound: 'Entrante',
    condition_on_receipt: 'Condición al recibir',
    laboratory_receipt_date: 'Fecha de recepción del laboratorio',
    laboratory_receipt_condition: 'Condición del recibo de laboratorio',
    specimen_type: 'Tipo de muestra',
    lab_biospecimen_processing: 'Procesamiento de muestras biológicas de laboratorio',
    storage_information: 'Información de almacenamiento',
    storage_location: 'Ubicación de almacenamiento',
    storage_temperature: 'Temperatura de almacenamiento',
    storage_start_date: 'Fecha de inicio del almacenamiento',
    review_date: 'Fecha de revisión',
    spinning_date: 'Fecha de giro',
    aliquoting_date: 'Fecha de alícuota',
    sample_position_in_rack: 'Posición de la muestra en el rack',
    rack_number: 'Número de bastidor',
    google_analytics: 'Google analitico',
    measurement: 'Medición',
    update_and_resend: 'Actualizar y reenviar tarjeta de regalo',
    update_without_resend: 'Actualizar sin reenviar la tarjeta de regalo',
    multimedia: 'Multimedia',
    format: 'Formato',
    optional_file_attachment: 'Archivo adjunto de imagen, audio o vídeo (Opcional):',
    provide_video_url: 'Proporcionar URL del vídeo',
    workflows: 'Flujos de trabajo',
    workflow: 'Flujo de trabajo',
    add_interview_workflow: 'Agregar entrevistas en el flujo de trabajo',
    active_after_completion: 'Activo después de completar el cuestionario anterior.',
    flow: 'Fluir',
    econsent: 'Consentimiento electrónico',
    duration: 'Duración',
    unit: 'Unidad',
    active_after_consent: 'Activo después de completar el consentimiento electrónico',
    active_in: 'Activo en',
    after_completion: 'después de completar el cuestionario anterior',
    active_on_after: 'Activo en / después',
    active_condition: 'Condición activa',
    active_on_date: 'Activo el fecha',
    biospecimen_kit_status: 'Estado del kit de muestras biológicas',
    and_kit_status: "y cuando el estado del kit de muestras biológicas de la entrevista anterior es",
    active_instantly: 'Activo <strong> al instante </strong>',
    screening_tool: 'Herramienta de visualización',
    information: 'Información',
    study: 'Estudiar',
    video_acknowledgment: '¿Ha terminado de ver todo el video?',
    yes: 'Sí',
    no: 'No',
    step: 'Paso',
    study_overview: 'Vídeo de descripción general del estudio',
    continue: 'Continuar',
    not_activated_yet: 'Entrevista no activado todavía!',
    not_finished: 'No terminado!',
    stage: 'Escenario',
    disagreed: 'en desacuerdo',
    eligible_for_study: '¡Elegible para estudiar!',
    ineligible_for_study: '¡No elegible para estudiar!',
    agreed: 'Acordado',
    optional_screening_tool_eligibility_condition: 'Condición de elegibilidad de la herramienta de detección (opcional)',
    condition: 'Condición',
    value: 'Valor',
    seperate_multiple_values: 'Separe varios valores con comas. La condición será verdadera si alguna coincide.',
    overview: 'Descripción general',
    video: 'Video',
    print: 'Imprimir',
    individual: 'Individual',
    please_enter_email: 'Por favor, introduzca su dirección de correo electrónico',
    change: 'Cambiar',
    self_collect_blood_permission: '¿Está interesado en una extracción de sangre usted mismo utilizando el dispositivo TAP Micro?',
    i_am_in: 'estoy en',
    i_am_out: 'estoy fuera',
    member: 'Miembro',
    and_when_econsent_agreed: 'y cuándo se acuerda el consentimiento electrónico.',
    text: 'Texto',
    hint: 'Pista',
    placeholder: 'Marcador de posición',
    default: 'Por defecto',
    introduction: 'Introducción',
    identifier_info_text: '¿El campo contiene información de identificación (por ejemplo, nombre, número de seguro social, dirección)?',
    required_info_text: '* Preguntar si el campo está en blanco',
    variable_name_info_text: 'Sólo letras, números y guiones bajos',
    disable_info_text: "Elija 'í' para ocultarla y 'No' para mantener esta pregunta activa.",
    help_info_text: "Información adicional debajo de la pregunta",
    placeholder_info_text: "Ingrese el texto para mostrar dentro del campo.",
    default_value_info_text: "Complete automáticamente este campo con un valor preestablecido.",
    auto: 'Auto',
    check_errors_other_lang_instruments: "¡Compruebe si hay errores en otros instrumentos lingüísticos de la entrevista!",
    check_errors_other_lang_instruments_info: "Garantice la precisión de los instrumentos de entrevista revisando posibles problemas, como recuentos de preguntas no coincidentes en otros instrumentos lingüísticos, discrepancias en los tipos de preguntas, recuento de opciones y validaciones como si una pregunta es obligatoria, tiene comentarios o sirve como identificador.",
    participants_having_deleted_questions_answers: "¡Los participantes eliminaron la respuesta a la pregunta en la entrevista!",
    participants_having_deleted_questions_answers_info: "Verificar si los participantes han enviado respuestas a preguntas que fueron eliminadas de los instrumentos en la entrevista.",
    check_errors_other_lang_consent: "Compruebe si hay errores en otros idiomas. ¡Consentimiento!",
    check_errors_other_lang_consent_info: "Garantice la precisión del consentimiento revisando posibles problemas, como secciones no coincidentes o recuentos de preguntas en el consentimiento de otros idiomas, discrepancias en los tipos de preguntas, recuento de opciones y validaciones, como si se requiere una pregunta.",
    multilingual: 'Plurilingüe',
    stay: 'Permanecer',
    leave: 'Dejar',
    workflow_not_eligible_text: `¡Gracias por tu interés en ${constant.app_name}! Lamentablemente, en este momento, sus respuestas indican que no sería elegible para participar en el estudio. No dude en ayudar a difundir este estudio entre amigos o familiares que crea que pueden ser elegibles para unirse al estudio.`,
    congratulations: '¡Felicitaciones',
    congratulations_screen_text: `Según las respuestas que proporcionaste en la encuesta de selección, <h5 class='d-inline'> eres elegible para participar en el estudio. </h5> Asegúrate de ver el video de descripción general del ${constant.app_name} para obtener más información sobre el estudio y lo que implica participar para ver si el estudio es adecuado para ti.`,
    best_wishes: 'Los mejores deseos',
    the_ks_team: `${constant.email_app_sign_full}`,
    show_input_field: 'Mostrar campo de entrada',
    aliquot: 'Alícuota',
    specimen: 'Muestra',
    freezer: 'Congelador',
    tube_freezer_storage_box_name: 'Nombre de la caja de almacenamiento del congelador de tubos',
    tube_freezer_storage_box_position_name: 'Nombre de la posición de la caja de almacenamiento del congelador de tubos',
    source: 'Fuente',
    pregnant: 'Embarazado',
    rows: 'Filas',
    columns: 'columnas',
    create: 'Crear',
    storage: 'Almacenamiento',
    box: 'Caja',
    leave_empty_to_auto_generate: 'Déjelo en blanco para generar valor automáticamente',
    please_select_freezer_stoarage_box: 'Por favor seleccione la caja de almacenamiento del congelador',
    click_icon_create_storage_box: 'Haga clic en el icono para crear una nueva caja de almacenamiento en el congelador.',
    click_icon_select_storage_position: 'Haga clic en el icono para seleccionar una posición de la caja de almacenamiento del congelador',
    participation_in_study_closed_out: "Su participación en el estudio ha sido cerrada.",
    please_enter_email_or_phone: "Por favor ingrese su dirección de correo electrónico o número de teléfono",
    preferred_method_of_communication: 'Método preferido de comunicación',
    opt_in: 'Optar en',
    opt_out: 'Optar por no',
    cheatblocker: "bloqueador de trampas",
    compare_dates_by: 'Comparar fechas por',
    time_period: 'Periodo de tiempo',
    criteria: 'Criterios',
    field: 'Campo',
    in: 'En',
    interview_answer_condition: 'Condición de respuestas de la entrevista',
    after_previous_answer_provided: "<strong> respuesta requerida proporcionada </strong> del cuestionario",
    even_after_previous_answer_missing: "incluso después de que falte la <strong> respuesta requerida </strong> del cuestionario anterior",
    depends_on_previous_interview: 'Depende de la entrevista anterior',
    answer_provided_for_interview: 'Respuesta proporcionada para la entrevista',
    required_interview: 'Entrevista requerida',
    after: 'después',
    its_kit_status: "y cuando el estado del kit de muestras biológicas es",
    after_completion_of_econsent: 'después de completar el consentimiento electrónico',
    to_activate_this_interview: 'para activar esta entrevista',
    filled_returned: 'Llenado y devuelto',
    urine: 'Orina',
    browse_files: 'Búsqueda de archivos',
    upload_files: 'Subir archivos',
    media: 'Medios de comunicación',
    path: 'Camino',
    resolution: 'Resolución',
    size: 'Tamaño',
    uploading: 'Subiendo',
    contact_form_message: `¡Bienvenido a ${constant.app_name}! Tómese un momento para compartir su información de contacto con nosotros para que podamos mantenernos en contacto con las actualizaciones sobre el estudio.`,
    after_interviews_kit_status: "El <strong> estado del kit de muestras biológicas </strong> de las entrevistas es",
    active_on_condition: 'Activo a condición',
    active_without_answer_kit_submission: 'Activo sin respuesta a la entrevista ni envío del kit de muestras biológicas.',
    active_after_4_weeks_final_reminder: "O activo después de 4 semanas desde el recordatorio final de la encuesta/kit de entrevista requerida",
    active_after_postnatal: "después del período posnatal",
    zipcode: 'Código postal',
    are_this_true: "¿Alguno de estos es cierto actualmente?",
    no_longer_pregnant: "ya no estas embarazada",
    moved_out_of_state: "Te has mudado fuera del estado",
    not_meet_criteria_for_study: "Gracias por su interés. Lamentablemente, usted no cumple con los criterios de elegibilidad para este estudio.",
    workflow_not_eligible_text_2: `¡Gracias por tu interés en ${constant.app_name}! Dado que nuestro estudio tiene requisitos muy específicos para participar, no todos son elegibles para unirse al estudio. Lamentablemente, en este momento, sus respuestas indican que no se cumplieron los <h5 class='d-inline'> requisitos de elegibilidad para el estudio. </h5> Queríamos enviar una guía especial con recursos comunitarios útiles para personas embarazadas y padres. Puedes descargar la guía aquí. También se enviará una copia a su correo electrónico. Nos encantaría permanecer en contacto para informarle sobre las cosas que aprendemos de este estudio. Asegúrese de visitarnos en ${constant.email_app_public_website} para recibir boletines y actualizaciones.`,
    study_video_title: "Obtenga más información sobre el estudio y lo que implica viendo este breve video.",
    email_app_sign_full: `El ${constant.email_app_sign_full}`,
    warmest_regards: 'El más cálido saludo',
    questionnaire_completed_subtext: "¡Gracias por completar su cuestionario! Nuestro equipo está preparando su próximo kit de muestra para su envío. Le avisaremos cuando su kit haya sido enviado y esté en camino hacia usted. Les deseo una gran semana por delante.",
    questionnaire_completed: "¡Cuestionario completado!",
    participation_close_subtext: `¡Muchas gracias por participar en ${constant.app_name}! En nombre de nuestro equipo, estamos muy agradecidos de que haya dedicado algún tiempo a participar en el estudio. Solo queríamos informarle que su participación en el estudio ha sido cerrada. Si tiene alguna pregunta para nuestro equipo o desea volver a unirse al estudio en otro momento en el futuro, no dude en comunicarse por correo electrónico a ${constant.app_email} o por teléfono a ${constant.app_phone}.`,
    participation_close_title: "¡La participación en el estudio está cerrada!",
    link_expire_subtext: `¡Muchas gracias por participar en ${constant.app_name}! En nombre de nuestro equipo, estamos muy agradecidos de que haya dedicado algún tiempo a participar en el estudio. Queríamos informarte que el enlace a la entrevista a la que intentaste acceder ha caducado. Si tiene alguna pregunta para nuestro equipo, no dude en comunicarse con nosotros por correo electrónico a ${constant.app_email} o por teléfono a ${constant.app_phone}.`,
    link_expire_title: "¡El enlace de la entrevista expiró!",
    rollover_question: 'Pregunta de transferencia',
    rollover_question_info_text: "¿Esta pregunta es una transferencia de otra entrevista?",
    rollover_question_variable_info_text: "Ingrese la etiqueta/nombre de la variable para la pregunta que desea transferir.",
    rollover_question_interview_info_text: "Seleccione la entrevista de la pregunta que desea transferir.",
    workflow_rollover_question_settings: "Configuración de preguntas de transferencia del flujo de trabajo (opcional)",
    interview_completed_message: "¡Felicidades! Su entrevista está 100% completada. ¡Gracias por su tiempo y participación!",
    triggers_no_longer_pregnant: "Al seleccionar 'No', se enviará un correo electrónico a la participante informándole que ya no está embarazada.",
    no_files_to_show: "¡No hay archivos para mostrar!",
    drag_and_drop_files: "Arrastre y suelte algunos archivos aquí o haga clic para seleccionar archivos",
    file_upload_limit: "Límite de carga de archivos: 500 MB máximo por archivo.",
    files: "Archivos",
    loading: "Cargando",
    are_you_sure_to_delete: "¿Estás seguro de que deseas eliminar esta entrada?",
    something_went_wrong: "Algo salió mal. Por favor, inténtelo de nuevo más tarde.",
    contact_information_updated_message: "¡Su información de contacto ha sido actualizada exitosamente!",
    answer_all_required_que: "¡Por favor responda todas las (*) preguntas requeridas!",
    your_consent_is_recorded: "¡Su consentimiento se ha registrado correctamente!",
    max_files: "Máximo 5 archivos",
    each_file_less_than: "Cada archivo debe tener menos de 2Mb",
    interview_saved_successfully: "¡Entrevista guardada correctamente!",
    unable_to_save_interview: "¡No se puede guardar la entrevista!",
    unable_to_save_contact_info: "¡No se puede actualizar la información de contacto del participante! Inténtalo de nuevo.",
    i_agree: "Estoy de acuerdo",
    disagree: "Discrepar",
    forgotten_password: "¿Contraseña olvidada?",
    login: "Acceso",
    sign_in_to_your_account: "Iniciar sesión en su cuenta",
    login_as: "Iniciar sesión como",
    login_welcome_text: `¡Bienvenido al panel de control de ${constant.app_name}! Inicie sesión en su cuenta con sus credenciales [nombre de usuario y contraseña]. ¡Gracias por su apoyo para promover la salud infantil!`,
    login_with_password: "Iniciar sesión con contraseña",
    continue: "Continuar",
    password_reset_link_sent: "El enlace para restablecer la contraseña ha sido enviado a su correo electrónico. Por favor revisa tu bandeja de entrada para continuar.",
    link_valid_for_note: "Tenga en cuenta que el enlace será válido durante una hora.",
    please_enter_email: "Por favor introduzca su correo electrónico",
    my_profile: "Mi perfil",
    change_password: "Cambiar la contraseña",
    logout: "Cerrar sesión",
    password_changed_success: "Contraseña cambiada correctamente. Haga clic en el botón 'Iniciar sesión' para iniciar sesión con sus nuevas credenciales.",
    password_reset_link_expire: "El enlace para restablecer la contraseña ha caducado o contiene un token no válido.",
    mark_as_bookmark_message: `Para marcar esta página como favorita:\n - Presione Ctrl/Cmd + D, o\n - Haga clic en el ícono de estrella en la barra de direcciones, o\n - Abra el menú del navegador y seleccione 'Marcar esta página como favorita'.`,
    lock_temporarily: "Bloquear temporalmente",
    interview_temporarily_locked: "Entrevista bloqueada temporalmente",
    interview_locked_subtext: `¡Muchas gracias por participar en ${constant.app_name}! En nombre de nuestro equipo, estamos muy agradecidos de que haya dedicado algún tiempo a participar en el estudio. Queríamos informarle que la entrevista a la que intentó acceder se encuentra actualmente bloqueada. Si tiene alguna pregunta o necesita ayuda, no dude en comunicarse con nuestro equipo por correo electrónico a ${constant.app_email} o por teléfono a ${constant.app_phone}.`,
    unsaved_changes_message: "Tiene cambios sin guardar. Estás seguro que quieres irte?",
    search: "Buscar",
    find: "Encontrar",
    clear: "Claro",
    study_status: "Estado del estudio",
    url_copied_success: "¡La URL se copió correctamente!",
    send_immediately: "Enviar inmediatamente",
    send_after_lapse: "Enviar después de transcurrido el tiempo:",
    send_at_exact: "Enviar en fecha/hora exacta",
    send_before_six: "Enviar antes de las 6 horas de la hora prevista",
    send_before_one: "Enviar antes de 1 hora de la hora programada",
    just_once: "Sólo una vez",
    multiple_times: "Varias veces: enviar cada",
    after_initially_sent: "después de haber sido enviado inicialmente",
    optional: "Opcional",
    click_to_remove_email: "Haga clic para eliminar el correo electrónico",
    or: "O",
    take_picture: "Tomar la foto",
    camera: "Cámara",
    selected_files: "Archivos seleccionados",
    retake: "Volver a tomar",
    capture_photo: "Capturar foto",
    switch_to: "Cambiar a",
    done: "Hecho",
    front: "Frente",
    rear: "Trasero",
    stop: "Detener",
    notification: "Notificación",
    unsave_biospecimen_warning_message: "Tiene cambios de muestras biológicas no guardados. Estás seguro que quieres irte?",
    unsave_specimen_warning_message: "Tiene cambios en los detalles del tipo de muestra sin guardar. Estás seguro que quieres irte?",
    unsave_aliquoting_specimen_warning_message: "Tiene cambios de muestras de alícuotas no guardados. Estás seguro que quieres irte?",
    biospecimen_saved: "¡Bioespecimen guardado exitosamente!",
    specimen_type_saved: "¡Los detalles del tipo de muestra se guardaron correctamente!",
    aliquot_specimen_saved: "¡El tipo de muestra alícuota se guardó correctamente!",
    are_you_sure_to_delete_this_entry: "¿Estás seguro de eliminar esta entrada?",
    and: 'y',
    return_later: 'Regresar más tarde',
    minimum: 'Mínimo',
    maximum: "Máximo",
    minimum_value_info_text: "Valor mínimo requerido de la pregunta.",
    maximum_value_info_text: "Valor máximo requerido de la pregunta.",
    error_messages: "Mensajes de error (opcional)",
    error: "Error",
    date_format: "Fecha/Fechahora/Hora",
    alpha: "Sólo letras",
    numeric: "Número",
    digits: "MRN (10 dígitos/genérico) / Teléfono",
    integer: "Entero",
    decimal: "Número decimal",
    regex: "Código postal",
    after_or_equal: "Fecha mínima",
    before_or_equal: "Fecha máxima",
    min: "Número mínimo",
    max: "Número máximo",
    read_resource_guide: `- Para leer la guía de recursos en inglés, <a href='${constant.spanish_resource_guide_link}'> haga clic aquí </a>`,
    interview_complete_1: "Has completado tu ",
    interview_complete_2: ` para el ${constant.app_name_full}. `,
    interview_complete_3: `Para agradecerle su participación, consulte la guía de recursos de NJKS, en inglés <a href='${constant.english_resource_guide_link}'>haga clic aquí</a>, en español <a href='${constant.spanish_resource_guide_link}'>haga clic aquí </a>, que está repleta de información para apoyar embarazos y familias saludables en Nueva Jersey.`,
    interview_complete_4: `Recibirá su primer kit de muestras biológicas por correo en 1 o 2 semanas. Para obtener más información sobre qué esperar, mire nuestro video de apertura de la caja de muestras biológicas de ${constant.app_name} <a href='${constant.biospecimen_box_opening_video_link}'>aquí</a>. Si tiene alguna pregunta o inquietud, contáctenos a ${constant.email_app_email} o ${constant.email_app_phone}. Puede acceder a su Panel de participantes en <a href='${constant.app_link}'>Iniciar sesión</a>. <br/> <br/> <strong> ¡Gracias por apoyar el ${constant.research_study_name}! </strong>`,
    questionnaire: 'Cuestionario',
    form: "Forma",
    header: "Encabezamiento",
    footer: "Pie de página",
    show_popup_message: "Mostrar mensaje emergente",
    popup_message: "Mensaje emergente",
    popup_condition: "Condición de ventana emergente",
    popup_message_info_text: "Mostrar mensaje emergente cuando se responde la pregunta",
    operator: "Operador",
    please_enter_exact: "Por favor, introduzca el valor exacto",
    ok: "De acuerdo",
    url_copied: "Url copiado con éxito!",
    failed_url_copy: "¡No se pudo copiar la URL!",
    remap_languages: "Reasignación de idiomas",
    remap_languages_successfully: "¡Reasigna idiomas con éxito!",
    remap_languages_failed: "¡No se pudo reasignar idiomas!",
    are_you_sure_to_remap: "¿Está seguro de reasignar los idiomas de los instrumentos?",
    study_introduction: "Introducción al studio",
    first_name: "Primer Nombre",
    last_name: "Apellido",
    cell_phone: "Teléfono Celular",
    home_phone: "Teléfono de Casa",
    work_phone: "Teléfono de Trabajo",
    this_is_required_field: "Este es un campo obligatorio",
    if_you_are_not_eligible_for_study: "Si no es elegible para este estudio y cree que se trata de un error, no dude en contactarnos.",
    to_continue_survey_check_email: "Para continuar con su encuesta, inicie sesión utilizando las credenciales proporcionadas en el correo electrónico.",
    login_credential_sent_to_your_below_contact_info: "Sus credenciales de inicio de sesión se han enviado a su correo electrónico y número de teléfono registrados como se muestra a continuación:",
    you_are_best_we_appreciate: "¡Eres el mejor! Realmente agradecemos que te hayas tomado el tiempo para completar esta entrevista.",
    to_learn_more_about_study: "Para obtener más información sobre el estudio,",
    click_here: "Haga clic aquí.",
    kit_will_be_sent_to_address: "Su kit será enviado a la dirección que proporcionó. Si la dirección no es correcta, inicie sesión en su panel de control para actualizar su información de contacto o comunicarse con el equipo del estudio.",
    resources: "Recursos",
 resources_text: `<h3 class='text-center'> Guías de recursos </h3>Ver la guía de recursos en inglés: <a target='__blank' href='https://njkidsstudy.rutgers.edu/kids-study-backend/public/media_uploads/1725304306_NJKS_Resource_Guide_2024.pdf'>Haga clic aquí</a> <br/> Ver la guía de recursos en español: <a target='__blank' href='https://njkidsstudy.rutgers.edu/kids-study-backend/public/media_uploads/1725304325_NJKS_Spanish_Resource_Guide_2024.pdf'>Haga clic aquí</a>`,    
spanish_interview_name: "Nombre de la entrevista en español",
    volume: "Volumen",
    unsigned_giftcard_notifications_disabled: 'Deshabilitar notificaciones de tarjetas de regalo sin firmar',
    unsigned_giftcard_notificatipns_disabled_warning_message: "Tenga en cuenta que las notificaciones de tarjetas de regalo sin firmar se han desactivado y los participantes ya no recibirán notificaciones de recordatorio de tarjetas de regalo.",
    notification_history: "Historial de notificaciones",
    sms: "",
    sent_at: "Enviado a",




  },
  korean: {
    dashboard: '작업관리자',
    system: '시스템',
    master: '마스터',
    language: '언어',
    languages: '언어들',
    category: '카테고리',
    country: '나라',
    countries: '나라들',
    state: '주',
    states: '주들',
    city: '도시',
    streettype: '도로 종류',
    street: '도로',
    direction: '방향',
    residence: '거주',
    residences: '거주지',
    recruitment: '모집',
    recruitments: '모집',
    socialgroup: '사회 그룹',
    socialgroups: '사회 그룹들',
    speakinglanguages: '말하기 언어들',
    purpose: '목표',
    purposes: '목표들 ',
    typeofcontact: '연락수단',
    typeofcontacts: '연락수단',
    syssetting: '시스템 설정',
    contactsetting: '연락처 설정',
    user: '사용자',
    users: '사용자들',
    role: '역할',
    gensetting: '일반 설정',
    myprofile: '내 프로필',
    changepassword: '암호 변경',
    databank: '인터뷰와 데어터저장소',
    participant: '참가자',
    participants: '참가자들',
    instrument: '수단',
    interview: '인터뷰 ',
    languages: 'Idiomas',
    name: '이름',
    action: '행동',
    edit: '편집',
    delete: '삭제',
    add: '첨부',
    back: '뒤',
    update: '업데이트',
    srno: '아니오',
    submit: '제출',
    reset: '새로고침',
    enter: '입력',
    categories: '카테고리',
    select: '선택',
    email: '이메일',
    first: '첫째',
    last: '마지막',
    username: '사용자 이름',
    other: '그 외',
    regulation: '규칙',
    id: '아이디',
    dob: '생일',
    gender: '성별',
    address: '주소',
    address2: '주소 2',
    zip: '우편번호',
    home: '집a',
    work: '회사',
    phone: '전화',
    phone_1: '전화1',
    phone_2: '전화2',
    phone_3: '전화3',
    phone_4: '전화4',
    cell: '핸드폰',
    comment: '의견',
    waveidentifier: 'Wave Identifier',
    puma: 'PUMA',
    psu: 'PSU',
    ssu: 'SSU',
    ifh_id: 'IFH ID',
    profile: '프로필',
    picture: '사진',
    password: '암호',
    confirm: '확인',
    old: '예전',
    new: '새로운 ',
    age: '나이',
    speaking: '말하기',
    reading: '읽기',
    writing: '쓰기',
    preference: '선호',
    ifother: '그 외인 경우',
    abbreviation: '약자',
    content: '찬성',
    question: '질문',
    questions: '질문들',
    details: '세부사항',
    up: '위',
    down: '아래',
    copy: '복사',
    option: '선택사항',
    from: '로 부터',
    options: '선택사항들',
    label: '분류표',
    help: '도움',
    parent: '부모',
    istrigger: '발생요인은',
    abbrivation: '약자',
    for: '위하여',
    assigned: '지명된',
    assign: '지명',
    staff: '직원',
    status: '상태',
    type: '타입',
    contact: '연락',
    contacts: '연락',
    previous: '이전',
    date: '날짜',
    time: '시간',
    start: '시작',
    next: '다음',
    save: '저장',
    list: '문항',
    kinship: '혈연',
    kinships: '혈연',
    tag: '인식표',
    tags: '인식표들',
    schedule: '예약',
    scheduled: '예약된',
    field: '영역',
    mandatory: '의무적인',
    isrequire: '요구됩니까?',
    conduct: '수행',
    pause: '일시중지',
    assignment: '할당',
    relationship: '관계',
    with: '함께',
    method: '방법',
    bloodsample: '혈액 샘플',
    tubecount: '튜브 개수',
    bsd: '날짜',
    watch: '보다',
    pddate: '날짜',
    code: '코드',
    compltreport: '완료 보고',
    coding: '코딩',
    percentage: '퍼센트 ',
    answer: '대답',
    thankyou: '감사합니다',
    display: '전시',
    sendemail: '이메일 발송',
    emailsent: '이메일 발송완료',
    preview: '미리보기',
    consent: '동의서',
    studyid: '설문 상태',
    familyid: '가족 아이디',
    hascomment: '의견을 적다',
    participantid: '참가자 아이디',
    section: '부분',
    child: '어린이',
    parent: '부모',
    speaking_language: '말하기 언어',
    study_type: '연구 종류',
    test_name: '테스트 이름',
    test_completed: '완료',
    view_details: '세부사항 보기',
    apt: '아파트',
    total: '총',
    assignments: '할당',
    mockparticipant: '가상 참가자',
    reassign: '재할당 ',
    completion: '완료',
    clone: '복제',
    sync: '동기화',
    contacted: '연락했음',
    not_contacted: '연락 전',
    history: '역사',
    device: '장치',
    sms_template: 'SMS Template',
    description: 'Description',
    checklist: 'Checklist',
    history: 'History',
    item: 'Item',
    price: 'Price',
    quantity: 'Quantity',
    purchase: 'Purchase',
    ref_no: 'Ref No.',
    purchase_by: 'Purchased By',
    dispatch: 'Dispatch',
    receive: 'Receive',
    phlebotomy: 'Phlebotomy and Saliva Collection',
    equipment: 'Equipment',
    tube: 'Tube',
    test: 'Test',
    barcode: 'Barcode',
    qrcode: 'QR code',
    capacity: 'Capacity',
    unit: 'Unit',
    inventory_no: 'Inventory No',
    draw_from: 'Blood Drawn From',
    filled_to_capacity: 'Filled To Capacity',
    second_attempt: 'Second Attempt',
    end: 'End',
    result: 'Result',
    result_recorded_date: 'Result Recorded Date',
    result_recorded_time: 'Result Recorded Time',
    anomalies: 'Anomalies',
    normal_range: 'Normal Range',
    notes: 'Notes',
    need_review: 'Need Review',
    ldd: 'LDD',
    lloq: 'LLOQ',
    selected: 'Selected',
    current: 'Current',
    start_time: 'Start Time',
    end_time: 'End Time',
    assign_date: 'Assign Date',
    unassign_date: 'Unassign Date',
    number: "Number",
    canvassing: 'Canvassing',
    association: 'Association',
    rds: 'RDS',
    coupon: 'Coupon',
    recruitment: 'Recruitment',
    mark: 'Mark',
    as: 'as',
    complete: 'Complete',
    remove: 'Remove',
    completion: 'Completion',
    of: 'of',
    reason: 'Reason',
    isCompleted: 'Is Completed',
    transfer: 'Transfer',
    householdid: 'Household ID',
    familyid: 'Family ID',
    date_consent_taken: 'Date Consent Taken',
    date_scanned: 'Date Scanned',
    file: 'File',
    upload: 'Upload',
    paper: 'Paper',
    remind: 'Remind',
    me: 'me',
    notifications: 'Notifications',
    reminders: 'Reminders',
    alerts: 'Alerts',
    title: 'Title',
    alertTopic: 'Alert topic',
    sendHowManyTimes: 'Send it how many times?',
    whenToSendAlert: 'When to send alert?',
    message: 'Message',
    settings: 'Settings',
    to: 'To',
    subject: 'Subject',
    alertExpiration: 'Alert Expiration',
    lot_number: 'Lot Number',
    tube_status: 'Tube Status',
    ups_tracking_no: 'UPS Tracking Number',
    date: 'Date',
    tube_fill_status: 'Tube Fill Status',
    phlebotomy_saliva: 'Phlebotomy/Saliva',
    tube_container: 'Tube/Container',
    data_entry_blood_saliva: 'DATA ENTRY FOR BLOOD DRAW AND SALIVA',
    data_entry_for: 'Data Entry For',
    eligibility: 'Eligibility',
    randomize_questions: 'Randomize Questions',
    randomize_options: 'Randomize Options',
    ethnicity: 'Ethnicity',
    sendConsentEmail: 'Send Consent Email',
    consentEmailSent: 'Consent Email Sent',
    data_collection_type: 'Data Collection Type',
    view: 'View',
    generate: 'Generate',
    triageReport: 'Triage Report',
    open: 'Open',
    time_point: 'Time Point',
    staff_id: 'Staff ID',
    participant_id: 'Participant ID',
    reviewed_by_partner: 'Reviewed By Partner',
    interview1_date_time: 'Interview 1: Date and time',
    interview1_completion_status: 'Interview 1: Completion Status',
    interview2_date_time: 'Interview 2: Date and time',
    interview2_completion_status: 'Interview 2: Completion Status',
    interview3_date_time: 'Interview 3: Date and time',
    interview3_completion_status: 'Interview 3: Completion Status',
    interview4_date_time: 'Interview 4: Date and time',
    interview4_completion_status: 'Interview 4: Completion Status',
    interview5_date_time: 'Interview 5: Date and time',
    interview5_completion_status: 'Interview 5: Completion Status',
    interview6_date_time: 'Interview 6: Date and time',
    interview6_completion_status: 'Interview 6: Completion Status',
    gift_card: 'Gift Card',
    transcribed: 'Transcribed',
    referral: 'Referral',
    race: 'Race',
    reason: 'Reason',
    interview1_staff: 'Interview 1: Staff',
    interview2_staff: 'Interview 2: Staff',
    interview3_staff: 'Interview 3: Staff',
    interview4_staff: 'Interview 4: Staff',
    interview5_staff: 'Interview 5: Staff',
    interview6_staff: 'Interview 6: Staff',
    shelf_number: 'Shelf Number',
    tube_rack_number: 'Tube Rack Number',
    sample_position_in_tube_rack: 'Sample Position in Tube Rack',
    link_or_card: 'Link or Card',
    card: 'Card',
    ra_staff: 'RA/ Staff',
    date_sent: 'Date Sent',
    date_receipt_received: 'Date Receipt Received',
    date_of_activation: 'Date Of Activation If Sent By Mail',
    gift_card_voucher: 'Gift Card & Voucher',
    tracking: 'Tracking',
    unassigned_report: 'Unassigned Report',
    insurance: 'Insurance',
    participant_info_report: 'Participant Info Report',
    validate: 'Validate',
    verify: 'Verify',
    answer_id: 'Answer ID',
    instrument_id: 'Instrument ID',
    language_id: 'Language ID',
    question_id: 'Question ID',
    deleted_quetions_answers: 'Deleted Questions Answers',
    triggered_quetions_answers: 'Triggered Questions Answers',
    no_parent_quetions_answers: 'No Parent Triggered Questions Answers',
    deleted_options_answers: 'Deleted Options Answers',
    incomp_report: 'Incompletion Report',
    partial_comp_report: 'Partially Completed Report',
    last: 'Last',
    contact: 'Contact',
    details: 'Details',
    of: 'Of',
    reluctance: 'Reluctance',
    severity: 'Severity',
    contacted: 'Contacted',
    who: 'Who',
    not_contacted_yet: 'Not contacted yet',
    not_interviewed_yet: 'Not interviewed yet',
    total_assigned: 'Total Assigned',
    not_contacted_and_interviewed_report: 'Not Contacted & Interviewed Report',
    send: 'Send',
    cancel: 'Cancel',
    amount: 'Amount',
    event: 'Event',
    survey: 'Survey',
    coordinator_only: 'Coordinator Only',
    date_of_signature: 'Date of Signature',
    date_of_filing: 'Date of Filing',
    date_of_interview: 'Date of Interview',
    name_of_subject: 'Name of Subject',
    signature_of_subject: 'Signature of Subject',
    interviewer_initials: 'Interviewer Initials',
    dear: 'Dear',
    gift_card_para_1: 'Thank you for your recent participation in our research! Community members like you play an important role in our work, and we thank you again for adding your voice to our community. We could not do this without you!',
    gift_card_para_2_sentence_1: 'As discussed, enclosed is your gift card in the amount of ',
    gift_card_para_2_sentence_2: ' This card can be used at any store or online merchant nationwide. To use the card, follow the instructions emailed to you and sign the receipt below to acknowledge receiving the card. Should you have any questions or difficulties in completing the form, please contact us at: ',
    gift_card_para_2_sentence_3: ' or call us at ',
    gift_card_para_3_sentence_1: "And don't forget to join our community. From seminars, to outreach, to our ongoing research, there is always something happening. If you are interested in learning more about us and future events, just visit our website, ",
    gift_card_para_3_sentence_2: " We couldn't do what we do without your support!",
    gift_card_para_4_sentence_1: "Thank you for taking part in this study. By signing below, you acknowledge receiving ",
    gift_card_para_4_sentence_2: " for participation in this study.",
    sincerely: "Sincerely",
    gift_card: 'Gift Card',
    organization_line: 'Organization Line',
    address_line: 'Address Line',
    website: 'Website',
    handle: 'Handle',
    fax: 'Fax',
    logo: 'Logo',
    team: "Team",
    review: 'Review',
    would_you_like_to_review: "Would you like to review the consent before submitting the interview?",
    consent_warning_note: "Note: After interview completion, the consent will be locked, and you will not be able to modify the answers. Aww yeah, you successfully read this important alert message. This example text is going to run a bit longer so that you can see how spacing within an alert works with this kind of content.",
    updated_at: 'Updated At',
    recent: 'Recent',
    social_media: 'Social Media',
    url: 'URL',
    image: 'Image',
    interview_expiration_date: 'Interview Expiration Date',
    redirect_url: 'Redirect Url',
    saliva: 'Saliva',
    ID: 'ID',
    collected: 'Collected',
    in_person_or_mail: 'In person or Mail',
    arrival_date: 'Arrival Date',
    mandatory_survey: 'Mandatory Survey',
    supplemental_survey: 'Supplemental Survey',
    actigraphy: 'Actigraphy',
    blood_draw: 'Blood Draw',
    signature: 'Signature',
    mail: 'Mail',
    errors: 'Errors',
    oa_ma_report: 'OA & MA Report',
    percent_completion_of: 'Percent Completion Of',
    wave: 'Wave',
    criteria: 'Criteria',
    report: 'Report',
    physical: 'Physical',
    cognitive_assessment: 'Cognitive Assessment',
    assessment: 'Assessment',
    lab: 'Lab',
    sent: 'Sent',
    signature_of_investigator: 'Signature of Investigator/Individual Obtaining Consent/Assent',
    to_the_best_ability: 'To the best of my ability, I have explained and discussed the full contents of the study including all of the information contained in this consent form. All questions of the research subject and those of his/her parent or legal guardian have been accurately answered.',
    investigator_person: 'Investigator/Person Obtaining Consent Name (Print)',
    outbound_tracking_number: 'Outbound Tracking Number',
    outbound_ship_date: 'Outbound Ship Date',
    inbound_tracking_number: 'Inbound Tracking Number',
    inbound_ship_date: 'Inbound Ship Date',
    storage_date: 'Storage Date',
    shelf: 'Shelf',
    additional_details: 'Additional Details',
    sent_via_email: "if sent via email",
    signed: 'Signed',
    responded: 'Responded',
    family: 'Family',
    cohort: 'Cohort',
    close: 'Close',
    tree: 'Tree',
    validation: 'Validation',
    variable: 'Variable',
    duplicate: 'Duplicate',
    hospital: 'Hospital',
    field_is_embedded: 'Field is embedded elsewhere',
    enumeration: "Enumeration",
    eligible: 'Eligible',
    not_available: 'Not Available',
    follow_up: 'Follow Up',
    reminder: 'Reminder',
    scheduling: 'Scheduling',
    location: 'Location',
    component: 'Component',
    trigger: 'Trigger',
    show: "Show",
    is_enumeration_informant: 'Is Enumeration Informant?',
    probability_or_immigrant_sample: 'Probability or Immigrant sample?',
    full: 'Full',
    total_household_members: 'Total Household Members',
    total_family_members: 'Total Family Members',
    download: 'Download',
    progress: "Progress",
    additional_display_information: 'Additionl Display Information',
    self_directed_interview: "Self Directed Interview Completion Time Display Screen",
    required: 'Required',
    this_questionnaire: "This Questionnaire will take approximately",
    to_complete: "to complete.",
    complete_questionnaire: "Complete Questionnaire",
    now: 'Now',
    later: 'Later',
    web_or_phone: 'Web Or Phone',
    in_person_dispatch: 'In Person Dispatch',
    ssrs: 'SSRS',
    biospecimen: 'Biospecimen',
    kit: 'Kit',
    shipment_carrier: 'Shipment Carrier',
    outbound: 'Outbound',
    inbound: 'Inbound',
    condition_on_receipt: 'Condition on Receipt',
    laboratory_receipt_date: 'Laboratory Receipt Date',
    laboratory_receipt_condition: 'Laboratory Receipt Condition',
    specimen_type: 'Specimen Type',
    lab_biospecimen_processing: 'Lab Biospecimen Processing',
    storage_information: 'Storage Information',
    storage_location: 'Storage Location',
    storage_temperature: 'Storage Temperature',
    storage_start_date: 'Storage Start Date',
    review_date: 'Review Date',
    spinning_date: 'Spinning Date',
    aliquoting_date: 'Aliquoting Date',
    sample_position_in_rack: 'Sample Position in Rack',
    rack_number: 'Rack Number',
    google_analytics: 'Google Analytics',
    measurement: 'Measurement',
    update_and_resend: 'Update and Resend Gift Card',
    update_without_resend: 'Update Without Resending Gift Card',
    multimedia: 'Multimedia',
    format: 'Format',
    optional_file_attachment: 'File attachment image, audio or video (Optional):',
    provide_video_url: 'Provide video URL',
    workflows: 'Workflows',
    workflow: 'Workflow',
    add_interview_workflow: 'Add Interviews in the workflow',
    active_after_completion: 'Active after completion of previous questionnaire.',
    flow: 'Flow',
    econsent: 'E-consent',
    duration: 'Duration',
    unit: 'Unit',
    active_after_consent: 'Active after completion of e-consent',
    active_in: 'Active in',
    after_completion: 'after completion of previous questionnaire',
    active_on_after: 'Active on/after',
    active_condition: 'Active condition',
    active_on_date: 'Active On Date',
    biospecimen_kit_status: 'Biospecimen Kit Status',
    and_kit_status: "and when previous interview's biospecimen kit status is",
    active_instantly: 'Active <strong> instantly </strong>',
    screening_tool: 'Screening Tool',
    information: 'Information',
    study: 'Study',
    video_acknowledgment: 'Have you completed watching the entire video?',
    yes: 'Yes',
    no: 'No',
    step: 'Step',
    study_overview: 'Study Overview Video',
    continue: 'Continue',
    not_activated_yet: 'Interview Not Activated Yet!',
    not_finished: 'Not Finished!',
    stage: 'Stage',
    disagreed: 'Disagreed',
    eligible_for_study: 'Eligible for study!',
    ineligible_for_study: 'Ineligible for study!',
    agreed: 'Agreed',
    optional_screening_tool_eligibility_condition: 'Screening Tool Eligibility Condition (Optional)',
    condition: 'Condition',
    value: 'Value',
    seperate_multiple_values: 'Separate multiple values with commas. The condition will be true if any one matches.',
    overview: 'Overview',
    video: 'Video',
    print: 'Print',
    individual: 'Individual',
    please_enter_email: 'Please enter your email address',
    change: 'Change',
    self_collect_blood_permission: 'Are you interested in a self-collected blood draw using the TAP Micro device.',
    i_am_in: 'I am in',
    i_am_out: 'I am out',
    member: 'Member',
    and_when_econsent_agreed: 'and when e-consent is agreed.',
    text: 'Text',
    hint: 'Hint',
    placeholder: 'Placeholder',
    default: 'Default',
    introduction: 'Introduction',
    identifier_info_text: 'Does the field contain identifying information (e.g. name, SSN, address)',
    required_info_text: '* Prompt if field is blank',
    variable_name_info_text: 'Only letters, numbers & underscores',
    disable_info_text: "Choose 'Yes' to hide, 'No' to keep this question active.",
    help_info_text: "Additional information below the question",
    placeholder_info_text: "Enter text to display inside the field.",
    default_value_info_text: "Automatically fill this field with a preset value.",
    auto: 'Auto',
    check_errors_other_lang_instruments: "Check for errors in interview's other language instruments!",
    check_errors_other_lang_instruments_info: "Ensure accuracy in interview instruments by reviewing for potential issues such as mismatched question counts in other language instruments, discrepancies in question types, options count, and validations like whether a question is required, has comments, or serves as an identifier.",
    participants_having_deleted_questions_answers: "Participants having deleted question's answer in the interview!",
    participants_having_deleted_questions_answers_info: "Verify if participants have submitted answers for questions that were deleted from the instruments in the interview.",
    check_errors_other_lang_consent: "Check for errors in other language consent!",
    check_errors_other_lang_consent_info: "Ensure accuracy in consent by reviewing for potential issues such as mismatched section or question counts in other language consent, discrepancies in question types, options count, and validations like whether a question is required.",
    multilingual: 'Multilingual',
    stay: 'Stay',
    leave: 'Leave',
    workflow_not_eligible_text: `Thank you for your interest in the ${constant.app_name}! Unfortunately, at this time, your responses indicate that you would not be eligible to participate in the study. Please feel free to help spread the word about this study to friends or family members that you think may be eligible to join the study.`,
    congratulations: 'Congratulations',
    congratulations_screen_text: `Based on the answers you provided in the screening survey, <h5 class='d-inline'> you are eligible to participate in the study. </h5> Be sure to watch the ${constant.app_name} overview video to learn more about the study and what’s involved in participating to see if the study is the right fit for you.`,
    best_wishes: 'Best Wishes',
    the_ks_team: `${constant.email_app_sign_full}`,
    show_input_field: 'Show Input Field',
    aliquot: 'Aliquot',
    specimen: 'Specimen',
    freezer: 'Freezer',
    tube_freezer_storage_box_name: 'Tube Freezer Storage Box Name',
    tube_freezer_storage_box_position_name: 'Tube Freezer Storage Box Position Name',
    source: 'Source',
    pregnant: 'Pregnant',
    rows: 'Rows',
    columns: 'Columns',
    create: 'Create',
    storage: 'Storage',
    box: 'Box',
    leave_empty_to_auto_generate: 'Leave blank to automatically generate value',
    please_select_freezer_stoarage_box: 'Please select freezer storage box',
    click_icon_create_storage_box: 'Click the icon to create a new freezer storage box',
    click_icon_select_storage_position: 'Click the icon to select a freezer storage box position',
    participation_in_study_closed_out: "Your participation in the study has been closed out.",
    please_enter_email_or_phone: "Please enter your email address or phone number",
    preferred_method_of_communication: 'Preferred Method Of Communication',
    opt_in: 'Opt In',
    opt_out: 'Opt Out',
    cheatblocker: "Cheatblocker",
    compare_dates_by: 'Compare Dates By',
    time_period: 'Time Period',
    criteria: 'Criteria',
    field: 'Field',
    in: 'In',
    interview_answer_condition: 'Interview Answers Condition',
    after_previous_answer_provided: "questionnaire's <strong> required answer provided </strong>",
    even_after_previous_answer_missing: "even after previous questionnaire's <strong> required answer missing </strong>",
    depends_on_previous_interview: 'Depends On Previous Interview',
    answer_provided_for_interview: 'Answer Provided For Interview',
    required_interview: 'Required Interview',
    after: 'after',
    its_kit_status: "and when it's biospecimen kit status is",
    after_completion_of_econsent: 'after completion of econsent',
    to_activate_this_interview: 'to activate this interview',
    filled_returned: 'Filled & Returned',
    urine: 'Urine',
    browse_files: 'Browse Files',
    upload_files: 'Upload Files',
    media: 'Media',
    path: 'Path',
    resolution: 'Resolution',
    size: 'Size',
    uploading: 'Uploading',
    contact_form_message: `Welcome to the ${constant.app_name}! Please take a moment to share your contact information with us so that we can stay in touch with updates about the study.`,
    after_interviews_kit_status: "interviews's <strong> biospecimen kit status </strong> is",
    active_on_condition: 'Active on condition',
    active_without_answer_kit_submission: 'Active without interview answer and biospecimen kit submission',
    active_after_4_weeks_final_reminder: "Or active after 4 weeks from final reminder of required interview's survey / kit",
    active_after_postnatal: "after postnatal period",
    zipcode: 'Zipcode',
    are_this_true: "Are either of these currently true?",
    no_longer_pregnant: "You are no longer pregnant",
    moved_out_of_state: "You have moved out of state",
    not_meet_criteria_for_study: "Thank you for your interest. Unfortunately, you do not meet the eligibility criteria for this study.",
    workflow_not_eligible_text_2: `Thank you for your interest in the ${constant.app_name}! Since our study has very specific requirements to participate, not everyone is eligible to join the study. Unfortunately, at this time, your answers indicate that the <h5 class='d-inline'> eligibility requirements for the study were not met. </h5> We wanted to send a special guide with helpful community resources for pregnant individuals and parents. You can download the guide here. A copy will also be sent to your email. We would love to stay in touch to let you know about the things we learn from this study. Be sure to visit us at ${constant.email_app_public_website}  for newsletters and updates.`,
    study_video_title: "Learn more about the study and what's involved by watching this short video.",
    email_app_sign_full: `The ${constant.email_app_sign_full}`,
    warmest_regards: 'Warmest Regards',
    questionnaire_completed_subtext: "Thank you for completing your questionnaire! Our team is getting your next sample kit ready for shipment. We'll let you know when your kit has shipped and is on its way to you. Wishing you a great week ahead.",
    questionnaire_completed: "Questionnaire Completed!",
    participation_close_subtext: `Thank you so much for participating in the ${constant.app_name}! On behalf of our team, we are very grateful that you spent some time participating in the study. We just wanted to let you know that your participation in the study has been closed out. If you have any questions for our team or would like to rejoin the study at some other time in the future, feel free to reach out via email at ${constant.app_email} or phone ${constant.app_phone}.`,
    participation_close_title: "Participation in the Study Closed Out!",
    link_expire_subtext: `Thank you so much for participating in the ${constant.app_name}! On behalf of our team, we are very grateful that you spent some time participating in the study. We wanted to inform you that the link to the interview you tried to access has expired. If you have any questions for our team feel free to reach out via email at ${constant.app_email} or phone ${constant.app_phone}.`,
    link_expire_title: "Interview Link Expired!",
    rollover_question: 'Rollover Question',
    rollover_question_info_text: "Is this question rollover from another interview?",
    rollover_question_variable_info_text: "Please enter the label/variable name for the question you wish to roll over.",
    rollover_question_interview_info_text: "Please select the interview of the question you wish to roll over.",
    workflow_rollover_question_settings: "Workflow Rollover Question Settings (Optional)",
    interview_completed_message: "Congratulations! Your interview is 100% completed. Thank you for your time and participation!",
    triggers_no_longer_pregnant: "Selecting 'No' will trigger email to participant that they are no longer pregnant.",
    no_files_to_show: "No Files to show!",
    drag_and_drop_files: "Drag 'n' drop some files here, or click to select files",
    file_upload_limit: "File upload limit: 500MB maximum per file.",
    files: "Files",
    loading: "Loading",
    are_you_sure_to_delete: "Are you sure you want to delete this entry?",
    something_went_wrong: "Something went wrong. Please try again later.",
    contact_information_updated_message: "Your contact information has been successfully updated!",
    answer_all_required_que: "Please answer all the (*) required questions!",
    your_consent_is_recorded: "Your consent has been successfully recorded!",
    max_files: "Max 5 files",
    each_file_less_than: "Each file must be less than 2Mb",
    interview_saved_successfully: "Interview Saved Successfully!",
    unable_to_save_interview: "Unable to save interview!",
    unable_to_save_contact_info: "Unable to Update Participant Contact Info! Try Again",
    i_agree: "I Agree",
    disagree: "Disagree",
    forgotten_password: "Forgotten password?",
    login: "Login",
    sign_in_to_your_account: "Sign In to your account",
    login_as: "Login as",
    login_welcome_text: `Welcome to the ${constant.app_name} Dashboard! Please login to your account with your credentials [username and password]. Thank you for your support in advancing child health!`,
    login_with_password: "Login with password",
    continue: "Continue",
    password_reset_link_sent: "Password reset link has been sent to your email. Please check your inbox to proceed.",
    link_valid_for_note: "Note that the link will be valid for one hour.",
    please_enter_email: "Please enter your email",
    my_profile: "My Profile",
    change_password: "Change password",
    logout: "Logout",
    password_changed_success: "Password successfully changed. Click the 'Login' button to log in with your new credentials.",
    password_reset_link_expire: "The password reset link has expired or contains an invalid token.",
    mark_as_bookmark_message: `To bookmark this page:\n - Press Ctrl/Cmd + D, or\n - Click on the star icon in the address bar, or\n - Open the browser menu and select 'Bookmark This Page'.`,
    lock_temporarily: "Lock Temporarily",
    interview_temporarily_locked: "Interview Temporarily Locked",
    interview_locked_subtext: `Thank you so much for participating in the ${constant.app_name}! On behalf of our team, we are very grateful that you spent some time participating in the study. We wanted to inform you that the interview you tried to access is currently locked. If you have any questions or need assistance, feel free to reach out to our team via email at ${constant.app_email} or phone ${constant.app_phone}.`,
    unsaved_changes_message: "You have unsaved changes. Are you sure you want to leave?",
    search: "Search",
    find: "Find",
    clear: "Clear",
    study_status: "Study Status",
    url_copied_success: "URL copied successfully!",
    send_immediately: "Send Immediately",
    send_after_lapse: "Send after lapse of time:",
    send_at_exact: "Send at exact date/time",
    send_before_six: "Send before 6 hours of the scheduled time",
    send_before_one: "Send before 1 hours of the scheduled time",
    just_once: "Just Once",
    multiple_times: "Multiple times: Send every",
    after_initially_sent: "after initially being sent",
    optional: "Optional",
    click_to_remove_email: "Click to remove email",
    or: "Or",
    take_picture: "Take Picture",
    camera: "Camera",
    selected_files: "Selected Files",
    retake: "Retake",
    capture_photo: "Capture Photo",
    switch_to: "Switch To",
    done: "Done",
    front: "Front",
    rear: "Rear",
    stop: "Stop",
    notification: "Notification",
    unsave_biospecimen_warning_message: "You have unsaved biospecimen changes. Are you sure you want to leave?",
    unsave_specimen_warning_message: "You have unsaved specimen type details changes. Are you sure you want to leave?",
    unsave_aliquoting_specimen_warning_message: "You have unsaved aliquoting specimen changes. Are you sure you want to leave?",
    biospecimen_saved: "Biospecimen saved successfully!",
    specimen_type_saved: "Specimen type details saved successfully!",
    aliquot_specimen_saved: "Aliquot Specimen type saved successfully!",
    are_you_sure_to_delete_this_entry: "Are you sure to delete this entry?",
    and: 'and',
    return_later: 'Return later',
    minimum: 'Minimum',
    maximum: "Maximum",
    minimum_value_info_text: "Required minimum value of the question",
    maximum_value_info_text: "Required maximum value of the question",
    error_messages: "Error Messages (Optional)",
    error: "Error",
    date_format: "Date/Datetime/Time",
    alpha: "Letters Only",
    numeric: "Number",
    digits: "MRN (10 digits/generic) / Phone",
    integer: "Integer",
    decimal: "Decimal Number",
    regex: "Zipcode",
    after_or_equal: "Minimum Date",
    before_or_equal: "Maximum Date",
    min: "Minimum Number",
    max: "Maximum Number",
    read_resource_guide: `- To read the English resource guide, <a href='${constant.english_resource_guide_link}'> click here </a>`,
    interview_complete_1: "You have completed your ",
    interview_complete_2: ` for the ${constant.app_name_full}. `,
    interview_complete_3: `To thank you for your participation, check out the NJKS resource guide, English <a href='${constant.english_resource_guide_link}'>click here</a>, Espanol <a href='${constant.spanish_resource_guide_link}'> click here </a> which is full of information to support healthy pregnancies and families in New Jersey.`,
    interview_complete_4: `You should receive your first Biospecimen Kit in the mail in 1-2 weeks. To learn more about what to expect, watch our ${constant.app_name} Biospecimen Box Opening Video <a href='${constant.biospecimen_box_opening_video_link}'>here</a>. If you have any questions or concerns, contact us at ${constant.email_app_email} or ${constant.email_app_phone}. You can access your Participant Dashboard at <a href='${constant.app_link}'>Login</a>. <br/> <br/> <strong> Thank you for supporting the ${constant.research_study_name}! </strong>`,
    questionnaire: 'Questionnaire',
    form: "Form",
    header: "Header",
    footer: "Footer",
    show_popup_message: "Show Popup Message",
    popup_message: "Popup Message",
    popup_condition: "Popup Condition",
    popup_message_info_text: "Show popup message when question is answered",
    operator: "Operator",
    please_enter_exact: "Please Enter Exact",
    ok: "Ok",
    url_copied: "Url copied successfully!",
    failed_url_copy: "Failed to copy URL!",
    remap_languages: "Remap Languages",
    remap_languages_successfully: "Remap languages successfully!",
    remap_languages_failed: "Failed to remap languages!",
    are_you_sure_to_remap: "Are you sure to remap languages of instruments?",
    study_introduction: "Study Introduction",
    first_name: "First Name",
    last_name: "Last Name",
    cell_phone: "Cell Phone",
    home_phone: "Home Phone",
    work_phone: "Work Phone",
    this_is_required_field: "This is a required field",
    if_you_are_not_eligible_for_study: "If you are not eligible for this study and believe this is an error, please don't hesitate to contact us.",
    to_continue_survey_check_email: "To continue your survey, log in using the credentials provided in the email.",
    login_credential_sent_to_your_below_contact_info: "Your login credentials have been sent to your registered email and phone number as shown below:",
    you_are_best_we_appreciate: "You’re the Best! We really appreciate you taking the time to complete this interview.",
    to_learn_more_about_study: "To learn more about the study,",
    click_here: "Click here.",
    kit_will_be_sent_to_address: "Your kit will be sent to the address you provided. If the address is not correct, please log in to your dashboard to update your contact information or reach out to the study team.",
    resources: "Resources",
    resources_text: `<h3 class='text-center'> Lorem Ipsum </h3> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. <br/> Lorem ipsum dolor sit amet <a target='__blank' href='https://google.com'>Click Here</a> <br/> Lorem ipsum dolor sit amet <a target='__blank' href='https://google.com'>Click Here</a>`,
    spanish_interview_name: "Spanish Interview Name",
    volume: "Volume",
    unsigned_giftcard_notifications_disabled: 'Disable Unsigned Gift Card Notifications',
    unsigned_giftcard_notificatipns_disabled_warning_message: "Please note that unsigned gift card notifications have been disabled, and participants will no longer receive gift card reminder notifications.",
    notification_history: "Notification History",
    sms: "SMS",
    sent_at: "Sent at",




  },
  gujarati: {
    dashboard: 'ડેશબોર્ડ',
    system: 'સિસ્ટમ',
    master: 'માસ્ટર',
    language: 'ભાષા',
    languages: 'ભાષાઓ',
    category: 'કેટેગરી',
    country: 'દેશ',
    countries: 'દેશો',
    state: 'રાજ્ય',
    states: 'રાજ્યો',
    city: 'શહેર',
    streettype: 'શેરીનો પ્રકાર',
    street: 'શેરી',
    direction: 'દિશા',
    residence: 'નિવાસ',
    residences: 'રહેઠાણો',
    recruitment: 'ભરતી',
    recruitments: 'ભરતીઓ',
    socialgroup: 'સામાજિક જૂથ',
    socialgroups: 'સામાજિક જૂથો',
    speakinglanguages: 'બોલાતી ભાષાઓ',
    purpose: 'હેતુ',
    purposes: 'હેતુઓ',
    typeofcontact: 'સંપર્કનો પ્રકાર',
    typeofcontacts: 'સંપર્કોનો પ્રકાર',
    syssetting: 'સિસ્ટમ સેટિંગ્સ',
    contactsetting: 'સંપર્ક સેટિંગ્સ',
    user: 'વપરાશકર્તા',
    users: 'વપરાશકર્તાઓ',
    role: 'ભૂમિકા',
    gensetting: 'જનરલ સેટિંગ્સ',
    myprofile: 'મારી પ્રોફાઈલ',
    changepassword: 'પાસવર્ડ બદલો',
    databank: 'ઇન્ટરવ્યૂ અને ડેટાબેંક',
    participant: 'સહભાગી',
    participants: 'સહભાગીઓ',
    instrument: 'ઇન્સ્ટ્રુમેન્ટ',
    interview: 'ઇન્ટરવ્યુ',
    name: 'નામ',
    action: 'એક્શન',
    edit: 'ફેરફાર કરો',
    delete: 'કાઢી નાંખવું',
    add: 'ઉમેરો',
    back: 'પાછળ',
    update: 'અપડેટ',
    srno: 'ક્રમ. નં',
    submit: 'સબમિટ કરો',
    reset: 'ફરીથી સેટ કરો',
    enter: 'દાખલ કરો',
    categories: 'કેટેગરીઝ',
    select: 'પસંદ કરો',
    email: 'ઇમેઇલ',
    first: 'પ્રથમ',
    last: 'છેલ્લું',
    username: 'વપરાશકર્તા નામ',
    other: 'અન્ય',
    regulation: 'નિયમન',
    id: 'આઈ.ડી.',
    dob: 'જન્મ તારીખ',
    gender: 'લિંગ',
    address: 'સરનામું',
    address2: 'સરનામું ૧',
    zip: 'ઝિપ',
    home: 'ઘર',
    work: 'કામ',
    phone: 'ફોન',
    cell: 'સેલ ફોન',
    phone_1: 'ફોન નં. ૧',
    phone_2: 'ફોન નં. ૨',
    phone_3: 'ફોન નં. ૩',
    phone_4: 'ફોન નં. ૪',
    comment: 'ટિપ્પણી',
    waveidentifier: 'Wave Identifier',
    puma: 'PUMA',
    psu: 'PSU',
    ssu: 'SSU',
    ifh_id: 'IFH ID',
    profile: 'પ્રોફાઇલ',
    picture: 'પિક્ચર',
    password: 'પાસવર્ડ',
    confirm: 'પુષ્ટિ કરો',
    old: 'જૂનું',
    new: 'નવું',
    age: 'ઉંમર',
    speaking: 'બોલાતી ભાષાઓ',
    reading: 'વાંચન',
    writing: 'લેખન',
    preference: 'પસંદગી',
    ifother: 'જો અન્ય',
    abbreviation: 'સંક્ષેપ',
    content: 'સામગ્રી',
    question: 'પ્રશ્ન',
    questions: 'પ્રશ્નો',
    details: 'વિગતો',
    up: 'ઉપર',
    down: 'નીચે',
    copy: 'નકલ કરો',
    option: 'વિકલ્પ',
    from: 'થી',
    options: 'વિકલ્પો',
    label: 'લેબલ',
    help: 'સહાય કરો',
    parent: 'માતા અથવા પિતા',
    istrigger: 'ટ્રિગર છે',
    abbrivation: 'સંક્ષેપ',
    for: 'માટે',
    assigned: 'સોંપ્યું',
    assign: 'સોંપો',
    staff: 'સ્ટાફ',
    status: 'સ્થિતિ',
    type: 'પ્રકાર',
    contact: 'સંપર્ક',
    contacts: 'સંપર્કો',
    previous: 'પહેલાનું',
    date: 'તારીખ',
    time: 'સમય',
    start: 'શરૂઆત',
    next: 'આગળ',
    save: 'સાચવો',
    list: 'યાદી',
    kinship: 'સગપણ',
    kinships: 'સગપણ',
    tag: 'ટેગ',
    tags: 'ટેગ્સ',
    schedule: 'અનુસૂચિ',
    scheduled: 'સુનિશ્ચિત',
    field: 'ક્ષેત્ર',
    mandatory: 'ફરજિયાત',
    isrequire: 'જરૂરી છે?',
    conduct: 'સંચાલન કરવું',
    pause: 'થોભો',
    assignment: 'સોંપણી',
    relationship: 'સંબંધ',
    with: 'સાથે',
    method: 'પદ્ધતિ',
    bloodsample: 'બ્લડ સેમ્પલ',
    tubecount: 'ટ્યુબ કાઉન્ટ',
    bsd: 'bsd',
    watch: 'ઘડિયાળ',
    pddate: 'તારીખ',
    Code: 'કોડ',
    coding: 'કોડિંગ',
    compltreport: 'સમાપ્તિ અહેવાલ',
    percentage: 'ટકાવારી',
    answer: 'જવાબ',
    thankyou: 'આભાર',
    display: 'દર્શાવો',
    sendemail: 'ઇમેઇલ મોકલો',
    emailsent: 'ઇમેઇલ મોકલાય ગયો',
    preview: 'પૂર્વાવલોકન',
    consent: 'સંમતિ',
    studyid: 'અભ્યાસની સ્થિતિ',
    familyid: 'ફેમિલીઆઈડી',
    hascomment: 'ટિપ્પણી છે',
    participantid: 'સહભાગી આઈડી',
    section: 'વિભાગ',
    child: 'બાળક',
    parent: 'માતા અથવા પિતા',
    speaking_language: 'બોલાતી ભાષા',
    study_type: 'અભ્યાસ પ્રકાર',
    test_name: 'પરીક્ષણ નામ',
    test_completed: 'પૂર્ણ',
    view_details: 'વિગતો જુઓ',
    apt: 'એપાર્ટમેન્ટ',
    total: 'કુલ',
    assignments: 'સોંપણીઓ',
    mockparticipant: 'મોક સહભાગી',
    reassign: 'ફરીથી સોંપવું',
    completion: 'પૂર્ણ',
    clone: 'ક્લોન',
    sync: 'સમન્વયન',
    contacted: 'સંપર્ક કર્યો',
    not_contacted: 'હજી સંપર્ક નથી કરાયો',
    history: 'ઇતિહાસ',
    device: 'સાધન',
    sms_template: 'SMS Template',
    description: 'Description',
    checklist: 'Checklist',
    history: 'History',
    item: 'Item',
    price: 'Price',
    quantity: 'Quantity',
    purchase: 'Purchase',
    ref_no: 'Ref No.',
    purchase_by: 'Purchased By',
    dispatch: 'Dispatch',
    receive: 'Receive',
    phlebotomy: 'Phlebotomy and Saliva Collection',
    equipment: 'Equipment',
    tube: 'Tube',
    test: 'Test',
    barcode: 'Barcode',
    qrcode: 'QR code',
    capacity: 'Capacity',
    unit: 'Unit',
    inventory_no: 'Inventory No',
    draw_from: 'Blood Drawn From',
    filled_to_capacity: 'Filled To Capacity',
    second_attempt: 'Second Attempt',
    end: 'End',
    result: 'Result',
    result_recorded_date: 'Result Recorded Date',
    result_recorded_time: 'Result Recorded Time',
    anomalies: 'Anomalies',
    normal_range: 'Normal Range',
    notes: 'Notes',
    need_review: 'Need Review',
    ldd: 'LDD',
    lloq: 'LLOQ',
    selected: 'Selected',
    current: 'Current',
    start_time: 'Start Time',
    end_time: 'End Time',
    assign_date: 'Assign Date',
    unassign_date: 'Unassign Date',
    number: "Number",
    canvassing: 'Canvassing',
    association: 'Association',
    rds: 'RDS',
    coupon: 'Coupon',
    recruitment: 'Recruitment',
    mark: 'Mark',
    as: 'as',
    complete: 'Complete',
    remove: 'Remove',
    completion: 'Completion',
    of: 'of',
    reason: 'Reason',
    isCompleted: 'Is Completed',
    transfer: 'Transfer',
    householdid: 'Household ID',
    familyid: 'Family ID',
    date_consent_taken: 'Date Consent Taken',
    date_scanned: 'Date Scanned',
    file: 'File',
    upload: 'Upload',
    paper: 'Paper',
    remind: 'Remind',
    me: 'me',
    notifications: 'Notifications',
    reminders: 'Reminders',
    alerts: 'Alerts',
    title: 'Title',
    alertTopic: 'Alert topic',
    sendHowManyTimes: 'Send it how many times?',
    whenToSendAlert: 'When to send alert?',
    message: 'Message',
    settings: 'Settings',
    to: 'To',
    subject: 'Subject',
    alertExpiration: 'Alert Expiration',
    lot_number: 'Lot Number',
    tube_status: 'Tube Status',
    ups_tracking_no: 'UPS Tracking Number',
    date: 'Date',
    tube_fill_status: 'Tube Fill Status',
    phlebotomy_saliva: 'Phlebotomy/Saliva',
    tube_container: 'Tube/Container',
    data_entry_blood_saliva: 'DATA ENTRY FOR BLOOD DRAW AND SALIVA',
    data_entry_for: 'Data Entry For',
    eligibility: 'Eligibility',
    randomize_questions: 'Randomize Questions',
    randomize_options: 'Randomize Options',
    ethnicity: 'Ethnicity',
    sendConsentEmail: 'Send Consent Email',
    consentEmailSent: 'Consent Email Sent',
    data_collection_type: 'Data Collection Type',
    view: 'View',
    generate: 'Generate',
    triageReport: 'Triage Report',
    open: 'Open',
    time_point: 'Time Point',
    staff_id: 'Staff ID',
    participant_id: 'Participant ID',
    reviewed_by_partner: 'Reviewed By Partner',
    interview1_date_time: 'Interview 1: Date and time',
    interview1_completion_status: 'Interview 1: Completion Status',
    interview2_date_time: 'Interview 2: Date and time',
    interview2_completion_status: 'Interview 2: Completion Status',
    interview3_date_time: 'Interview 3: Date and time',
    interview3_completion_status: 'Interview 3: Completion Status',
    interview4_date_time: 'Interview 4: Date and time',
    interview4_completion_status: 'Interview 4: Completion Status',
    interview5_date_time: 'Interview 5: Date and time',
    interview5_completion_status: 'Interview 5: Completion Status',
    interview6_date_time: 'Interview 6: Date and time',
    interview6_completion_status: 'Interview 6: Completion Status',
    gift_card: 'Gift Card',
    transcribed: 'Transcribed',
    referral: 'Referral',
    race: 'Race',
    reason: 'Reason',
    interview1_staff: 'Interview 1: Staff',
    interview2_staff: 'Interview 2: Staff',
    interview3_staff: 'Interview 3: Staff',
    interview4_staff: 'Interview 4: Staff',
    interview5_staff: 'Interview 5: Staff',
    interview6_staff: 'Interview 6: Staff',
    shelf_number: 'Shelf Number',
    tube_rack_number: 'Tube Rack Number',
    sample_position_in_tube_rack: 'Sample Position in Tube Rack',
    link_or_card: 'Link or Card',
    card: 'Card',
    ra_staff: 'RA/ Staff',
    date_sent: 'Date Sent',
    date_receipt_received: 'Date Receipt Received',
    date_of_activation: 'Date Of Activation If Sent By Mail',
    gift_card_voucher: 'Gift Card & Voucher',
    tracking: 'Tracking',
    unassigned_report: 'Unassigned Report',
    insurance: 'Insurance',
    participant_info_report: 'Participant Info Report',
    validate: 'Validate',
    verify: 'Verify',
    answer_id: 'Answer ID',
    instrument_id: 'Instrument ID',
    language_id: 'Language ID',
    question_id: 'Question ID',
    deleted_quetions_answers: 'Deleted Questions Answers',
    triggered_quetions_answers: 'Triggered Questions Answers',
    no_parent_quetions_answers: 'No Parent Triggered Questions Answers',
    deleted_options_answers: 'Deleted Options Answers',
    incomp_report: 'Incompletion Report',
    partial_comp_report: 'Partially Completed Report',
    last: 'Last',
    contact: 'Contact',
    details: 'Details',
    of: 'Of',
    reluctance: 'Reluctance',
    severity: 'Severity',
    contacted: 'Contacted',
    who: 'Who',
    not_contacted_yet: 'Not contacted yet',
    not_interviewed_yet: 'Not interviewed yet',
    total_assigned: 'Total Assigned',
    not_contacted_and_interviewed_report: 'Not Contacted & Interviewed Report',
    send: 'Send',
    cancel: 'Cancel',
    amount: 'Amount',
    event: 'Event',
    survey: 'Survey',
    coordinator_only: 'Coordinator Only',
    date_of_signature: 'Date of Signature',
    date_of_filing: 'Date of Filing',
    date_of_interview: 'Date of Interview',
    name_of_subject: 'Name of Subject',
    signature_of_subject: 'Signature of Subject',
    interviewer_initials: 'Interviewer Initials',
    dear: 'Dear',
    gift_card_para_1: 'Thank you for your recent participation in our research! Community members like you play an important role in our work, and we thank you again for adding your voice to our community. We could not do this without you!',
    gift_card_para_2_sentence_1: 'As discussed, enclosed is your gift card in the amount of ',
    gift_card_para_2_sentence_2: ' This card can be used at any store or online merchant nationwide. To use the card, follow the instructions emailed to you and sign the receipt below to acknowledge receiving the card. Should you have any questions or difficulties in completing the form, please contact us at: ',
    gift_card_para_2_sentence_3: ' or call us at ',
    gift_card_para_3_sentence_1: "And don't forget to join our community. From seminars, to outreach, to our ongoing research, there is always something happening. If you are interested in learning more about us and future events, just visit our website, ",
    gift_card_para_3_sentence_2: " We couldn't do what we do without your support!",
    gift_card_para_4_sentence_1: "Thank you for taking part in this study. By signing below, you acknowledge receiving ",
    gift_card_para_4_sentence_2: " for participation in this study.",
    sincerely: "Sincerely",
    gift_card: 'Gift Card',
    organization_line: 'Organization Line',
    address_line: 'Address Line',
    website: 'Website',
    handle: 'Handle',
    fax: 'Fax',
    logo: 'Logo',
    team: "Team",
    review: 'Review',
    would_you_like_to_review: "Would you like to review the consent before submitting the interview?",
    consent_warning_note: "Note: After interview completion, the consent will be locked, and you will not be able to modify the answers. Aww yeah, you successfully read this important alert message. This example text is going to run a bit longer so that you can see how spacing within an alert works with this kind of content.",
    updated_at: 'Updated At',
    recent: 'Recent',
    social_media: 'Social Media',
    url: 'URL',
    image: 'Image',
    interview_expiration_date: 'Interview Expiration Date',
    redirect_url: 'Redirect Url',
    saliva: 'Saliva',
    ID: 'ID',
    collected: 'Collected',
    in_person_or_mail: 'In person or Mail',
    arrival_date: 'Arrival Date',
    mandatory_survey: 'Mandatory Survey',
    supplemental_survey: 'Supplemental Survey',
    actigraphy: 'Actigraphy',
    blood_draw: 'Blood Draw',
    signature: 'Signature',
    mail: 'Mail',
    errors: 'Errors',
    oa_ma_report: 'OA & MA Report',
    percent_completion_of: 'Percent Completion Of',
    wave: 'Wave',
    criteria: 'Criteria',
    report: 'Report',
    physical: 'Physical',
    cognitive_assessment: 'Cognitive Assessment',
    assessment: 'Assessment',
    lab: 'Lab',
    sent: 'Sent',
    signature_of_investigator: 'Signature of Investigator/Individual Obtaining Consent/Assent',
    to_the_best_ability: 'To the best of my ability, I have explained and discussed the full contents of the study including all of the information contained in this consent form. All questions of the research subject and those of his/her parent or legal guardian have been accurately answered.',
    investigator_person: 'Investigator/Person Obtaining Consent Name (Print)',
    outbound_tracking_number: 'Outbound Tracking Number',
    outbound_ship_date: 'Outbound Ship Date',
    inbound_tracking_number: 'Inbound Tracking Number',
    inbound_ship_date: 'Inbound Ship Date',
    storage_date: 'Storage Date',
    shelf: 'Shelf',
    additional_details: 'Additional Details',
    sent_via_email: "if sent via email",
    signed: 'Signed',
    responded: 'Responded',
    family: 'Family',
    cohort: 'Cohort',
    close: 'Close',
    tree: 'Tree',
    validation: 'Validation',
    variable: 'Variable',
    duplicate: 'Duplicate',
    hospital: 'Hospital',
    field_is_embedded: 'Field is embedded elsewhere',
    enumeration: "Enumeration",
    eligible: 'Eligible',
    not_available: 'Not Available',
    follow_up: 'Follow Up',
    reminder: 'Reminder',
    scheduling: 'Scheduling',
    location: 'Location',
    component: 'Component',
    trigger: 'Trigger',
    show: "Show",
    is_enumeration_informant: 'Is Enumeration Informant?',
    probability_or_immigrant_sample: 'Probability or Immigrant sample?',
    full: 'Full',
    total_household_members: 'Total Household Members',
    total_family_members: 'Total Family Members',
    download: 'Download',
    progress: "Progress",
    additional_display_information: 'Additionl Display Information',
    self_directed_interview: "Self Directed Interview Completion Time Display Screen",
    required: 'Required',
    this_questionnaire: "This Questionnaire will take approximately",
    to_complete: "to complete.",
    complete_questionnaire: "Complete Questionnaire",
    now: 'Now',
    later: 'Later',
    web_or_phone: 'Web Or Phone',
    in_person_dispatch: 'In Person Dispatch',
    ssrs: 'SSRS',
    biospecimen: 'Biospecimen',
    kit: 'Kit',
    shipment_carrier: 'Shipment Carrier',
    outbound: 'Outbound',
    inbound: 'Inbound',
    condition_on_receipt: 'Condition on Receipt',
    laboratory_receipt_date: 'Laboratory Receipt Date',
    laboratory_receipt_condition: 'Laboratory Receipt Condition',
    specimen_type: 'Specimen Type',
    lab_biospecimen_processing: 'Lab Biospecimen Processing',
    storage_information: 'Storage Information',
    storage_location: 'Storage Location',
    storage_temperature: 'Storage Temperature',
    storage_start_date: 'Storage Start Date',
    review_date: 'Review Date',
    spinning_date: 'Spinning Date',
    aliquoting_date: 'Aliquoting Date',
    sample_position_in_rack: 'Sample Position in Rack',
    rack_number: 'Rack Number',
    google_analytics: 'Google Analytics',
    measurement: 'Measurement',
    update_and_resend: 'Update and Resend Gift Card',
    update_without_resend: 'Update Without Resending Gift Card',
    multimedia: 'Multimedia',
    format: 'Format',
    optional_file_attachment: 'File attachment image, audio or video (Optional):',
    provide_video_url: 'Provide video URL',
    workflows: 'Workflows',
    workflow: 'Workflow',
    add_interview_workflow: 'Add Interviews in the workflow',
    active_after_completion: 'Active after completion of previous questionnaire.',
    flow: 'Flow',
    econsent: 'E-consent',
    duration: 'Duration',
    unit: 'Unit',
    active_after_consent: 'Active after completion of e-consent',
    active_in: 'Active in',
    after_completion: 'after completion of previous questionnaire',
    active_on_after: 'Active on/after',
    active_condition: 'Active condition',
    active_on_date: 'Active On Date',
    biospecimen_kit_status: 'Biospecimen Kit Status',
    and_kit_status: "and when previous interview's biospecimen kit status is",
    active_instantly: 'Active <strong> instantly </strong>',
    screening_tool: 'Screening Tool',
    information: 'Information',
    study: 'Study',
    video_acknowledgment: 'Have you completed watching the entire video?',
    yes: 'Yes',
    no: 'No',
    step: 'Step',
    study_overview: 'Study Overview Video',
    continue: 'Continue',
    not_activated_yet: 'Interview Not Activated Yet!',
    not_finished: 'Not Finished!',
    stage: 'Stage',
    disagreed: 'Disagreed',
    eligible_for_study: 'Eligible for study!',
    ineligible_for_study: 'Ineligible for study!',
    agreed: 'Agreed',
    optional_screening_tool_eligibility_condition: 'Screening Tool Eligibility Condition (Optional)',
    condition: 'Condition',
    value: 'Value',
    seperate_multiple_values: 'Separate multiple values with commas. The condition will be true if any one matches.',
    overview: 'Overview',
    video: 'Video',
    print: 'Print',
    individual: 'Individual',
    please_enter_email: 'Please enter your email address',
    change: 'Change',
    self_collect_blood_permission: 'Are you interested in a self-collected blood draw using the TAP Micro device.',
    i_am_in: 'I am in',
    i_am_out: 'I am out',
    member: 'Member',
    and_when_econsent_agreed: 'and when e-consent is agreed.',
    text: 'Text',
    hint: 'Hint',
    placeholder: 'Placeholder',
    default: 'Default',
    introduction: 'Introduction',
    identifier_info_text: 'Does the field contain identifying information (e.g. name, SSN, address)',
    required_info_text: '* Prompt if field is blank',
    variable_name_info_text: 'Only letters, numbers & underscores',
    disable_info_text: "Choose 'Yes' to hide, 'No' to keep this question active.",
    help_info_text: "Additional information below the question",
    placeholder_info_text: "Enter text to display inside the field.",
    default_value_info_text: "Automatically fill this field with a preset value.",
    auto: 'Auto',
    check_errors_other_lang_instruments: "Check for errors in interview's other language instruments!",
    check_errors_other_lang_instruments_info: "Ensure accuracy in interview instruments by reviewing for potential issues such as mismatched question counts in other language instruments, discrepancies in question types, options count, and validations like whether a question is required, has comments, or serves as an identifier.",
    participants_having_deleted_questions_answers: "Participants having deleted question's answer in the interview!",
    participants_having_deleted_questions_answers_info: "Verify if participants have submitted answers for questions that were deleted from the instruments in the interview.",
    check_errors_other_lang_consent: "Check for errors in other language consent!",
    check_errors_other_lang_consent_info: "Ensure accuracy in consent by reviewing for potential issues such as mismatched section or question counts in other language consent, discrepancies in question types, options count, and validations like whether a question is required.",
    multilingual: 'Multilingual',
    stay: 'Stay',
    leave: 'Leave',
    workflow_not_eligible_text: `Thank you for your interest in the ${constant.app_name}! Unfortunately, at this time, your responses indicate that you would not be eligible to participate in the study. Please feel free to help spread the word about this study to friends or family members that you think may be eligible to join the study.`,
    congratulations: 'Congratulations',
    congratulations_screen_text: `Based on the answers you provided in the screening survey, <h5 class='d-inline'> you are eligible to participate in the study. </h5> Be sure to watch the ${constant.app_name} overview video to learn more about the study and what’s involved in participating to see if the study is the right fit for you.`,
    best_wishes: 'Best Wishes',
    the_ks_team: `${constant.email_app_sign_full}`,
    show_input_field: 'Show Input Field',
    aliquot: 'Aliquot',
    specimen: 'Specimen',
    freezer: 'Freezer',
    tube_freezer_storage_box_name: 'Tube Freezer Storage Box Name',
    tube_freezer_storage_box_position_name: 'Tube Freezer Storage Box Position Name',
    source: 'Source',
    pregnant: 'Pregnant',
    rows: 'Rows',
    columns: 'Columns',
    create: 'Create',
    storage: 'Storage',
    box: 'Box',
    leave_empty_to_auto_generate: 'Leave blank to automatically generate value',
    please_select_freezer_stoarage_box: 'Please select freezer storage box',
    click_icon_create_storage_box: 'Click the icon to create a new freezer storage box',
    click_icon_select_storage_position: 'Click the icon to select a freezer storage box position',
    participation_in_study_closed_out: "Your participation in the study has been closed out.",
    please_enter_email_or_phone: "Please enter your email address or phone number",
    preferred_method_of_communication: 'Preferred Method Of Communication',
    opt_in: 'Opt In',
    opt_out: 'Opt Out',
    cheatblocker: "Cheatblocker",
    compare_dates_by: 'Compare Dates By',
    time_period: 'Time Period',
    criteria: 'Criteria',
    field: 'Field',
    in: 'In',
    interview_answer_condition: 'Interview Answers Condition',
    after_previous_answer_provided: "questionnaire's <strong> required answer provided </strong>",
    even_after_previous_answer_missing: "even after previous questionnaire's <strong> required answer missing </strong>",
    depends_on_previous_interview: 'Depends On Previous Interview',
    answer_provided_for_interview: 'Answer Provided For Interview',
    required_interview: 'Required Interview',
    after: 'after',
    its_kit_status: "and when it's biospecimen kit status is",
    after_completion_of_econsent: 'after completion of econsent',
    to_activate_this_interview: 'to activate this interview',
    filled_returned: 'Filled & Returned',
    urine: 'Urine',
    browse_files: 'Browse Files',
    upload_files: 'Upload Files',
    media: 'Media',
    path: 'Path',
    resolution: 'Resolution',
    size: 'Size',
    uploading: 'Uploading',
    contact_form_message: `Welcome to the ${constant.app_name}! Please take a moment to share your contact information with us so that we can stay in touch with updates about the study.`,
    after_interviews_kit_status: "interviews's <strong> biospecimen kit status </strong> is",
    active_on_condition: 'Active on condition',
    active_without_answer_kit_submission: 'Active without interview answer and biospecimen kit submission',
    active_after_4_weeks_final_reminder: "Or active after 4 weeks from final reminder of required interview's survey / kit",
    active_after_postnatal: "after postnatal period",
    zipcode: 'Zipcode',
    are_this_true: "Are either of these currently true?",
    no_longer_pregnant: "You are no longer pregnant",
    moved_out_of_state: "You have moved out of state",
    not_meet_criteria_for_study: "Thank you for your interest. Unfortunately, you do not meet the eligibility criteria for this study.",
    workflow_not_eligible_text_2: `Thank you for your interest in the ${constant.app_name}! Since our study has very specific requirements to participate, not everyone is eligible to join the study. Unfortunately, at this time, your answers indicate that the <h5 class='d-inline'> eligibility requirements for the study were not met. </h5> We wanted to send a special guide with helpful community resources for pregnant individuals and parents. You can download the guide here. A copy will also be sent to your email. We would love to stay in touch to let you know about the things we learn from this study. Be sure to visit us at ${constant.email_app_public_website}  for newsletters and updates.`,
    study_video_title: "Learn more about the study and what's involved by watching this short video.",
    email_app_sign_full: `The ${constant.email_app_sign_full}`,
    warmest_regards: 'Warmest Regards',
    questionnaire_completed_subtext: "Thank you for completing your questionnaire! Our team is getting your next sample kit ready for shipment. We'll let you know when your kit has shipped and is on its way to you. Wishing you a great week ahead.",
    questionnaire_completed: "Questionnaire Completed!",
    participation_close_subtext: `Thank you so much for participating in the ${constant.app_name}! On behalf of our team, we are very grateful that you spent some time participating in the study. We just wanted to let you know that your participation in the study has been closed out. If you have any questions for our team or would like to rejoin the study at some other time in the future, feel free to reach out via email at ${constant.app_email} or phone ${constant.app_phone}.`,
    participation_close_title: "Participation in the Study Closed Out!",
    link_expire_subtext: `Thank you so much for participating in the ${constant.app_name}! On behalf of our team, we are very grateful that you spent some time participating in the study. We wanted to inform you that the link to the interview you tried to access has expired. If you have any questions for our team feel free to reach out via email at ${constant.app_email} or phone ${constant.app_phone}.`,
    link_expire_title: "Interview Link Expired!",
    rollover_question: 'Rollover Question',
    rollover_question_info_text: "Is this question rollover from another interview?",
    rollover_question_variable_info_text: "Please enter the label/variable name for the question you wish to roll over.",
    rollover_question_interview_info_text: "Please select the interview of the question you wish to roll over.",
    workflow_rollover_question_settings: "Workflow Rollover Question Settings (Optional)",
    interview_completed_message: "Congratulations! Your interview is 100% completed. Thank you for your time and participation!",
    triggers_no_longer_pregnant: "Selecting 'No' will trigger email to participant that they are no longer pregnant.",
    no_files_to_show: "No Files to show!",
    drag_and_drop_files: "Drag 'n' drop some files here, or click to select files",
    file_upload_limit: "File upload limit: 500MB maximum per file.",
    files: "Files",
    loading: "Loading",
    are_you_sure_to_delete: "Are you sure you want to delete this entry?",
    something_went_wrong: "Something went wrong. Please try again later.",
    contact_information_updated_message: "Your contact information has been successfully updated!",
    answer_all_required_que: "Please answer all the (*) required questions!",
    your_consent_is_recorded: "Your consent has been successfully recorded!",
    max_files: "Max 5 files",
    each_file_less_than: "Each file must be less than 2Mb",
    interview_saved_successfully: "Interview Saved Successfully!",
    unable_to_save_interview: "Unable to save interview!",
    unable_to_save_contact_info: "Unable to Update Participant Contact Info! Try Again",
    i_agree: "I Agree",
    disagree: "Disagree",
    forgotten_password: "Forgotten password?",
    login: "Login",
    sign_in_to_your_account: "Sign In to your account",
    login_as: "Login as",
    login_welcome_text: `Welcome to the ${constant.app_name} Dashboard! Please login to your account with your credentials [username and password]. Thank you for your support in advancing child health!`,
    login_with_password: "Login with password",
    continue: "Continue",
    password_reset_link_sent: "Password reset link has been sent to your email. Please check your inbox to proceed.",
    link_valid_for_note: "Note that the link will be valid for one hour.",
    please_enter_email: "Please enter your email",
    my_profile: "My Profile",
    change_password: "Change password",
    logout: "Logout",
    password_changed_success: "Password successfully changed. Click the 'Login' button to log in with your new credentials.",
    password_reset_link_expire: "The password reset link has expired or contains an invalid token.",
    mark_as_bookmark_message: `To bookmark this page:\n - Press Ctrl/Cmd + D, or\n - Click on the star icon in the address bar, or\n - Open the browser menu and select 'Bookmark This Page'.`,
    lock_temporarily: "Lock Temporarily",
    interview_temporarily_locked: "Interview Temporarily Locked",
    interview_locked_subtext: `Thank you so much for participating in the ${constant.app_name}! On behalf of our team, we are very grateful that you spent some time participating in the study. We wanted to inform you that the interview you tried to access is currently locked. If you have any questions or need assistance, feel free to reach out to our team via email at ${constant.app_email} or phone ${constant.app_phone}.`,
    unsaved_changes_message: "You have unsaved changes. Are you sure you want to leave?",
    search: "Search",
    find: "Find",
    clear: "Clear",
    study_status: "Study Status",
    url_copied_success: "URL copied successfully!",
    send_immediately: "Send Immediately",
    send_after_lapse: "Send after lapse of time:",
    send_at_exact: "Send at exact date/time",
    send_before_six: "Send before 6 hours of the scheduled time",
    send_before_one: "Send before 1 hours of the scheduled time",
    just_once: "Just Once",
    multiple_times: "Multiple times: Send every",
    after_initially_sent: "after initially being sent",
    optional: "Optional",
    click_to_remove_email: "Click to remove email",
    or: "Or",
    take_picture: "Take Picture",
    camera: "Camera",
    selected_files: "Selected Files",
    retake: "Retake",
    capture_photo: "Capture Photo",
    switch_to: "Switch To",
    done: "Done",
    front: "Front",
    rear: "Rear",
    stop: "Stop",
    notification: "Notification",
    unsave_biospecimen_warning_message: "You have unsaved biospecimen changes. Are you sure you want to leave?",
    unsave_specimen_warning_message: "You have unsaved specimen type details changes. Are you sure you want to leave?",
    unsave_aliquoting_specimen_warning_message: "You have unsaved aliquoting specimen changes. Are you sure you want to leave?",
    biospecimen_saved: "Biospecimen saved successfully!",
    specimen_type_saved: "Specimen type details saved successfully!",
    aliquot_specimen_saved: "Aliquot Specimen type saved successfully!",
    are_you_sure_to_delete_this_entry: "Are you sure to delete this entry?",
    and: 'and',
    return_later: 'Return later',
    minimum: 'Minimum',
    maximum: "Maximum",
    minimum_value_info_text: "Required minimum value of the question",
    maximum_value_info_text: "Required maximum value of the question",
    error_messages: "Error Messages (Optional)",
    error: "Error",
    date_format: "Date/Datetime/Time",
    alpha: "Letters Only",
    numeric: "Number",
    digits: "MRN (10 digits/generic) / Phone",
    integer: "Integer",
    decimal: "Decimal Number",
    regex: "Zipcode",
    after_or_equal: "Minimum Date",
    before_or_equal: "Maximum Date",
    min: "Minimum Number",
    max: "Maximum Number",
    read_resource_guide: `- To read the English resource guide, <a href='${constant.english_resource_guide_link}'> click here </a>`,
    interview_complete_1: "You have completed your ",
    interview_complete_2: ` for the ${constant.app_name_full}. `,
    interview_complete_3: `To thank you for your participation, check out the NJKS resource guide, English <a href='${constant.english_resource_guide_link}'>click here</a>, Espanol <a href='${constant.spanish_resource_guide_link}'> click here </a> which is full of information to support healthy pregnancies and families in New Jersey.`,
    interview_complete_4: `You should receive your first Biospecimen Kit in the mail in 1-2 weeks. To learn more about what to expect, watch our ${constant.app_name} Biospecimen Box Opening Video <a href='${constant.biospecimen_box_opening_video_link}'>here</a>. If you have any questions or concerns, contact us at ${constant.email_app_email} or ${constant.email_app_phone}. You can access your Participant Dashboard at <a href='${constant.app_link}'>Login</a>. <br/> <br/> <strong> Thank you for supporting the ${constant.research_study_name}! </strong>`,
    questionnaire: 'Questionnaire',
    form: "Form",
    header: "Header",
    footer: "Footer",
    show_popup_message: "Show Popup Message",
    popup_message: "Popup Message",
    popup_condition: "Popup Condition",
    popup_message_info_text: "Show popup message when question is answered",
    operator: "Operator",
    please_enter_exact: "Please Enter Exact",
    ok: "Ok",
    url_copied: "Url copied successfully!",
    failed_url_copy: "Failed to copy URL!",
    remap_languages: "Remap Languages",
    remap_languages_successfully: "Remap languages successfully!",
    remap_languages_failed: "Failed to remap languages!",
    are_you_sure_to_remap: "Are you sure to remap languages of instruments?",
    study_introduction: "Study Introduction",
    first_name: "First Name",
    last_name: "Last Name",
    cell_phone: "Cell Phone",
    home_phone: "Home Phone",
    work_phone: "Work Phone",
    this_is_required_field: "This is a required field",
    if_you_are_not_eligible_for_study: "If you are not eligible for this study and believe this is an error, please don't hesitate to contact us.",
    to_continue_survey_check_email: "To continue your survey, log in using the credentials provided in the email.",
    login_credential_sent_to_your_below_contact_info: "Your login credentials have been sent to your registered email and phone number as shown below:",
    you_are_best_we_appreciate: "You’re the Best! We really appreciate you taking the time to complete this interview.",
    to_learn_more_about_study: "To learn more about the study,",
    click_here: "Click here.",
    kit_will_be_sent_to_address: "Your kit will be sent to the address you provided. If the address is not correct, please log in to your dashboard to update your contact information or reach out to the study team.",
    resources: "Resources",
    resources_text: `<h3 class='text-center'> Lorem Ipsum </h3> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. <br/> Lorem ipsum dolor sit amet <a target='__blank' href='https://google.com'>Click Here</a> <br/> Lorem ipsum dolor sit amet <a target='__blank' href='https://google.com'>Click Here</a>`,
    spanish_interview_name: "Spanish Interview Name",
    volume: "Volume",
    unsigned_giftcard_notifications_disabled: 'Disable Unsigned Gift Card Notifications',
    unsigned_giftcard_notificatipns_disabled_warning_message: "Please note that unsigned gift card notifications have been disabled, and participants will no longer receive gift card reminder notifications.",
    notification_history: "Notification History",
    sms: "SMS",
    sent_at: "Sent at",



  },

  pidgin: {
    dashboard: 'Dashboard Pidgin',
    system: 'SYSTEM',
    master: 'Master',
    language: 'Language',
    languages: 'Languages',
    category: 'Category',
    country: 'Country',
    countries: 'Countries',
    state: 'State',
    states: 'States',
    city: 'City',
    streettype: 'Street Type',
    street: 'Street',
    direction: 'Direction',
    residence: 'Residence',
    residences: 'Residences',
    recruitment: 'Recruitment',
    recruitments: 'Recruitments',
    socialgroup: 'Social Group',
    socialgroups: 'Social Groups',
    speakinglanguages: 'Speaking Languages',
    purpose: 'Purpose',
    purposes: 'Purposes',
    typeofcontact: 'Type Of Contact',
    typeofcontacts: 'Type Of Contacts',
    syssetting: 'System Settings',
    contactsetting: 'Contact Settings',
    user: 'User',
    users: 'Users',
    role: 'Role',
    gensetting: 'General Settings',
    myprofile: 'My Profile',
    changepassword: 'Change Password',
    databank: 'INTERVIEW AND DATABANK',
    participant: 'Participant',
    participants: 'Participants',
    instrument: 'Instrument',
    interview: 'Interview',
    name: 'Name',
    action: 'Action',
    edit: 'Edit',
    delete: 'Delete',
    add: 'Add',
    back: 'Back',
    update: 'Update',
    srno: 'Sr. No',
    submit: 'Submit',
    reset: 'Reset',
    enter: 'Enter',
    categories: 'Categories',
    select: 'Select',
    email: 'Email',
    first: 'First',
    last: 'Last',
    username: 'Username',
    other: 'Other',
    regulation: 'Regulation',
    id: 'id',
    dob: 'Date of birth',
    gender: 'Gender',
    address: 'Address',
    address2: 'Address 2',
    zip: 'Zip',
    home: 'Home',
    work: 'Work',
    phone: 'Phone',
    cell: 'Cell',
    phone_1: 'Phone No. 1',
    phone_2: 'Phone No. 2',
    phone_3: 'Phone No. 3',
    phone_4: 'Phone No. 4',
    comment: 'Comment',
    waveidentifier: 'Wave Identifier',
    puma: 'PUMA',
    psu: 'PSU',
    ssu: 'SSU',
    ifh_id: 'IFH ID',
    profile: 'Profile',
    picture: 'Picture',
    password: 'Password',
    confirm: 'Confirm',
    old: 'Old',
    new: 'New',
    age: 'Age',
    speaking: 'Speaking',
    reading: 'Reading',
    writing: 'Writing',
    preference: 'Preference',
    ifother: 'If Other',
    abbreviation: 'Abbreviation',
    content: 'Content',
    question: 'Question',
    questions: 'Questions',
    details: 'Details',
    up: 'up',
    down: 'down',
    copy: 'Copy',
    option: 'Option',
    from: 'From',
    options: 'Options',
    label: 'Label',
    help: 'help',
    parent: 'Parent',
    istrigger: 'Is Trigger',
    abbrivation: 'Abbrivation',
    for: 'for',
    assigned: 'Assigned',
    assign: 'Assign',
    staff: 'Staff',
    status: 'Status',
    type: 'Type',
    contact: 'Contact',
    contacts: 'Contacts',
    previous: 'Previous',
    date: 'Date',
    time: 'Time',
    start: 'Start',
    next: 'Next',
    save: 'Save',
    list: 'List',
    kinship: 'Kinship',
    kinships: 'kinships',
    tag: 'Tag',
    tags: 'Tags',
    schedule: 'Schedule',
    scheduled: 'Scheduled',
    field: 'Field',
    mandatory: 'Mandatory',
    isrequire: 'Is Required?',
    conduct: 'Conduct',
    pause: 'Pause',
    assignment: 'Assignment',
    relationship: 'Relationship',
    with: 'With',
    method: 'Method',
    bloodsample: 'Blood Sample',
    tubecount: 'Tube Count',
    bsd: 'Date',
    watch: 'Watch',
    pddate: 'Date',
    code: 'Code',
    coding: 'Coding',
    compltreport: 'Completion Report',
    percentage: 'Percentage',
    answer: 'Answer',
    thankyou: 'Thankyou',
    display: 'Display',
    sendemail: 'Send Email',
    emailsent: 'Email Sent',
    preview: 'Preview',
    consent: 'Consent',
    studyid: 'Study Status',
    familyid: 'FamilyId',
    hascomment: 'Has Comment',
    participantid: 'ParticipantId',
    section: 'Section',
    child: 'Child',
    parent: 'Parent',
    speaking_language: 'Speaking Language',
    study_type: 'Study Type',
    test_name: 'Test Name',
    test_completed: 'Completed',
    view_details: 'View Details',
    apt: 'Apt',
    total: 'Total',
    assignments: 'Assignments',
    mockparticipant: 'Mock Participant',
    reassign: 'Reassign',
    completion: 'Completion',
    clone: 'Clone',
    sync: 'Sync',
    contacted: 'Contacted',
    not_contacted: 'Not Yet Contacted',
    history: 'History',
    device: 'Device',
    sms_template: 'SMS Template',
    description: 'Description',
    checklist: 'Checklist',
    history: 'History',
    item: 'Item',
    price: 'Price',
    quantity: 'Quantity',
    purchase: 'Purchase',
    ref_no: 'Ref No.',
    purchase_by: 'Purchased By',
    dispatch: 'Dispatch',
    receive: 'Receive',
    phlebotomy: 'Phlebotomy and Saliva Collection',
    equipment: 'Equipment',
    tube: 'Tube',
    test: 'Test',
    barcode: 'Barcode',
    qrcode: 'QR code',
    capacity: 'Capacity',
    unit: 'Unit',
    inventory_no: 'Inventory No',
    draw_from: 'Blood Drawn From',
    filled_to_capacity: 'Filled To Capacity',
    second_attempt: 'Second Attempt',
    end: 'End',
    result: 'Result',
    result_recorded_date: 'Result Recorded Date',
    result_recorded_time: 'Result Recorded Time',
    anomalies: 'Anomalies',
    normal_range: 'Normal Range',
    notes: 'Notes',
    need_review: 'Need Review',
    ldd: 'LDD',
    lloq: 'LLOQ',
    selected: 'Selected',
    current: 'Current',
    start_time: 'Start Time',
    end_time: 'End Time',
    assign_date: 'Assign Date',
    unassign_date: 'Unassign Date',
    number: "Number",
    canvassing: 'Canvassing',
    association: 'Association',
    rds: 'RDS',
    coupon: 'Coupon',
    recruitment: 'Recruitment',
    mark: 'Mark',
    as: 'as',
    complete: 'Complete',
    remove: 'Remove',
    completion: 'Completion',
    of: 'of',
    reason: 'Reason',
    isCompleted: 'Is Completed',
    transfer: 'Transfer',
    householdid: 'Household ID',
    familyid: 'Family ID',
    date_consent_taken: 'Date Consent Taken',
    date_scanned: 'Date Scanned',
    file: 'File',
    upload: 'Upload',
    paper: 'Paper',
    remind: 'Remind',
    me: 'me',
    notifications: 'Notifications',
    reminders: 'Reminders',
    alerts: 'Alerts',
    title: 'Title',
    alertTopic: 'Alert topic',
    sendHowManyTimes: 'Send it how many times?',
    whenToSendAlert: 'When to send alert?',
    message: 'Message',
    settings: 'Settings',
    to: 'To',
    subject: 'Subject',
    alertExpiration: 'Alert Expiration',
    lot_number: 'Lot Number',
    tube_status: 'Tube Status',
    ups_tracking_no: 'UPS Tracking Number',
    date: 'Date',
    tube_fill_status: 'Tube Fill Status',
    phlebotomy_saliva: 'Phlebotomy/Saliva',
    tube_container: 'Tube/Container',
    data_entry_blood_saliva: 'DATA ENTRY FOR BLOOD DRAW AND SALIVA',
    data_entry_for: 'Data Entry For',
    eligibility: 'Eligibility',
    randomize_questions: 'Randomize Questions',
    randomize_options: 'Randomize Options',
    ethnicity: 'Ethnicity',
    sendConsentEmail: 'Send Consent Email',
    consentEmailSent: 'Consent Email Sent',
    data_collection_type: 'Data Collection Type',
    view: 'View',
    generate: 'Generate',
    triageReport: 'Triage Report',
    open: 'Open',
    time_point: 'Time Point',
    staff_id: 'Staff ID',
    participant_id: 'Participant ID',
    reviewed_by_partner: 'Reviewed By Partner',
    interview1_date_time: 'Interview 1: Date and time',
    interview1_completion_status: 'Interview 1: Completion Status',
    interview2_date_time: 'Interview 2: Date and time',
    interview2_completion_status: 'Interview 2: Completion Status',
    interview3_date_time: 'Interview 3: Date and time',
    interview3_completion_status: 'Interview 3: Completion Status',
    interview4_date_time: 'Interview 4: Date and time',
    interview4_completion_status: 'Interview 4: Completion Status',
    interview5_date_time: 'Interview 5: Date and time',
    interview5_completion_status: 'Interview 5: Completion Status',
    interview6_date_time: 'Interview 6: Date and time',
    interview6_completion_status: 'Interview 6: Completion Status',
    gift_card: 'Gift Card',
    transcribed: 'Transcribed',
    referral: 'Referral',
    race: 'Race',
    reason: 'Reason',
    interview1_staff: 'Interview 1: Staff',
    interview2_staff: 'Interview 2: Staff',
    interview3_staff: 'Interview 3: Staff',
    interview4_staff: 'Interview 4: Staff',
    interview5_staff: 'Interview 5: Staff',
    interview6_staff: 'Interview 6: Staff',
    shelf_number: 'Shelf Number',
    tube_rack_number: 'Tube Rack Number',
    sample_position_in_tube_rack: 'Sample Position in Tube Rack',
    link_or_card: 'Link or Card',
    card: 'Card',
    ra_staff: 'RA/ Staff',
    date_sent: 'Date Sent',
    date_receipt_received: 'Date Receipt Received',
    date_of_activation: 'Date Of Activation If Sent By Mail',
    gift_card_voucher: 'Gift Card & Voucher',
    tracking: 'Tracking',
    unassigned_report: 'Unassigned Report',
    insurance: 'Insurance',
    participant_info_report: 'Participant Info Report',
    validate: 'Validate',
    verify: 'Verify',
    answer_id: 'Answer ID',
    instrument_id: 'Instrument ID',
    language_id: 'Language ID',
    question_id: 'Question ID',
    deleted_quetions_answers: 'Deleted Questions Answers',
    triggered_quetions_answers: 'Triggered Questions Answers',
    no_parent_quetions_answers: 'No Parent Triggered Questions Answers',
    deleted_options_answers: 'Deleted Options Answers',
    incomp_report: 'Incompletion Report',
    partial_comp_report: 'Partially Completed Report',
    last: 'Last',
    contact: 'Contact',
    details: 'Details',
    of: 'Of',
    reluctance: 'Reluctance',
    severity: 'Severity',
    contacted: 'Contacted',
    who: 'Who',
    not_contacted_yet: 'Not contacted yet',
    not_interviewed_yet: 'Not interviewed yet',
    total_assigned: 'Total Assigned',
    not_contacted_and_interviewed_report: 'Not Contacted & Interviewed Report',
    send: 'Send',
    cancel: 'Cancel',
    amount: 'Amount',
    event: 'Event',
    survey: 'Survey',
    coordinator_only: 'Coordinator Only',
    date_of_signature: 'Date of Signature',
    date_of_filing: 'Date of Filing',
    date_of_interview: 'Date of Interview',
    name_of_subject: 'Name of Subject',
    signature_of_subject: 'Signature of Subject',
    interviewer_initials: 'Interviewer Initials',
    dear: 'Dear',
    gift_card_para_1: 'Thank you for your recent participation in our research! Community members like you play an important role in our work, and we thank you again for adding your voice to our community. We could not do this without you!',
    gift_card_para_2_sentence_1: 'As discussed, enclosed is your gift card in the amount of ',
    gift_card_para_2_sentence_2: ' This card can be used at any store or online merchant nationwide. To use the card, follow the instructions emailed to you and sign the receipt below to acknowledge receiving the card. Should you have any questions or difficulties in completing the form, please contact us at: ',
    gift_card_para_2_sentence_3: ' or call us at ',
    gift_card_para_3_sentence_1: "And don't forget to join our community. From seminars, to outreach, to our ongoing research, there is always something happening. If you are interested in learning more about us and future events, just visit our website, ",
    gift_card_para_3_sentence_2: " We couldn't do what we do without your support!",
    gift_card_para_4_sentence_1: "Thank you for taking part in this study. By signing below, you acknowledge receiving ",
    gift_card_para_4_sentence_2: " for participation in this study.",
    sincerely: "Sincerely",
    gift_card: 'Gift Card',
    organization_line: 'Organization Line',
    address_line: 'Address Line',
    website: 'Website',
    handle: 'Handle',
    fax: 'Fax',
    logo: 'Logo',
    team: "Team",
    review: 'Review',
    would_you_like_to_review: "Would you like to review the consent before submitting the interview?",
    consent_warning_note: "Note: After interview completion, the consent will be locked, and you will not be able to modify the answers. Aww yeah, you successfully read this important alert message. This example text is going to run a bit longer so that you can see how spacing within an alert works with this kind of content.",
    updated_at: 'Updated At',
    recent: 'Recent',
    social_media: 'Social Media',
    url: 'URL',
    image: 'Image',
    interview_expiration_date: 'Interview Expiration Date',
    redirect_url: 'Redirect Url',
    saliva: 'Saliva',
    ID: 'ID',
    collected: 'Collected',
    in_person_or_mail: 'In person or Mail',
    arrival_date: 'Arrival Date',
    mandatory_survey: 'Mandatory Survey',
    supplemental_survey: 'Supplemental Survey',
    actigraphy: 'Actigraphy',
    blood_draw: 'Blood Draw',
    signature: 'Signature',
    mail: 'Mail',
    errors: 'Errors',
    oa_ma_report: 'OA & MA Report',
    percent_completion_of: 'Percent Completion Of',
    wave: 'Wave',
    criteria: 'Criteria',
    report: 'Report',
    physical: 'Physical',
    cognitive_assessment: 'Cognitive Assessment',
    assessment: 'Assessment',
    lab: 'Lab',
    sent: 'Sent',
    signature_of_investigator: 'Signature of Investigator/Individual Obtaining Consent/Assent',
    to_the_best_ability: 'To the best of my ability, I have explained and discussed the full contents of the study including all of the information contained in this consent form. All questions of the research subject and those of his/her parent or legal guardian have been accurately answered.',
    investigator_person: 'Investigator/Person Obtaining Consent Name (Print)',
    outbound_tracking_number: 'Outbound Tracking Number',
    outbound_ship_date: 'Outbound Ship Date',
    inbound_tracking_number: 'Inbound Tracking Number',
    inbound_ship_date: 'Inbound Ship Date',
    storage_date: 'Storage Date',
    shelf: 'Shelf',
    additional_details: 'Additional Details',
    sent_via_email: "if sent via email",
    signed: 'Signed',
    responded: 'Responded',
    family: 'Family',
    cohort: 'Cohort',
    close: 'Close',
    tree: 'Tree',
    validation: 'Validation',
    variable: 'Variable',
    duplicate: 'Duplicate',
    hospital: 'Hospital',
    field_is_embedded: 'Field is embedded elsewhere',
    enumeration: "Enumeration",
    eligible: 'Eligible',
    not_available: 'Not Available',
    follow_up: 'Follow Up',
    reminder: 'Reminder',
    scheduling: 'Scheduling',
    location: 'Location',
    component: 'Component',
    trigger: 'Trigger',
    show: "Show",
    is_enumeration_informant: 'Is Enumeration Informant?',
    probability_or_immigrant_sample: 'Probability or Immigrant sample?',
    full: 'Full',
    total_household_members: 'Total Household Members',
    total_family_members: 'Total Family Members',
    download: 'Download',
    progress: "Progress",
    additional_display_information: 'Additionl Display Information',
    self_directed_interview: "Self Directed Interview Completion Time Display Screen",
    required: 'Required',
    this_questionnaire: "This Questionnaire will take approximately",
    to_complete: "to complete.",
    complete_questionnaire: "Complete Questionnaire",
    now: 'Now',
    later: 'Later',
    web_or_phone: 'Web Or Phone',
    in_person_dispatch: 'In Person Dispatch',
    ssrs: 'SSRS',
    biospecimen: 'Biospecimen',
    kit: 'Kit',
    shipment_carrier: 'Shipment Carrier',
    outbound: 'Outbound',
    inbound: 'Inbound',
    condition_on_receipt: 'Condition on Receipt',
    laboratory_receipt_date: 'Laboratory Receipt Date',
    laboratory_receipt_condition: 'Laboratory Receipt Condition',
    specimen_type: 'Specimen Type',
    lab_biospecimen_processing: 'Lab Biospecimen Processing',
    storage_information: 'Storage Information',
    storage_location: 'Storage Location',
    storage_temperature: 'Storage Temperature',
    storage_start_date: 'Storage Start Date',
    review_date: 'Review Date',
    spinning_date: 'Spinning Date',
    aliquoting_date: 'Aliquoting Date',
    sample_position_in_rack: 'Sample Position in Rack',
    rack_number: 'Rack Number',
    google_analytics: 'Google Analytics',
    measurement: 'Measurement',
    update_and_resend: 'Update and Resend Gift Card',
    update_without_resend: 'Update Without Resending Gift Card',
    multimedia: 'Multimedia',
    format: 'Format',
    optional_file_attachment: 'File attachment image, audio or video (Optional):',
    provide_video_url: 'Provide video URL',
    workflows: 'Workflows',
    workflow: 'Workflow',
    add_interview_workflow: 'Add Interviews in the workflow',
    active_after_completion: 'Active after completion of previous questionnaire.',
    flow: 'Flow',
    econsent: 'E-consent',
    duration: 'Duration',
    unit: 'Unit',
    active_after_consent: 'Active after completion of e-consent',
    active_in: 'Active in',
    after_completion: 'after completion of previous questionnaire',
    active_on_after: 'Active on/after',
    active_condition: 'Active condition',
    active_on_date: 'Active On Date',
    biospecimen_kit_status: 'Biospecimen Kit Status',
    and_kit_status: "and when previous interview's biospecimen kit status is",
    active_instantly: 'Active <strong> instantly </strong>',
    screening_tool: 'Screening Tool',
    information: 'Information',
    study: 'Study',
    video_acknowledgment: 'Have you completed watching the entire video?',
    yes: 'Yes',
    no: 'No',
    step: 'Step',
    study_overview: 'Study Overview Video',
    continue: 'Continue',
    not_activated_yet: 'Interview Not Activated Yet!',
    not_finished: 'Not Finished!',
    stage: 'Stage',
    disagreed: 'Disagreed',
    eligible_for_study: 'Eligible for study!',
    ineligible_for_study: 'Ineligible for study!',
    agreed: 'Agreed',
    optional_screening_tool_eligibility_condition: 'Screening Tool Eligibility Condition (Optional)',
    condition: 'Condition',
    value: 'Value',
    seperate_multiple_values: 'Separate multiple values with commas. The condition will be true if any one matches.',
    overview: 'Overview',
    video: 'Video',
    print: 'Print',
    individual: 'Individual',
    please_enter_email: 'Please enter your email address',
    change: 'Change',
    self_collect_blood_permission: 'Are you interested in a self-collected blood draw using the TAP Micro device.',
    i_am_in: 'I am in',
    i_am_out: 'I am out',
    member: 'Member',
    and_when_econsent_agreed: 'and when e-consent is agreed.',
    text: 'Text',
    hint: 'Hint',
    placeholder: 'Placeholder',
    default: 'Default',
    introduction: 'Introduction',
    identifier_info_text: 'Does the field contain identifying information (e.g. name, SSN, address)',
    required_info_text: '* Prompt if field is blank',
    variable_name_info_text: 'Only letters, numbers & underscores',
    disable_info_text: "Choose 'Yes' to hide, 'No' to keep this question active.",
    help_info_text: "Additional information below the question",
    placeholder_info_text: "Enter text to display inside the field.",
    default_value_info_text: "Automatically fill this field with a preset value.",
    auto: 'Auto',
    check_errors_other_lang_instruments: "Check for errors in interview's other language instruments!",
    check_errors_other_lang_instruments_info: "Ensure accuracy in interview instruments by reviewing for potential issues such as mismatched question counts in other language instruments, discrepancies in question types, options count, and validations like whether a question is required, has comments, or serves as an identifier.",
    participants_having_deleted_questions_answers: "Participants having deleted question's answer in the interview!",
    participants_having_deleted_questions_answers_info: "Verify if participants have submitted answers for questions that were deleted from the instruments in the interview.",
    check_errors_other_lang_consent: "Check for errors in other language consent!",
    check_errors_other_lang_consent_info: "Ensure accuracy in consent by reviewing for potential issues such as mismatched section or question counts in other language consent, discrepancies in question types, options count, and validations like whether a question is required.",
    multilingual: 'Multilingual',
    stay: 'Stay',
    leave: 'Leave',
    workflow_not_eligible_text: `Thank you for your interest in the ${constant.app_name}! Unfortunately, at this time, your responses indicate that you would not be eligible to participate in the study. Please feel free to help spread the word about this study to friends or family members that you think may be eligible to join the study.`,
    congratulations: 'Congratulations',
    congratulations_screen_text: `Based on the answers you provided in the screening survey, <h5 class='d-inline'> you are eligible to participate in the study. </h5> Be sure to watch the ${constant.app_name} overview video to learn more about the study and what’s involved in participating to see if the study is the right fit for you.`,
    best_wishes: 'Best Wishes',
    the_ks_team: `${constant.email_app_sign_full}`,
    show_input_field: 'Show Input Field',
    aliquot: 'Aliquot',
    specimen: 'Specimen',
    freezer: 'Freezer',
    tube_freezer_storage_box_name: 'Tube Freezer Storage Box Name',
    tube_freezer_storage_box_position_name: 'Tube Freezer Storage Box Position Name',
    source: 'Source',
    pregnant: 'Pregnant',
    rows: 'Rows',
    columns: 'Columns',
    create: 'Create',
    storage: 'Storage',
    box: 'Box',
    leave_empty_to_auto_generate: 'Leave blank to automatically generate value',
    please_select_freezer_stoarage_box: 'Please select freezer storage box',
    click_icon_create_storage_box: 'Click the icon to create a new freezer storage box',
    click_icon_select_storage_position: 'Click the icon to select a freezer storage box position',
    participation_in_study_closed_out: "Your participation in the study has been closed out.",
    please_enter_email_or_phone: "Please enter your email address or phone number",
    preferred_method_of_communication: 'Preferred Method Of Communication',
    opt_in: 'Opt In',
    opt_out: 'Opt Out',
    cheatblocker: "Cheatblocker",
    compare_dates_by: 'Compare Dates By',
    time_period: 'Time Period',
    criteria: 'Criteria',
    field: 'Field',
    in: 'In',
    interview_answer_condition: 'Interview Answers Condition',
    after_previous_answer_provided: "questionnaire's <strong> required answer provided </strong>",
    even_after_previous_answer_missing: "even after previous questionnaire's <strong> required answer missing </strong>",
    depends_on_previous_interview: 'Depends On Previous Interview',
    answer_provided_for_interview: 'Answer Provided For Interview',
    required_interview: 'Required Interview',
    after: 'after',
    its_kit_status: "and when it's biospecimen kit status is",
    after_completion_of_econsent: 'after completion of econsent',
    to_activate_this_interview: 'to activate this interview',
    filled_returned: 'Filled & Returned',
    urine: 'Urine',
    browse_files: 'Browse Files',
    upload_files: 'Upload Files',
    media: 'Media',
    path: 'Path',
    resolution: 'Resolution',
    size: 'Size',
    uploading: 'Uploading',
    contact_form_message: `Welcome to the ${constant.app_name}! Please take a moment to share your contact information with us so that we can stay in touch with updates about the study.`,
    after_interviews_kit_status: "interviews's <strong> biospecimen kit status </strong> is",
    active_on_condition: 'Active on condition',
    active_without_answer_kit_submission: 'Active without interview answer and biospecimen kit submission',
    active_after_4_weeks_final_reminder: "Or active after 4 weeks from final reminder of required interview's survey / kit",
    active_after_postnatal: "after postnatal period",
    zipcode: 'Zipcode',
    are_this_true: "Are either of these currently true?",
    no_longer_pregnant: "You are no longer pregnant",
    moved_out_of_state: "You have moved out of state",
    not_meet_criteria_for_study: "Thank you for your interest. Unfortunately, you do not meet the eligibility criteria for this study.",
    workflow_not_eligible_text_2: `Thank you for your interest in the ${constant.app_name}! Since our study has very specific requirements to participate, not everyone is eligible to join the study. Unfortunately, at this time, your answers indicate that the <h5 class='d-inline'> eligibility requirements for the study were not met. </h5> We wanted to send a special guide with helpful community resources for pregnant individuals and parents. You can download the guide here. A copy will also be sent to your email. We would love to stay in touch to let you know about the things we learn from this study. Be sure to visit us at ${constant.email_app_public_website}  for newsletters and updates.`,
    study_video_title: "Learn more about the study and what's involved by watching this short video.",
    email_app_sign_full: `The ${constant.email_app_sign_full}`,
    warmest_regards: 'Warmest Regards',
    questionnaire_completed_subtext: "Thank you for completing your questionnaire! Our team is getting your next sample kit ready for shipment. We'll let you know when your kit has shipped and is on its way to you. Wishing you a great week ahead.",
    questionnaire_completed: "Questionnaire Completed!",
    participation_close_subtext: `Thank you so much for participating in the ${constant.app_name}! On behalf of our team, we are very grateful that you spent some time participating in the study. We just wanted to let you know that your participation in the study has been closed out. If you have any questions for our team or would like to rejoin the study at some other time in the future, feel free to reach out via email at ${constant.app_email} or phone ${constant.app_phone}.`,
    participation_close_title: "Participation in the Study Closed Out!",
    link_expire_subtext: `Thank you so much for participating in the ${constant.app_name}! On behalf of our team, we are very grateful that you spent some time participating in the study. We wanted to inform you that the link to the interview you tried to access has expired. If you have any questions for our team feel free to reach out via email at ${constant.app_email} or phone ${constant.app_phone}.`,
    link_expire_title: "Interview Link Expired!",
    rollover_question: 'Rollover Question',
    rollover_question_info_text: "Is this question rollover from another interview?",
    rollover_question_variable_info_text: "Please enter the label/variable name for the question you wish to roll over.",
    rollover_question_interview_info_text: "Please select the interview of the question you wish to roll over.",
    workflow_rollover_question_settings: "Workflow Rollover Question Settings (Optional)",
    interview_completed_message: "Congratulations! Your interview is 100% completed. Thank you for your time and participation!",
    triggers_no_longer_pregnant: "Selecting 'No' will trigger email to participant that they are no longer pregnant.",
    no_files_to_show: "No Files to show!",
    drag_and_drop_files: "Drag 'n' drop some files here, or click to select files",
    file_upload_limit: "File upload limit: 500MB maximum per file.",
    files: "Files",
    loading: "Loading",
    are_you_sure_to_delete: "Are you sure you want to delete this entry?",
    something_went_wrong: "Something went wrong. Please try again later.",
    contact_information_updated_message: "Your contact information has been successfully updated!",
    answer_all_required_que: "Please answer all the (*) required questions!",
    your_consent_is_recorded: "Your consent has been successfully recorded!",
    max_files: "Max 5 files",
    each_file_less_than: "Each file must be less than 2Mb",
    interview_saved_successfully: "Interview Saved Successfully!",
    unable_to_save_interview: "Unable to save interview!",
    unable_to_save_contact_info: "Unable to Update Participant Contact Info! Try Again",
    i_agree: "I Agree",
    disagree: "Disagree",
    forgotten_password: "Forgotten password?",
    login: "Login",
    sign_in_to_your_account: "Sign In to your account",
    login_as: "Login as",
    login_welcome_text: `Welcome to the ${constant.app_name} Dashboard! Please login to your account with your credentials [username and password]. Thank you for your support in advancing child health!`,
    login_with_password: "Login with password",
    continue: "Continue",
    password_reset_link_sent: "Password reset link has been sent to your email. Please check your inbox to proceed.",
    link_valid_for_note: "Note that the link will be valid for one hour.",
    please_enter_email: "Please enter your email",
    my_profile: "My Profile",
    change_password: "Change password",
    logout: "Logout",
    password_changed_success: "Password successfully changed. Click the 'Login' button to log in with your new credentials.",
    password_reset_link_expire: "The password reset link has expired or contains an invalid token.",
    mark_as_bookmark_message: `To bookmark this page:\n - Press Ctrl/Cmd + D, or\n - Click on the star icon in the address bar, or\n - Open the browser menu and select 'Bookmark This Page'.`,
    lock_temporarily: "Lock Temporarily",
    interview_temporarily_locked: "Interview Temporarily Locked",
    interview_locked_subtext: `Thank you so much for participating in the ${constant.app_name}! On behalf of our team, we are very grateful that you spent some time participating in the study. We wanted to inform you that the interview you tried to access is currently locked. If you have any questions or need assistance, feel free to reach out to our team via email at ${constant.app_email} or phone ${constant.app_phone}.`,
    unsaved_changes_message: "You have unsaved changes. Are you sure you want to leave?",
    search: "Search",
    find: "Find",
    clear: "Clear",
    study_status: "Study Status",
    url_copied_success: "URL copied successfully!",
    send_immediately: "Send Immediately",
    send_after_lapse: "Send after lapse of time:",
    send_at_exact: "Send at exact date/time",
    send_before_six: "Send before 6 hours of the scheduled time",
    send_before_one: "Send before 1 hours of the scheduled time",
    just_once: "Just Once",
    multiple_times: "Multiple times: Send every",
    after_initially_sent: "after initially being sent",
    optional: "Optional",
    click_to_remove_email: "Click to remove email",
    or: "Or",
    take_picture: "Take Picture",
    camera: "Camera",
    selected_files: "Selected Files",
    retake: "Retake",
    capture_photo: "Capture Photo",
    switch_to: "Switch To",
    done: "Done",
    front: "Front",
    rear: "Rear",
    stop: "Stop",
    notification: "Notification",
    unsave_biospecimen_warning_message: "You have unsaved biospecimen changes. Are you sure you want to leave?",
    unsave_specimen_warning_message: "You have unsaved specimen type details changes. Are you sure you want to leave?",
    unsave_aliquoting_specimen_warning_message: "You have unsaved aliquoting specimen changes. Are you sure you want to leave?",
    biospecimen_saved: "Biospecimen saved successfully!",
    specimen_type_saved: "Specimen type details saved successfully!",
    aliquot_specimen_saved: "Aliquot Specimen type saved successfully!",
    are_you_sure_to_delete_this_entry: "Are you sure to delete this entry?",
    and: 'and',
    return_later: 'Return later',
    minimum: 'Minimum',
    maximum: "Maximum",
    minimum_value_info_text: "Required minimum value of the question",
    maximum_value_info_text: "Required maximum value of the question",
    error_messages: "Error Messages (Optional)",
    error: "Error",
    date_format: "Date/Datetime/Time",
    alpha: "Letters Only",
    numeric: "Number",
    digits: "MRN (10 digits/generic) / Phone",
    integer: "Integer",
    decimal: "Decimal Number",
    regex: "Zipcode",
    after_or_equal: "Minimum Date",
    before_or_equal: "Maximum Date",
    min: "Minimum Number",
    max: "Maximum Number",
    read_resource_guide: `- To read the English resource guide, <a href='${constant.english_resource_guide_link}'> click here </a>`,
    interview_complete_1: "You have completed your ",
    interview_complete_2: ` for the ${constant.app_name_full}. `,
    interview_complete_3: `To thank you for your participation, check out the NJKS resource guide, English <a href='${constant.english_resource_guide_link}'>click here</a>, Espanol <a href='${constant.spanish_resource_guide_link}'> click here </a> which is full of information to support healthy pregnancies and families in New Jersey.`,
    interview_complete_4: `You should receive your first Biospecimen Kit in the mail in 1-2 weeks. To learn more about what to expect, watch our ${constant.app_name} Biospecimen Box Opening Video <a href='${constant.biospecimen_box_opening_video_link}'>here</a>. If you have any questions or concerns, contact us at ${constant.email_app_email} or ${constant.email_app_phone}. You can access your Participant Dashboard at <a href='${constant.app_link}'>Login</a>. <br/> <br/> <strong> Thank you for supporting the ${constant.research_study_name}! </strong>`,
    questionnaire: 'Questionnaire',
    form: "Form",
    header: "Header",
    footer: "Footer",
    show_popup_message: "Show Popup Message",
    popup_message: "Popup Message",
    popup_condition: "Popup Condition",
    popup_message_info_text: "Show popup message when question is answered",
    operator: "Operator",
    please_enter_exact: "Please Enter Exact",
    ok: "Ok",
    url_copied: "Url copied successfully!",
    failed_url_copy: "Failed to copy URL!",
    remap_languages: "Remap Languages",
    remap_languages_successfully: "Remap languages successfully!",
    remap_languages_failed: "Failed to remap languages!",
    are_you_sure_to_remap: "Are you sure to remap languages of instruments?",
    study_introduction: "Study Introduction",
    first_name: "First Name",
    last_name: "Last Name",
    cell_phone: "Cell Phone",
    home_phone: "Home Phone",
    work_phone: "Work Phone",
    this_is_required_field: "This is a required field",
    if_you_are_not_eligible_for_study: "If you are not eligible for this study and believe this is an error, please don't hesitate to contact us.",
    to_continue_survey_check_email: "To continue your survey, log in using the credentials provided in the email.",
    login_credential_sent_to_your_below_contact_info: "Your login credentials have been sent to your registered email and phone number as shown below:",
    you_are_best_we_appreciate: "You’re the Best! We really appreciate you taking the time to complete this interview.",
    to_learn_more_about_study: "To learn more about the study,",
    click_here: "Click here.",
    kit_will_be_sent_to_address: "Your kit will be sent to the address you provided. If the address is not correct, please log in to your dashboard to update your contact information or reach out to the study team.",
    resources: "Resources",
    resources_text: `<h3 class='text-center'> Lorem Ipsum </h3> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. <br/> Lorem ipsum dolor sit amet <a target='__blank' href='https://google.com'>Click Here</a> <br/> Lorem ipsum dolor sit amet <a target='__blank' href='https://google.com'>Click Here</a>`,
    spanish_interview_name: "Spanish Interview Name",
    volume: "Volume",
    unsigned_giftcard_notifications_disabled: 'Disable Unsigned Gift Card Notifications',
    unsigned_giftcard_notificatipns_disabled_warning_message: "Please note that unsigned gift card notifications have been disabled, and participants will no longer receive gift card reminder notifications.",
    notification_history: "Notification History",
    sms: "SMS",
    sent_at: "Sent at",


  },

  tagalog: {
    dashboard: 'Dashboard Tagalog',
    system: 'SYSTEM',
    master: 'Master',
    language: 'Language',
    languages: 'Languages',
    category: 'Category',
    country: 'Country',
    countries: 'Countries',
    state: 'State',
    states: 'States',
    city: 'City',
    streettype: 'Street Type',
    street: 'Street',
    direction: 'Direction',
    residence: 'Residence',
    residences: 'Residences',
    recruitment: 'Recruitment',
    recruitments: 'Recruitments',
    socialgroup: 'Social Group',
    socialgroups: 'Social Groups',
    speakinglanguages: 'Speaking Languages',
    purpose: 'Purpose',
    purposes: 'Purposes',
    typeofcontact: 'Type Of Contact',
    typeofcontacts: 'Type Of Contacts',
    syssetting: 'System Settings',
    contactsetting: 'Contact Settings',
    user: 'User',
    users: 'Users',
    role: 'Role',
    gensetting: 'General Settings',
    myprofile: 'My Profile',
    changepassword: 'Change Password',
    databank: 'INTERVIEW AND DATABANK',
    participant: 'Participant',
    participants: 'Participants',
    instrument: 'Instrument',
    interview: 'Interview',
    name: 'Name',
    action: 'Action',
    edit: 'Edit',
    delete: 'Delete',
    add: 'Add',
    back: 'Back',
    update: 'Update',
    srno: 'Sr. No',
    submit: 'Submit',
    reset: 'Reset',
    enter: 'Enter',
    categories: 'Categories',
    select: 'Select',
    email: 'Email',
    first: 'First',
    last: 'Last',
    username: 'Username',
    other: 'Other',
    regulation: 'Regulation',
    id: 'id',
    dob: 'Date of birth',
    gender: 'Gender',
    address: 'Address',
    address2: 'Address 2',
    zip: 'Zip',
    home: 'Home',
    work: 'Work',
    phone: 'Phone',
    cell: 'Cell',
    phone_1: 'Phone No. 1',
    phone_2: 'Phone No. 2',
    phone_3: 'Phone No. 3',
    phone_4: 'Phone No. 4',
    comment: 'Comment',
    waveidentifier: 'Wave Identifier',
    puma: 'PUMA',
    psu: 'PSU',
    ssu: 'SSU',
    ifh_id: 'IFH ID',
    profile: 'Profile',
    picture: 'Picture',
    password: 'Password',
    confirm: 'Confirm',
    old: 'Old',
    new: 'New',
    age: 'Age',
    speaking: 'Speaking',
    reading: 'Reading',
    writing: 'Writing',
    preference: 'Preference',
    ifother: 'If Other',
    abbreviation: 'Abbreviation',
    content: 'Content',
    question: 'Question',
    questions: 'Questions',
    details: 'Details',
    up: 'up',
    down: 'down',
    copy: 'Copy',
    option: 'Option',
    from: 'From',
    options: 'Options',
    label: 'Label',
    help: 'help',
    parent: 'Parent',
    istrigger: 'Is Trigger',
    abbrivation: 'Abbrivation',
    for: 'for',
    assigned: 'Assigned',
    assign: 'Assign',
    staff: 'Staff',
    status: 'Status',
    type: 'Type',
    contact: 'Contact',
    contacts: 'Contacts',
    previous: 'Previous',
    date: 'Date',
    time: 'Time',
    start: 'Start',
    next: 'Next',
    save: 'Save',
    list: 'List',
    kinship: 'Kinship',
    kinships: 'kinships',
    tag: 'Tag',
    tags: 'Tags',
    schedule: 'Schedule',
    scheduled: 'Scheduled',
    field: 'Field',
    mandatory: 'Mandatory',
    isrequire: 'Is Required?',
    conduct: 'Conduct',
    pause: 'Pause',
    assignment: 'Assignment',
    relationship: 'Relationship',
    with: 'With',
    method: 'Method',
    bloodsample: 'Blood Sample',
    tubecount: 'Tube Count',
    bsd: 'Date',
    watch: 'Watch',
    pddate: 'Date',
    code: 'Code',
    coding: 'Coding',
    compltreport: 'Completion Report',
    percentage: 'Percentage',
    answer: 'Answer',
    thankyou: 'Thankyou',
    display: 'Display',
    sendemail: 'Send Email',
    emailsent: 'Email Sent',
    preview: 'Preview',
    consent: 'Consent',
    studyid: 'Study Status',
    familyid: 'FamilyId',
    hascomment: 'Has Comment',
    participantid: 'ParticipantId',
    section: 'Section',
    child: 'Child',
    parent: 'Parent',
    speaking_language: 'Speaking Language',
    study_type: 'Study Type',
    test_name: 'Test Name',
    test_completed: 'Completed',
    view_details: 'View Details',
    apt: 'Apt',
    total: 'Total',
    assignments: 'Assignments',
    mockparticipant: 'Mock Participant',
    reassign: 'Reassign',
    completion: 'Completion',
    clone: 'Clone',
    sync: 'Sync',
    contacted: 'Contacted',
    not_contacted: 'Not Yet Contacted',
    history: 'History',
    device: 'Device',
    sms_template: 'SMS Template',
    description: 'Description',
    checklist: 'Checklist',
    history: 'History',
    item: 'Item',
    price: 'Price',
    quantity: 'Quantity',
    purchase: 'Purchase',
    ref_no: 'Ref No.',
    purchase_by: 'Purchased By',
    dispatch: 'Dispatch',
    receive: 'Receive',
    phlebotomy: 'Phlebotomy and Saliva Collection',
    equipment: 'Equipment',
    tube: 'Tube',
    test: 'Test',
    barcode: 'Barcode',
    qrcode: 'QR code',
    capacity: 'Capacity',
    unit: 'Unit',
    inventory_no: 'Inventory No',
    draw_from: 'Blood Drawn From',
    filled_to_capacity: 'Filled To Capacity',
    second_attempt: 'Second Attempt',
    end: 'End',
    result: 'Result',
    result_recorded_date: 'Result Recorded Date',
    result_recorded_time: 'Result Recorded Time',
    anomalies: 'Anomalies',
    normal_range: 'Normal Range',
    notes: 'Notes',
    need_review: 'Need Review',
    ldd: 'LDD',
    lloq: 'LLOQ',
    selected: 'Selected',
    current: 'Current',
    start_time: 'Start Time',
    end_time: 'End Time',
    assign_date: 'Assign Date',
    unassign_date: 'Unassign Date',
    number: "Number",
    canvassing: 'Canvassing',
    association: 'Association',
    rds: 'RDS',
    coupon: 'Coupon',
    recruitment: 'Recruitment',
    mark: 'Mark',
    as: 'as',
    complete: 'Complete',
    remove: 'Remove',
    completion: 'Completion',
    of: 'of',
    reason: 'Reason',
    isCompleted: 'Is Completed',
    transfer: 'Transfer',
    householdid: 'Household ID',
    familyid: 'Family ID',
    date_consent_taken: 'Date Consent Taken',
    date_scanned: 'Date Scanned',
    file: 'File',
    upload: 'Upload',
    paper: 'Paper',
    remind: 'Remind',
    me: 'me',
    notifications: 'Notifications',
    reminders: 'Reminders',
    alerts: 'Alerts',
    title: 'Title',
    alertTopic: 'Alert topic',
    sendHowManyTimes: 'Send it how many times?',
    whenToSendAlert: 'When to send alert?',
    message: 'Message',
    settings: 'Settings',
    to: 'To',
    subject: 'Subject',
    alertExpiration: 'Alert Expiration',
    lot_number: 'Lot Number',
    tube_status: 'Tube Status',
    ups_tracking_no: 'UPS Tracking Number',
    date: 'Date',
    tube_fill_status: 'Tube Fill Status',
    phlebotomy_saliva: 'Phlebotomy/Saliva',
    tube_container: 'Tube/Container',
    data_entry_blood_saliva: 'DATA ENTRY FOR BLOOD DRAW AND SALIVA',
    data_entry_for: 'Data Entry For',
    eligibility: 'Eligibility',
    randomize_questions: 'Randomize Questions',
    randomize_options: 'Randomize Options',
    ethnicity: 'Ethnicity',
    sendConsentEmail: 'Send Consent Email',
    consentEmailSent: 'Consent Email Sent',
    data_collection_type: 'Data Collection Type',
    view: 'View',
    generate: 'Generate',
    triageReport: 'Triage Report',
    open: 'Open',
    time_point: 'Time Point',
    staff_id: 'Staff ID',
    participant_id: 'Participant ID',
    reviewed_by_partner: 'Reviewed By Partner',
    interview1_date_time: 'Interview 1: Date and time',
    interview1_completion_status: 'Interview 1: Completion Status',
    interview2_date_time: 'Interview 2: Date and time',
    interview2_completion_status: 'Interview 2: Completion Status',
    interview3_date_time: 'Interview 3: Date and time',
    interview3_completion_status: 'Interview 3: Completion Status',
    interview4_date_time: 'Interview 4: Date and time',
    interview4_completion_status: 'Interview 4: Completion Status',
    interview5_date_time: 'Interview 5: Date and time',
    interview5_completion_status: 'Interview 5: Completion Status',
    interview6_date_time: 'Interview 6: Date and time',
    interview6_completion_status: 'Interview 6: Completion Status',
    gift_card: 'Gift Card',
    transcribed: 'Transcribed',
    referral: 'Referral',
    race: 'Race',
    reason: 'Reason',
    interview1_staff: 'Interview 1: Staff',
    interview2_staff: 'Interview 2: Staff',
    interview3_staff: 'Interview 3: Staff',
    interview4_staff: 'Interview 4: Staff',
    interview5_staff: 'Interview 5: Staff',
    interview6_staff: 'Interview 6: Staff',
    shelf_number: 'Shelf Number',
    tube_rack_number: 'Tube Rack Number',
    sample_position_in_tube_rack: 'Sample Position in Tube Rack',
    link_or_card: 'Link or Card',
    card: 'Card',
    ra_staff: 'RA/ Staff',
    date_sent: 'Date Sent',
    date_receipt_received: 'Date Receipt Received',
    date_of_activation: 'Date Of Activation If Sent By Mail',
    gift_card_voucher: 'Gift Card & Voucher',
    tracking: 'Tracking',
    unassigned_report: 'Unassigned Report',
    insurance: 'Insurance',
    participant_info_report: 'Participant Info Report',
    validate: 'Validate',
    verify: 'Verify',
    answer_id: 'Answer ID',
    instrument_id: 'Instrument ID',
    language_id: 'Language ID',
    question_id: 'Question ID',
    deleted_quetions_answers: 'Deleted Questions Answers',
    triggered_quetions_answers: 'Triggered Questions Answers',
    no_parent_quetions_answers: 'No Parent Triggered Questions Answers',
    deleted_options_answers: 'Deleted Options Answers',
    incomp_report: 'Incompletion Report',
    partial_comp_report: 'Partially Completed Report',
    last: 'Last',
    contact: 'Contact',
    details: 'Details',
    of: 'Of',
    reluctance: 'Reluctance',
    severity: 'Severity',
    contacted: 'Contacted',
    who: 'Who',
    not_contacted_yet: 'Not contacted yet',
    not_interviewed_yet: 'Not interviewed yet',
    total_assigned: 'Total Assigned',
    not_contacted_and_interviewed_report: 'Not Contacted & Interviewed Report',
    send: 'Send',
    cancel: 'Cancel',
    amount: 'Amount',
    event: 'Event',
    survey: 'Survey',
    coordinator_only: 'Coordinator Only',
    date_of_signature: 'Date of Signature',
    date_of_filing: 'Date of Filing',
    date_of_interview: 'Date of Interview',
    name_of_subject: 'Name of Subject',
    signature_of_subject: 'Signature of Subject',
    interviewer_initials: 'Interviewer Initials',
    dear: 'Dear',
    gift_card_para_1: 'Thank you for your recent participation in our research! Community members like you play an important role in our work, and we thank you again for adding your voice to our community. We could not do this without you!',
    gift_card_para_2_sentence_1: 'As discussed, enclosed is your gift card in the amount of ',
    gift_card_para_2_sentence_2: ' This card can be used at any store or online merchant nationwide. To use the card, follow the instructions emailed to you and sign the receipt below to acknowledge receiving the card. Should you have any questions or difficulties in completing the form, please contact us at: ',
    gift_card_para_2_sentence_3: ' or call us at ',
    gift_card_para_3_sentence_1: "And don't forget to join our community. From seminars, to outreach, to our ongoing research, there is always something happening. If you are interested in learning more about us and future events, just visit our website, ",
    gift_card_para_3_sentence_2: " We couldn't do what we do without your support!",
    gift_card_para_4_sentence_1: "Thank you for taking part in this study. By signing below, you acknowledge receiving ",
    gift_card_para_4_sentence_2: " for participation in this study.",
    sincerely: "Sincerely",
    gift_card: 'Gift Card',
    organization_line: 'Organization Line',
    address_line: 'Address Line',
    website: 'Website',
    handle: 'Handle',
    fax: 'Fax',
    logo: 'Logo',
    team: "Team",
    review: 'Review',
    would_you_like_to_review: "Would you like to review the consent before submitting the interview?",
    consent_warning_note: "Note: After interview completion, the consent will be locked, and you will not be able to modify the answers. Aww yeah, you successfully read this important alert message. This example text is going to run a bit longer so that you can see how spacing within an alert works with this kind of content.",
    updated_at: 'Updated At',
    recent: 'Recent',
    social_media: 'Social Media',
    url: 'URL',
    image: 'Image',
    interview_expiration_date: 'Interview Expiration Date',
    redirect_url: 'Redirect Url',
    saliva: 'Saliva',
    ID: 'ID',
    collected: 'Collected',
    in_person_or_mail: 'In person or Mail',
    arrival_date: 'Arrival Date',
    mandatory_survey: 'Mandatory Survey',
    supplemental_survey: 'Supplemental Survey',
    actigraphy: 'Actigraphy',
    blood_draw: 'Blood Draw',
    signature: 'Signature',
    mail: 'Mail',
    errors: 'Errors',
    oa_ma_report: 'OA & MA Report',
    percent_completion_of: 'Percent Completion Of',
    wave: 'Wave',
    criteria: 'Criteria',
    report: 'Report',
    physical: 'Physical',
    cognitive_assessment: 'Cognitive Assessment',
    assessment: 'Assessment',
    lab: 'Lab',
    sent: 'Sent',
    signature_of_investigator: 'Signature of Investigator/Individual Obtaining Consent/Assent',
    to_the_best_ability: 'To the best of my ability, I have explained and discussed the full contents of the study including all of the information contained in this consent form. All questions of the research subject and those of his/her parent or legal guardian have been accurately answered.',
    investigator_person: 'Investigator/Person Obtaining Consent Name (Print)',
    outbound_tracking_number: 'Outbound Tracking Number',
    outbound_ship_date: 'Outbound Ship Date',
    inbound_tracking_number: 'Inbound Tracking Number',
    inbound_ship_date: 'Inbound Ship Date',
    storage_date: 'Storage Date',
    shelf: 'Shelf',
    additional_details: 'Additional Details',
    sent_via_email: "if sent via email",
    signed: 'Signed',
    responded: 'Responded',
    family: 'Family',
    cohort: 'Cohort',
    close: 'Close',
    tree: 'Tree',
    validation: 'Validation',
    variable: 'Variable',
    duplicate: 'Duplicate',
    hospital: 'Hospital',
    field_is_embedded: 'Field is embedded elsewhere',
    enumeration: "Enumeration",
    eligible: 'Eligible',
    not_available: 'Not Available',
    follow_up: 'Follow Up',
    reminder: 'Reminder',
    scheduling: 'Scheduling',
    location: 'Location',
    component: 'Component',
    trigger: 'Trigger',
    show: "Show",
    is_enumeration_informant: 'Is Enumeration Informant?',
    probability_or_immigrant_sample: 'Probability or Immigrant sample?',
    full: 'Full',
    total_household_members: 'Total Household Members',
    total_family_members: 'Total Family Members',
    download: 'Download',
    progress: "Progress",
    additional_display_information: 'Additionl Display Information',
    self_directed_interview: "Self Directed Interview Completion Time Display Screen",
    required: 'Required',
    this_questionnaire: "This Questionnaire will take approximately",
    to_complete: "to complete.",
    complete_questionnaire: "Complete Questionnaire",
    now: 'Now',
    later: 'Later',
    web_or_phone: 'Web Or Phone',
    in_person_dispatch: 'In Person Dispatch',
    ssrs: 'SSRS',
    biospecimen: 'Biospecimen',
    kit: 'Kit',
    shipment_carrier: 'Shipment Carrier',
    outbound: 'Outbound',
    inbound: 'Inbound',
    condition_on_receipt: 'Condition on Receipt',
    laboratory_receipt_date: 'Laboratory Receipt Date',
    laboratory_receipt_condition: 'Laboratory Receipt Condition',
    specimen_type: 'Specimen Type',
    lab_biospecimen_processing: 'Lab Biospecimen Processing',
    storage_information: 'Storage Information',
    storage_location: 'Storage Location',
    storage_temperature: 'Storage Temperature',
    storage_start_date: 'Storage Start Date',
    review_date: 'Review Date',
    spinning_date: 'Spinning Date',
    aliquoting_date: 'Aliquoting Date',
    sample_position_in_rack: 'Sample Position in Rack',
    rack_number: 'Rack Number',
    google_analytics: 'Google Analytics',
    measurement: 'Measurement',
    update_and_resend: 'Update and Resend Gift Card',
    update_without_resend: 'Update Without Resending Gift Card',
    multimedia: 'Multimedia',
    format: 'Format',
    optional_file_attachment: 'File attachment image, audio or video (Optional):',
    provide_video_url: 'Provide video URL',
    workflows: 'Workflows',
    workflow: 'Workflow',
    add_interview_workflow: 'Add Interviews in the workflow',
    active_after_completion: 'Active after completion of previous questionnaire.',
    flow: 'Flow',
    econsent: 'E-consent',
    duration: 'Duration',
    unit: 'Unit',
    active_after_consent: 'Active after completion of e-consent',
    active_in: 'Active in',
    after_completion: 'after completion of previous questionnaire',
    active_on_after: 'Active on/after',
    active_condition: 'Active condition',
    active_on_date: 'Active On Date',
    biospecimen_kit_status: 'Biospecimen Kit Status',
    and_kit_status: "and when previous interview's biospecimen kit status is",
    active_instantly: 'Active <strong> instantly </strong>',
    screening_tool: 'Screening Tool',
    information: 'Information',
    study: 'Study',
    video_acknowledgment: 'Have you completed watching the entire video?',
    yes: 'Yes',
    no: 'No',
    step: 'Step',
    study_overview: 'Study Overview Video',
    continue: 'Continue',
    not_activated_yet: 'Interview Not Activated Yet!',
    not_finished: 'Not Finished!',
    stage: 'Stage',
    disagreed: 'Disagreed',
    eligible_for_study: 'Eligible for study!',
    ineligible_for_study: 'Ineligible for study!',
    agreed: 'Agreed',
    optional_screening_tool_eligibility_condition: 'Screening Tool Eligibility Condition (Optional)',
    condition: 'Condition',
    value: 'Value',
    seperate_multiple_values: 'Separate multiple values with commas. The condition will be true if any one matches.',
    overview: 'Overview',
    video: 'Video',
    print: 'Print',
    individual: 'Individual',
    please_enter_email: 'Please enter your email address',
    change: 'Change',
    self_collect_blood_permission: 'Are you interested in a self-collected blood draw using the TAP Micro device.',
    i_am_in: 'I am in',
    i_am_out: 'I am out',
    member: 'Member',
    and_when_econsent_agreed: 'and when e-consent is agreed.',
    text: 'Text',
    hint: 'Hint',
    placeholder: 'Placeholder',
    default: 'Default',
    introduction: 'Introduction',
    identifier_info_text: 'Does the field contain identifying information (e.g. name, SSN, address)',
    required_info_text: '* Prompt if field is blank',
    variable_name_info_text: 'Only letters, numbers & underscores',
    disable_info_text: "Choose 'Yes' to hide, 'No' to keep this question active.",
    help_info_text: "Additional information below the question",
    placeholder_info_text: "Enter text to display inside the field.",
    default_value_info_text: "Automatically fill this field with a preset value.",
    auto: 'Auto',
    check_errors_other_lang_instruments: "Check for errors in interview's other language instruments!",
    check_errors_other_lang_instruments_info: "Ensure accuracy in interview instruments by reviewing for potential issues such as mismatched question counts in other language instruments, discrepancies in question types, options count, and validations like whether a question is required, has comments, or serves as an identifier.",
    participants_having_deleted_questions_answers: "Participants having deleted question's answer in the interview!",
    participants_having_deleted_questions_answers_info: "Verify if participants have submitted answers for questions that were deleted from the instruments in the interview.",
    check_errors_other_lang_consent: "Check for errors in other language consent!",
    check_errors_other_lang_consent_info: "Ensure accuracy in consent by reviewing for potential issues such as mismatched section or question counts in other language consent, discrepancies in question types, options count, and validations like whether a question is required.",
    multilingual: 'Multilingual',
    stay: 'Stay',
    leave: 'Leave',
    workflow_not_eligible_text: `Thank you for your interest in the ${constant.app_name}! Unfortunately, at this time, your responses indicate that you would not be eligible to participate in the study. Please feel free to help spread the word about this study to friends or family members that you think may be eligible to join the study.`,
    congratulations: 'Congratulations',
    congratulations_screen_text: `Based on the answers you provided in the screening survey, <h5 class='d-inline'> you are eligible to participate in the study. </h5> Be sure to watch the ${constant.app_name} overview video to learn more about the study and what’s involved in participating to see if the study is the right fit for you.`,
    best_wishes: 'Best Wishes',
    the_ks_team: `${constant.email_app_sign_full}`,
    show_input_field: 'Show Input Field',
    aliquot: 'Aliquot',
    specimen: 'Specimen',
    freezer: 'Freezer',
    tube_freezer_storage_box_name: 'Tube Freezer Storage Box Name',
    tube_freezer_storage_box_position_name: 'Tube Freezer Storage Box Position Name',
    source: 'Source',
    pregnant: 'Pregnant',
    rows: 'Rows',
    columns: 'Columns',
    create: 'Create',
    storage: 'Storage',
    box: 'Box',
    leave_empty_to_auto_generate: 'Leave blank to automatically generate value',
    please_select_freezer_stoarage_box: 'Please select freezer storage box',
    click_icon_create_storage_box: 'Click the icon to create a new freezer storage box',
    click_icon_select_storage_position: 'Click the icon to select a freezer storage box position',
    participation_in_study_closed_out: "Your participation in the study has been closed out.",
    please_enter_email_or_phone: "Please enter your email address or phone number",
    preferred_method_of_communication: 'Preferred Method Of Communication',
    opt_in: 'Opt In',
    opt_out: 'Opt Out',
    cheatblocker: "Cheatblocker",
    compare_dates_by: 'Compare Dates By',
    time_period: 'Time Period',
    criteria: 'Criteria',
    field: 'Field',
    in: 'In',
    interview_answer_condition: 'Interview Answers Condition',
    after_previous_answer_provided: "questionnaire's <strong> required answer provided </strong>",
    even_after_previous_answer_missing: "even after previous questionnaire's <strong> required answer missing </strong>",
    depends_on_previous_interview: 'Depends On Previous Interview',
    answer_provided_for_interview: 'Answer Provided For Interview',
    required_interview: 'Required Interview',
    after: 'after',
    its_kit_status: "and when it's biospecimen kit status is",
    after_completion_of_econsent: 'after completion of econsent',
    to_activate_this_interview: 'to activate this interview',
    filled_returned: 'Filled & Returned',
    urine: 'Urine',
    browse_files: 'Browse Files',
    upload_files: 'Upload Files',
    media: 'Media',
    path: 'Path',
    resolution: 'Resolution',
    size: 'Size',
    uploading: 'Uploading',
    contact_form_message: `Welcome to the ${constant.app_name}! Please take a moment to share your contact information with us so that we can stay in touch with updates about the study.`,
    after_interviews_kit_status: "interviews's <strong> biospecimen kit status </strong> is",
    active_on_condition: 'Active on condition',
    active_without_answer_kit_submission: 'Active without interview answer and biospecimen kit submission',
    active_after_4_weeks_final_reminder: "Or active after 4 weeks from final reminder of required interview's survey / kit",
    active_after_postnatal: "after postnatal period",
    zipcode: 'Zipcode',
    are_this_true: "Are either of these currently true?",
    no_longer_pregnant: "You are no longer pregnant",
    moved_out_of_state: "You have moved out of state",
    not_meet_criteria_for_study: "Thank you for your interest. Unfortunately, you do not meet the eligibility criteria for this study.",
    workflow_not_eligible_text_2: `Thank you for your interest in the ${constant.app_name}! Since our study has very specific requirements to participate, not everyone is eligible to join the study. Unfortunately, at this time, your answers indicate that the <h5 class='d-inline'> eligibility requirements for the study were not met. </h5> We wanted to send a special guide with helpful community resources for pregnant individuals and parents. You can download the guide here. A copy will also be sent to your email. We would love to stay in touch to let you know about the things we learn from this study. Be sure to visit us at ${constant.email_app_public_website}  for newsletters and updates.`,
    study_video_title: "Learn more about the study and what's involved by watching this short video.",
    email_app_sign_full: `The ${constant.email_app_sign_full}`,
    warmest_regards: 'Warmest Regards',
    questionnaire_completed_subtext: "Thank you for completing your questionnaire! Our team is getting your next sample kit ready for shipment. We'll let you know when your kit has shipped and is on its way to you. Wishing you a great week ahead.",
    questionnaire_completed: "Questionnaire Completed!",
    participation_close_subtext: `Thank you so much for participating in the ${constant.app_name}! On behalf of our team, we are very grateful that you spent some time participating in the study. We just wanted to let you know that your participation in the study has been closed out. If you have any questions for our team or would like to rejoin the study at some other time in the future, feel free to reach out via email at ${constant.app_email} or phone ${constant.app_phone}.`,
    participation_close_title: "Participation in the Study Closed Out!",
    link_expire_subtext: `Thank you so much for participating in the ${constant.app_name}! On behalf of our team, we are very grateful that you spent some time participating in the study. We wanted to inform you that the link to the interview you tried to access has expired. If you have any questions for our team feel free to reach out via email at ${constant.app_email} or phone ${constant.app_phone}.`,
    link_expire_title: "Interview Link Expired!",
    rollover_question: 'Rollover Question',
    rollover_question_info_text: "Is this question rollover from another interview?",
    rollover_question_variable_info_text: "Please enter the label/variable name for the question you wish to roll over.",
    rollover_question_interview_info_text: "Please select the interview of the question you wish to roll over.",
    workflow_rollover_question_settings: "Workflow Rollover Question Settings (Optional)",
    interview_completed_message: "Congratulations! Your interview is 100% completed. Thank you for your time and participation!",
    triggers_no_longer_pregnant: "Selecting 'No' will trigger email to participant that they are no longer pregnant.",
    no_files_to_show: "No Files to show!",
    drag_and_drop_files: "Drag 'n' drop some files here, or click to select files",
    file_upload_limit: "File upload limit: 500MB maximum per file.",
    files: "Files",
    loading: "Loading",
    are_you_sure_to_delete: "Are you sure you want to delete this entry?",
    something_went_wrong: "Something went wrong. Please try again later.",
    contact_information_updated_message: "Your contact information has been successfully updated!",
    answer_all_required_que: "Please answer all the (*) required questions!",
    your_consent_is_recorded: "Your consent has been successfully recorded!",
    max_files: "Max 5 files",
    each_file_less_than: "Each file must be less than 2Mb",
    interview_saved_successfully: "Interview Saved Successfully!",
    unable_to_save_interview: "Unable to save interview!",
    unable_to_save_contact_info: "Unable to Update Participant Contact Info! Try Again",
    i_agree: "I Agree",
    disagree: "Disagree",
    forgotten_password: "Forgotten password?",
    login: "Login",
    sign_in_to_your_account: "Sign In to your account",
    login_as: "Login as",
    login_welcome_text: `Welcome to the ${constant.app_name} Dashboard! Please login to your account with your credentials [username and password]. Thank you for your support in advancing child health!`,
    login_with_password: "Login with password",
    continue: "Continue",
    password_reset_link_sent: "Password reset link has been sent to your email. Please check your inbox to proceed.",
    link_valid_for_note: "Note that the link will be valid for one hour.",
    please_enter_email: "Please enter your email",
    my_profile: "My Profile",
    change_password: "Change password",
    logout: "Logout",
    password_changed_success: "Password successfully changed. Click the 'Login' button to log in with your new credentials.",
    password_reset_link_expire: "The password reset link has expired or contains an invalid token.",
    mark_as_bookmark_message: `To bookmark this page:\n - Press Ctrl/Cmd + D, or\n - Click on the star icon in the address bar, or\n - Open the browser menu and select 'Bookmark This Page'.`,
    lock_temporarily: "Lock Temporarily",
    interview_temporarily_locked: "Interview Temporarily Locked",
    interview_locked_subtext: `Thank you so much for participating in the ${constant.app_name}! On behalf of our team, we are very grateful that you spent some time participating in the study. We wanted to inform you that the interview you tried to access is currently locked. If you have any questions or need assistance, feel free to reach out to our team via email at ${constant.app_email} or phone ${constant.app_phone}.`,
    unsaved_changes_message: "You have unsaved changes. Are you sure you want to leave?",
    search: "Search",
    find: "Find",
    clear: "Clear",
    study_status: "Study Status",
    url_copied_success: "URL copied successfully!",
    send_immediately: "Send Immediately",
    send_after_lapse: "Send after lapse of time:",
    send_at_exact: "Send at exact date/time",
    send_before_six: "Send before 6 hours of the scheduled time",
    send_before_one: "Send before 1 hours of the scheduled time",
    just_once: "Just Once",
    multiple_times: "Multiple times: Send every",
    after_initially_sent: "after initially being sent",
    optional: "Optional",
    click_to_remove_email: "Click to remove email",
    or: "Or",
    take_picture: "Take Picture",
    camera: "Camera",
    selected_files: "Selected Files",
    retake: "Retake",
    capture_photo: "Capture Photo",
    switch_to: "Switch To",
    done: "Done",
    front: "Front",
    rear: "Rear",
    stop: "Stop",
    notification: "Notification",
    unsave_biospecimen_warning_message: "You have unsaved biospecimen changes. Are you sure you want to leave?",
    unsave_specimen_warning_message: "You have unsaved specimen type details changes. Are you sure you want to leave?",
    unsave_aliquoting_specimen_warning_message: "You have unsaved aliquoting specimen changes. Are you sure you want to leave?",
    biospecimen_saved: "Biospecimen saved successfully!",
    specimen_type_saved: "Specimen type details saved successfully!",
    aliquot_specimen_saved: "Aliquot Specimen type saved successfully!",
    are_you_sure_to_delete_this_entry: "Are you sure to delete this entry?",
    and: 'and',
    return_later: 'Return later',
    minimum: 'Minimum',
    maximum: "Maximum",
    minimum_value_info_text: "Required minimum value of the question",
    maximum_value_info_text: "Required maximum value of the question",
    error_messages: "Error Messages (Optional)",
    error: "Error",
    date_format: "Date/Datetime/Time",
    alpha: "Letters Only",
    numeric: "Number",
    digits: "MRN (10 digits/generic) / Phone",
    integer: "Integer",
    decimal: "Decimal Number",
    regex: "Zipcode",
    after_or_equal: "Minimum Date",
    before_or_equal: "Maximum Date",
    min: "Minimum Number",
    max: "Maximum Number",
    read_resource_guide: `- To read the English resource guide, <a href='${constant.english_resource_guide_link}'> click here </a>`,
    interview_complete_1: "You have completed your ",
    interview_complete_2: ` for the ${constant.app_name_full}. `,
    interview_complete_3: `To thank you for your participation, check out the NJKS resource guide, English <a href='${constant.english_resource_guide_link}'>click here</a>, Espanol <a href='${constant.spanish_resource_guide_link}'> click here </a> which is full of information to support healthy pregnancies and families in New Jersey.`,
    interview_complete_4: `You should receive your first Biospecimen Kit in the mail in 1-2 weeks. To learn more about what to expect, watch our ${constant.app_name} Biospecimen Box Opening Video <a href='${constant.biospecimen_box_opening_video_link}'>here</a>. If you have any questions or concerns, contact us at ${constant.email_app_email} or ${constant.email_app_phone}. You can access your Participant Dashboard at <a href='${constant.app_link}'>Login</a>. <br/> <br/> <strong> Thank you for supporting the ${constant.research_study_name}! </strong>`,
    questionnaire: 'Questionnaire',
    form: "Form",
    header: "Header",
    footer: "Footer",
    show_popup_message: "Show Popup Message",
    popup_message: "Popup Message",
    popup_condition: "Popup Condition",
    popup_message_info_text: "Show popup message when question is answered",
    operator: "Operator",
    please_enter_exact: "Please Enter Exact",
    ok: "Ok",
    url_copied: "Url copied successfully!",
    failed_url_copy: "Failed to copy URL!",
    remap_languages: "Remap Languages",
    remap_languages_successfully: "Remap languages successfully!",
    remap_languages_failed: "Failed to remap languages!",
    are_you_sure_to_remap: "Are you sure to remap languages of instruments?",
    study_introduction: "Study Introduction",
    first_name: "First Name",
    last_name: "Last Name",
    cell_phone: "Cell Phone",
    home_phone: "Home Phone",
    work_phone: "Work Phone",
    this_is_required_field: "This is a required field",
    if_you_are_not_eligible_for_study: "If you are not eligible for this study and believe this is an error, please don't hesitate to contact us.",
    to_continue_survey_check_email: "To continue your survey, log in using the credentials provided in the email.",
    login_credential_sent_to_your_below_contact_info: "Your login credentials have been sent to your registered email and phone number as shown below:",
    you_are_best_we_appreciate: "You’re the Best! We really appreciate you taking the time to complete this interview.",
    to_learn_more_about_study: "To learn more about the study,",
    click_here: "Click here.",
    kit_will_be_sent_to_address: "Your kit will be sent to the address you provided. If the address is not correct, please log in to your dashboard to update your contact information or reach out to the study team.",
    resources: "Resources",
    resources_text: `<h3 class='text-center'> Lorem Ipsum </h3> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. <br/> Lorem ipsum dolor sit amet <a target='__blank' href='https://google.com'>Click Here</a> <br/> Lorem ipsum dolor sit amet <a target='__blank' href='https://google.com'>Click Here</a>`,
    spanish_interview_name: "Spanish Interview Name",
    volume: "Volume",
    unsigned_giftcard_notifications_disabled: 'Disable Unsigned Gift Card Notifications',
    unsigned_giftcard_notificatipns_disabled_warning_message: "Please note that unsigned gift card notifications have been disabled, and participants will no longer receive gift card reminder notifications.",
    notification_history: "Notification History",
    sms: "SMS",
    sent_at: "Sent at",



  },

  urdu: {
    dashboard: 'Dashboard Urdu',
    system: 'SYSTEM',
    master: 'Master',
    language: 'Language',
    languages: 'Languages',
    category: 'Category',
    country: 'Country',
    countries: 'Countries',
    state: 'State',
    states: 'States',
    city: 'City',
    streettype: 'Street Type',
    street: 'Street',
    direction: 'Direction',
    residence: 'Residence',
    residences: 'Residences',
    recruitment: 'Recruitment',
    recruitments: 'Recruitments',
    socialgroup: 'Social Group',
    socialgroups: 'Social Groups',
    speakinglanguages: 'Speaking Languages',
    purpose: 'Purpose',
    purposes: 'Purposes',
    typeofcontact: 'Type Of Contact',
    typeofcontacts: 'Type Of Contacts',
    syssetting: 'System Settings',
    contactsetting: 'Contact Settings',
    user: 'User',
    users: 'Users',
    role: 'Role',
    gensetting: 'General Settings',
    myprofile: 'My Profile',
    changepassword: 'Change Password',
    databank: 'INTERVIEW AND DATABANK',
    participant: 'Participant',
    participants: 'Participants',
    instrument: 'Instrument',
    interview: 'Interview',
    name: 'Name',
    action: 'Action',
    edit: 'Edit',
    delete: 'Delete',
    add: 'Add',
    back: 'Back',
    update: 'Update',
    srno: 'Sr. No',
    submit: 'Submit',
    reset: 'Reset',
    enter: 'Enter',
    categories: 'Categories',
    select: 'Select',
    email: 'Email',
    first: 'First',
    last: 'Last',
    username: 'Username',
    other: 'Other',
    regulation: 'Regulation',
    id: 'id',
    dob: 'Date of birth',
    gender: 'Gender',
    address: 'Address',
    address2: 'Address 2',
    zip: 'Zip',
    home: 'Home',
    work: 'Work',
    phone: 'Phone',
    cell: 'Cell',
    phone_1: 'Phone No. 1',
    phone_2: 'Phone No. 2',
    phone_3: 'Phone No. 3',
    phone_4: 'Phone No. 4',
    comment: 'Comment',
    waveidentifier: 'Wave Identifier',
    puma: 'PUMA',
    psu: 'PSU',
    ssu: 'SSU',
    ifh_id: 'IFH ID',
    profile: 'Profile',
    picture: 'Picture',
    password: 'Password',
    confirm: 'Confirm',
    old: 'Old',
    new: 'New',
    age: 'Age',
    speaking: 'Speaking',
    reading: 'Reading',
    writing: 'Writing',
    preference: 'Preference',
    ifother: 'If Other',
    abbreviation: 'Abbreviation',
    content: 'Content',
    question: 'Question',
    questions: 'Questions',
    details: 'Details',
    up: 'up',
    down: 'down',
    copy: 'Copy',
    option: 'Option',
    from: 'From',
    options: 'Options',
    label: 'Label',
    help: 'help',
    parent: 'Parent',
    istrigger: 'Is Trigger',
    abbrivation: 'Abbrivation',
    for: 'for',
    assigned: 'Assigned',
    assign: 'Assign',
    staff: 'Staff',
    status: 'Status',
    type: 'Type',
    contact: 'Contact',
    contacts: 'Contacts',
    previous: 'Previous',
    date: 'Date',
    time: 'Time',
    start: 'Start',
    next: 'Next',
    save: 'Save',
    list: 'List',
    kinship: 'Kinship',
    kinships: 'kinships',
    tag: 'Tag',
    tags: 'Tags',
    schedule: 'Schedule',
    scheduled: 'Scheduled',
    field: 'Field',
    mandatory: 'Mandatory',
    isrequire: 'Is Required?',
    conduct: 'Conduct',
    pause: 'Pause',
    assignment: 'Assignment',
    relationship: 'Relationship',
    with: 'With',
    method: 'Method',
    bloodsample: 'Blood Sample',
    tubecount: 'Tube Count',
    bsd: 'Date',
    watch: 'Watch',
    pddate: 'Date',
    code: 'Code',
    coding: 'Coding',
    compltreport: 'Completion Report',
    percentage: 'Percentage',
    answer: 'Answer',
    thankyou: 'Thankyou',
    display: 'Display',
    sendemail: 'Send Email',
    emailsent: 'Email Sent',
    preview: 'Preview',
    consent: 'Consent',
    studyid: 'Study Status',
    familyid: 'FamilyId',
    hascomment: 'Has Comment',
    participantid: 'ParticipantId',
    section: 'Section',
    child: 'Child',
    parent: 'Parent',
    speaking_language: 'Speaking Language',
    study_type: 'Study Type',
    test_name: 'Test Name',
    test_completed: 'Completed',
    view_details: 'View Details',
    apt: 'Apt',
    total: 'Total',
    assignments: 'Assignments',
    mockparticipant: 'Mock Participant',
    reassign: 'Reassign',
    completion: 'Completion',
    clone: 'Clone',
    sync: 'Sync',
    contacted: 'Contacted',
    not_contacted: 'Not Yet Contacted',
    history: 'History',
    device: 'Device',
    sms_template: 'SMS Template',
    description: 'Description',
    checklist: 'Checklist',
    history: 'History',
    item: 'Item',
    price: 'Price',
    quantity: 'Quantity',
    purchase: 'Purchase',
    ref_no: 'Ref No.',
    purchase_by: 'Purchased By',
    dispatch: 'Dispatch',
    receive: 'Receive',
    phlebotomy: 'Phlebotomy and Saliva Collection',
    equipment: 'Equipment',
    tube: 'Tube',
    test: 'Test',
    barcode: 'Barcode',
    qrcode: 'QR code',
    capacity: 'Capacity',
    unit: 'Unit',
    inventory_no: 'Inventory No',
    draw_from: 'Blood Drawn From',
    filled_to_capacity: 'Filled To Capacity',
    second_attempt: 'Second Attempt',
    end: 'End',
    result: 'Result',
    result_recorded_date: 'Result Recorded Date',
    result_recorded_time: 'Result Recorded Time',
    anomalies: 'Anomalies',
    normal_range: 'Normal Range',
    notes: 'Notes',
    need_review: 'Need Review',
    ldd: 'LDD',
    lloq: 'LLOQ',
    selected: 'Selected',
    current: 'Current',
    start_time: 'Start Time',
    end_time: 'End Time',
    assign_date: 'Assign Date',
    unassign_date: 'Unassign Date',
    number: "Number",
    canvassing: 'Canvassing',
    association: 'Association',
    rds: 'RDS',
    coupon: 'Coupon',
    recruitment: 'Recruitment',
    mark: 'Mark',
    as: 'as',
    complete: 'Complete',
    remove: 'Remove',
    completion: 'Completion',
    of: 'of',
    reason: 'Reason',
    isCompleted: 'Is Completed',
    transfer: 'Transfer',
    householdid: 'Household ID',
    familyid: 'Family ID',
    date_consent_taken: 'Date Consent Taken',
    date_scanned: 'Date Scanned',
    file: 'File',
    upload: 'Upload',
    paper: 'Paper',
    remind: 'Remind',
    me: 'me',
    notifications: 'Notifications',
    reminders: 'Reminders',
    alerts: 'Alerts',
    title: 'Title',
    alertTopic: 'Alert topic',
    sendHowManyTimes: 'Send it how many times?',
    whenToSendAlert: 'When to send alert?',
    message: 'Message',
    settings: 'Settings',
    to: 'To',
    subject: 'Subject',
    alertExpiration: 'Alert Expiration',
    lot_number: 'Lot Number',
    tube_status: 'Tube Status',
    ups_tracking_no: 'UPS Tracking Number',
    date: 'Date',
    tube_fill_status: 'Tube Fill Status',
    phlebotomy_saliva: 'Phlebotomy/Saliva',
    tube_container: 'Tube/Container',
    data_entry_blood_saliva: 'DATA ENTRY FOR BLOOD DRAW AND SALIVA',
    data_entry_for: 'Data Entry For',
    eligibility: 'Eligibility',
    randomize_questions: 'Randomize Questions',
    randomize_options: 'Randomize Options',
    ethnicity: 'Ethnicity',
    sendConsentEmail: 'Send Consent Email',
    consentEmailSent: 'Consent Email Sent',
    data_collection_type: 'Data Collection Type',
    view: 'View',
    generate: 'Generate',
    triageReport: 'Triage Report',
    open: 'Open',
    time_point: 'Time Point',
    staff_id: 'Staff ID',
    participant_id: 'Participant ID',
    reviewed_by_partner: 'Reviewed By Partner',
    interview1_date_time: 'Interview 1: Date and time',
    interview1_completion_status: 'Interview 1: Completion Status',
    interview2_date_time: 'Interview 2: Date and time',
    interview2_completion_status: 'Interview 2: Completion Status',
    interview3_date_time: 'Interview 3: Date and time',
    interview3_completion_status: 'Interview 3: Completion Status',
    interview4_date_time: 'Interview 4: Date and time',
    interview4_completion_status: 'Interview 4: Completion Status',
    interview5_date_time: 'Interview 5: Date and time',
    interview5_completion_status: 'Interview 5: Completion Status',
    interview6_date_time: 'Interview 6: Date and time',
    interview6_completion_status: 'Interview 6: Completion Status',
    gift_card: 'Gift Card',
    transcribed: 'Transcribed',
    referral: 'Referral',
    race: 'Race',
    reason: 'Reason',
    interview1_staff: 'Interview 1: Staff',
    interview2_staff: 'Interview 2: Staff',
    interview3_staff: 'Interview 3: Staff',
    interview4_staff: 'Interview 4: Staff',
    interview5_staff: 'Interview 5: Staff',
    interview6_staff: 'Interview 6: Staff',
    shelf_number: 'Shelf Number',
    tube_rack_number: 'Tube Rack Number',
    sample_position_in_tube_rack: 'Sample Position in Tube Rack',
    link_or_card: 'Link or Card',
    card: 'Card',
    ra_staff: 'RA/ Staff',
    date_sent: 'Date Sent',
    date_receipt_received: 'Date Receipt Received',
    date_of_activation: 'Date Of Activation If Sent By Mail',
    gift_card_voucher: 'Gift Card & Voucher',
    tracking: 'Tracking',
    unassigned_report: 'Unassigned Report',
    insurance: 'Insurance',
    participant_info_report: 'Participant Info Report',
    validate: 'Validate',
    verify: 'Verify',
    answer_id: 'Answer ID',
    instrument_id: 'Instrument ID',
    language_id: 'Language ID',
    question_id: 'Question ID',
    deleted_quetions_answers: 'Deleted Questions Answers',
    triggered_quetions_answers: 'Triggered Questions Answers',
    no_parent_quetions_answers: 'No Parent Triggered Questions Answers',
    deleted_options_answers: 'Deleted Options Answers',
    incomp_report: 'Incompletion Report',
    partial_comp_report: 'Partially Completed Report',
    last: 'Last',
    contact: 'Contact',
    details: 'Details',
    of: 'Of',
    reluctance: 'Reluctance',
    severity: 'Severity',
    contacted: 'Contacted',
    who: 'Who',
    not_contacted_yet: 'Not contacted yet',
    not_interviewed_yet: 'Not interviewed yet',
    total_assigned: 'Total Assigned',
    not_contacted_and_interviewed_report: 'Not Contacted & Interviewed Report',
    send: 'Send',
    cancel: 'Cancel',
    amount: 'Amount',
    event: 'Event',
    survey: 'Survey',
    coordinator_only: 'Coordinator Only',
    date_of_signature: 'Date of Signature',
    date_of_filing: 'Date of Filing',
    date_of_interview: 'Date of Interview',
    name_of_subject: 'Name of Subject',
    signature_of_subject: 'Signature of Subject',
    interviewer_initials: 'Interviewer Initials',
    dear: 'Dear',
    gift_card_para_1: 'Thank you for your recent participation in our research! Community members like you play an important role in our work, and we thank you again for adding your voice to our community. We could not do this without you!',
    gift_card_para_2_sentence_1: 'As discussed, enclosed is your gift card in the amount of ',
    gift_card_para_2_sentence_2: ' This card can be used at any store or online merchant nationwide. To use the card, follow the instructions emailed to you and sign the receipt below to acknowledge receiving the card. Should you have any questions or difficulties in completing the form, please contact us at: ',
    gift_card_para_2_sentence_3: ' or call us at ',
    gift_card_para_3_sentence_1: "And don't forget to join our community. From seminars, to outreach, to our ongoing research, there is always something happening. If you are interested in learning more about us and future events, just visit our website, ",
    gift_card_para_3_sentence_2: " We couldn't do what we do without your support!",
    gift_card_para_4_sentence_1: "Thank you for taking part in this study. By signing below, you acknowledge receiving ",
    gift_card_para_4_sentence_2: " for participation in this study.",
    sincerely: "Sincerely",
    gift_card: 'Gift Card',
    organization_line: 'Organization Line',
    address_line: 'Address Line',
    website: 'Website',
    handle: 'Handle',
    fax: 'Fax',
    logo: 'Logo',
    team: "Team",
    review: 'Review',
    would_you_like_to_review: "Would you like to review the consent before submitting the interview?",
    consent_warning_note: "Note: After interview completion, the consent will be locked, and you will not be able to modify the answers. Aww yeah, you successfully read this important alert message. This example text is going to run a bit longer so that you can see how spacing within an alert works with this kind of content.",
    updated_at: 'Updated At',
    recent: 'Recent',
    social_media: 'Social Media',
    url: 'URL',
    image: 'Image',
    interview_expiration_date: 'Interview Expiration Date',
    redirect_url: 'Redirect Url',
    saliva: 'Saliva',
    ID: 'ID',
    collected: 'Collected',
    in_person_or_mail: 'In person or Mail',
    arrival_date: 'Arrival Date',
    mandatory_survey: 'Mandatory Survey',
    supplemental_survey: 'Supplemental Survey',
    actigraphy: 'Actigraphy',
    blood_draw: 'Blood Draw',
    signature: 'Signature',
    mail: 'Mail',
    errors: 'Errors',
    oa_ma_report: 'OA & MA Report',
    percent_completion_of: 'Percent Completion Of',
    wave: 'Wave',
    criteria: 'Criteria',
    report: 'Report',
    physical: 'Physical',
    cognitive_assessment: 'Cognitive Assessment',
    assessment: 'Assessment',
    lab: 'Lab',
    sent: 'Sent',
    signature_of_investigator: 'Signature of Investigator/Individual Obtaining Consent/Assent',
    to_the_best_ability: 'To the best of my ability, I have explained and discussed the full contents of the study including all of the information contained in this consent form. All questions of the research subject and those of his/her parent or legal guardian have been accurately answered.',
    investigator_person: 'Investigator/Person Obtaining Consent Name (Print)',
    outbound_tracking_number: 'Outbound Tracking Number',
    outbound_ship_date: 'Outbound Ship Date',
    inbound_tracking_number: 'Inbound Tracking Number',
    inbound_ship_date: 'Inbound Ship Date',
    storage_date: 'Storage Date',
    shelf: 'Shelf',
    additional_details: 'Additional Details',
    sent_via_email: "if sent via email",
    signed: 'Signed',
    responded: 'Responded',
    family: 'Family',
    cohort: 'Cohort',
    close: 'Close',
    tree: 'Tree',
    validation: 'Validation',
    variable: 'Variable',
    duplicate: 'Duplicate',
    hospital: 'Hospital',
    field_is_embedded: 'Field is embedded elsewhere',
    enumeration: "Enumeration",
    eligible: 'Eligible',
    not_available: 'Not Available',
    follow_up: 'Follow Up',
    reminder: 'Reminder',
    scheduling: 'Scheduling',
    location: 'Location',
    component: 'Component',
    trigger: 'Trigger',
    show: "Show",
    is_enumeration_informant: 'Is Enumeration Informant?',
    probability_or_immigrant_sample: 'Probability or Immigrant sample?',
    full: 'Full',
    total_household_members: 'Total Household Members',
    total_family_members: 'Total Family Members',
    download: 'Download',
    progress: "Progress",
    additional_display_information: 'Additionl Display Information',
    self_directed_interview: "Self Directed Interview Completion Time Display Screen",
    required: 'Required',
    this_questionnaire: "This Questionnaire will take approximately",
    to_complete: "to complete.",
    complete_questionnaire: "Complete Questionnaire",
    now: 'Now',
    later: 'Later',
    web_or_phone: 'Web Or Phone',
    in_person_dispatch: 'In Person Dispatch',
    ssrs: 'SSRS',
    biospecimen: 'Biospecimen',
    kit: 'Kit',
    shipment_carrier: 'Shipment Carrier',
    outbound: 'Outbound',
    inbound: 'Inbound',
    condition_on_receipt: 'Condition on Receipt',
    laboratory_receipt_date: 'Laboratory Receipt Date',
    laboratory_receipt_condition: 'Laboratory Receipt Condition',
    specimen_type: 'Specimen Type',
    lab_biospecimen_processing: 'Lab Biospecimen Processing',
    storage_information: 'Storage Information',
    storage_location: 'Storage Location',
    storage_temperature: 'Storage Temperature',
    storage_start_date: 'Storage Start Date',
    review_date: 'Review Date',
    spinning_date: 'Spinning Date',
    aliquoting_date: 'Aliquoting Date',
    sample_position_in_rack: 'Sample Position in Rack',
    rack_number: 'Rack Number',
    google_analytics: 'Google Analytics',
    measurement: 'Measurement',
    update_and_resend: 'Update and Resend Gift Card',
    update_without_resend: 'Update Without Resending Gift Card',
    multimedia: 'Multimedia',
    format: 'Format',
    optional_file_attachment: 'File attachment image, audio or video (Optional):',
    provide_video_url: 'Provide video URL',
    workflows: 'Workflows',
    workflow: 'Workflow',
    add_interview_workflow: 'Add Interviews in the workflow',
    active_after_completion: 'Active after completion of previous questionnaire.',
    flow: 'Flow',
    econsent: 'E-consent',
    duration: 'Duration',
    unit: 'Unit',
    active_after_consent: 'Active after completion of e-consent',
    active_in: 'Active in',
    after_completion: 'after completion of previous questionnaire',
    active_on_after: 'Active on/after',
    active_condition: 'Active condition',
    active_on_date: 'Active On Date',
    biospecimen_kit_status: 'Biospecimen Kit Status',
    and_kit_status: "and when previous interview's biospecimen kit status is",
    active_instantly: 'Active <strong> instantly </strong>',
    screening_tool: 'Screening Tool',
    information: 'Information',
    study: 'Study',
    video_acknowledgment: 'Have you completed watching the entire video?',
    yes: 'Yes',
    no: 'No',
    step: 'Step',
    study_overview: 'Study Overview Video',
    continue: 'Continue',
    not_activated_yet: 'Interview Not Activated Yet!',
    not_finished: 'Not Finished!',
    stage: 'Stage',
    disagreed: 'Disagreed',
    eligible_for_study: 'Eligible for study!',
    ineligible_for_study: 'Ineligible for study!',
    agreed: 'Agreed',
    optional_screening_tool_eligibility_condition: 'Screening Tool Eligibility Condition (Optional)',
    condition: 'Condition',
    value: 'Value',
    seperate_multiple_values: 'Separate multiple values with commas. The condition will be true if any one matches.',
    overview: 'Overview',
    video: 'Video',
    print: 'Print',
    individual: 'Individual',
    please_enter_email: 'Please enter your email address',
    change: 'Change',
    self_collect_blood_permission: 'Are you interested in a self-collected blood draw using the TAP Micro device.',
    i_am_in: 'I am in',
    i_am_out: 'I am out',
    member: 'Member',
    and_when_econsent_agreed: 'and when e-consent is agreed.',
    text: 'Text',
    hint: 'Hint',
    placeholder: 'Placeholder',
    default: 'Default',
    introduction: 'Introduction',
    identifier_info_text: 'Does the field contain identifying information (e.g. name, SSN, address)',
    required_info_text: '* Prompt if field is blank',
    variable_name_info_text: 'Only letters, numbers & underscores',
    disable_info_text: "Choose 'Yes' to hide, 'No' to keep this question active.",
    help_info_text: "Additional information below the question",
    placeholder_info_text: "Enter text to display inside the field.",
    default_value_info_text: "Automatically fill this field with a preset value.",
    auto: 'Auto',
    check_errors_other_lang_instruments: "Check for errors in interview's other language instruments!",
    check_errors_other_lang_instruments_info: "Ensure accuracy in interview instruments by reviewing for potential issues such as mismatched question counts in other language instruments, discrepancies in question types, options count, and validations like whether a question is required, has comments, or serves as an identifier.",
    participants_having_deleted_questions_answers: "Participants having deleted question's answer in the interview!",
    participants_having_deleted_questions_answers_info: "Verify if participants have submitted answers for questions that were deleted from the instruments in the interview.",
    check_errors_other_lang_consent: "Check for errors in other language consent!",
    check_errors_other_lang_consent_info: "Ensure accuracy in consent by reviewing for potential issues such as mismatched section or question counts in other language consent, discrepancies in question types, options count, and validations like whether a question is required.",
    multilingual: 'Multilingual',
    stay: 'Stay',
    leave: 'Leave',
    workflow_not_eligible_text: `Thank you for your interest in the ${constant.app_name}! Unfortunately, at this time, your responses indicate that you would not be eligible to participate in the study. Please feel free to help spread the word about this study to friends or family members that you think may be eligible to join the study.`,
    congratulations: 'Congratulations',
    congratulations_screen_text: `Based on the answers you provided in the screening survey, <h5 class='d-inline'> you are eligible to participate in the study. </h5> Be sure to watch the ${constant.app_name} overview video to learn more about the study and what’s involved in participating to see if the study is the right fit for you.`,
    best_wishes: 'Best Wishes',
    the_ks_team: `${constant.email_app_sign_full}`,
    show_input_field: 'Show Input Field',
    aliquot: 'Aliquot',
    specimen: 'Specimen',
    freezer: 'Freezer',
    tube_freezer_storage_box_name: 'Tube Freezer Storage Box Name',
    tube_freezer_storage_box_position_name: 'Tube Freezer Storage Box Position Name',
    source: 'Source',
    pregnant: 'Pregnant',
    rows: 'Rows',
    columns: 'Columns',
    create: 'Create',
    storage: 'Storage',
    box: 'Box',
    leave_empty_to_auto_generate: 'Leave blank to automatically generate value',
    please_select_freezer_stoarage_box: 'Please select freezer storage box',
    click_icon_create_storage_box: 'Click the icon to create a new freezer storage box',
    click_icon_select_storage_position: 'Click the icon to select a freezer storage box position',
    participation_in_study_closed_out: "Your participation in the study has been closed out.",
    please_enter_email_or_phone: "Please enter your email address or phone number",
    preferred_method_of_communication: 'Preferred Method Of Communication',
    opt_in: 'Opt In',
    opt_out: 'Opt Out',
    cheatblocker: "Cheatblocker",
    compare_dates_by: 'Compare Dates By',
    time_period: 'Time Period',
    criteria: 'Criteria',
    field: 'Field',
    in: 'In',
    interview_answer_condition: 'Interview Answers Condition',
    after_previous_answer_provided: "questionnaire's <strong> required answer provided </strong>",
    even_after_previous_answer_missing: "even after previous questionnaire's <strong> required answer missing </strong>",
    depends_on_previous_interview: 'Depends On Previous Interview',
    answer_provided_for_interview: 'Answer Provided For Interview',
    required_interview: 'Required Interview',
    after: 'after',
    its_kit_status: "and when it's biospecimen kit status is",
    after_completion_of_econsent: 'after completion of econsent',
    to_activate_this_interview: 'to activate this interview',
    filled_returned: 'Filled & Returned',
    urine: 'Urine',
    browse_files: 'Browse Files',
    upload_files: 'Upload Files',
    media: 'Media',
    path: 'Path',
    resolution: 'Resolution',
    size: 'Size',
    uploading: 'Uploading',
    contact_form_message: `Welcome to the ${constant.app_name}! Please take a moment to share your contact information with us so that we can stay in touch with updates about the study.`,
    after_interviews_kit_status: "interviews's <strong> biospecimen kit status </strong> is",
    active_on_condition: 'Active on condition',
    active_without_answer_kit_submission: 'Active without interview answer and biospecimen kit submission',
    active_after_4_weeks_final_reminder: "Or active after 4 weeks from final reminder of required interview's survey / kit",
    active_after_postnatal: "after postnatal period",
    zipcode: 'Zipcode',
    are_this_true: "Are either of these currently true?",
    no_longer_pregnant: "You are no longer pregnant",
    moved_out_of_state: "You have moved out of state",
    not_meet_criteria_for_study: "Thank you for your interest. Unfortunately, you do not meet the eligibility criteria for this study.",
    workflow_not_eligible_text_2: `Thank you for your interest in the ${constant.app_name}! Since our study has very specific requirements to participate, not everyone is eligible to join the study. Unfortunately, at this time, your answers indicate that the <h5 class='d-inline'> eligibility requirements for the study were not met. </h5> We wanted to send a special guide with helpful community resources for pregnant individuals and parents. You can download the guide here. A copy will also be sent to your email. We would love to stay in touch to let you know about the things we learn from this study. Be sure to visit us at ${constant.email_app_public_website}  for newsletters and updates.`,
    study_video_title: "Learn more about the study and what's involved by watching this short video.",
    email_app_sign_full: `The ${constant.email_app_sign_full}`,
    warmest_regards: 'Warmest Regards',
    questionnaire_completed_subtext: "Thank you for completing your questionnaire! Our team is getting your next sample kit ready for shipment. We'll let you know when your kit has shipped and is on its way to you. Wishing you a great week ahead.",
    questionnaire_completed: "Questionnaire Completed!",
    participation_close_subtext: `Thank you so much for participating in the ${constant.app_name}! On behalf of our team, we are very grateful that you spent some time participating in the study. We just wanted to let you know that your participation in the study has been closed out. If you have any questions for our team or would like to rejoin the study at some other time in the future, feel free to reach out via email at ${constant.app_email} or phone ${constant.app_phone}.`,
    participation_close_title: "Participation in the Study Closed Out!",
    link_expire_subtext: `Thank you so much for participating in the ${constant.app_name}! On behalf of our team, we are very grateful that you spent some time participating in the study. We wanted to inform you that the link to the interview you tried to access has expired. If you have any questions for our team feel free to reach out via email at ${constant.app_email} or phone ${constant.app_phone}.`,
    link_expire_title: "Interview Link Expired!",
    rollover_question: 'Rollover Question',
    rollover_question_info_text: "Is this question rollover from another interview?",
    rollover_question_variable_info_text: "Please enter the label/variable name for the question you wish to roll over.",
    rollover_question_interview_info_text: "Please select the interview of the question you wish to roll over.",
    workflow_rollover_question_settings: "Workflow Rollover Question Settings (Optional)",
    interview_completed_message: "Congratulations! Your interview is 100% completed. Thank you for your time and participation!",
    triggers_no_longer_pregnant: "Selecting 'No' will trigger email to participant that they are no longer pregnant.",
    no_files_to_show: "No Files to show!",
    drag_and_drop_files: "Drag 'n' drop some files here, or click to select files",
    file_upload_limit: "File upload limit: 500MB maximum per file.",
    files: "Files",
    loading: "Loading",
    are_you_sure_to_delete: "Are you sure you want to delete this entry?",
    something_went_wrong: "Something went wrong. Please try again later.",
    contact_information_updated_message: "Your contact information has been successfully updated!",
    answer_all_required_que: "Please answer all the (*) required questions!",
    your_consent_is_recorded: "Your consent has been successfully recorded!",
    max_files: "Max 5 files",
    each_file_less_than: "Each file must be less than 2Mb",
    interview_saved_successfully: "Interview Saved Successfully!",
    unable_to_save_interview: "Unable to save interview!",
    unable_to_save_contact_info: "Unable to Update Participant Contact Info! Try Again",
    i_agree: "I Agree",
    disagree: "Disagree",
    forgotten_password: "Forgotten password?",
    login: "Login",
    sign_in_to_your_account: "Sign In to your account",
    login_as: "Login as",
    login_welcome_text: `Welcome to the ${constant.app_name} Dashboard! Please login to your account with your credentials [username and password]. Thank you for your support in advancing child health!`,
    login_with_password: "Login with password",
    continue: "Continue",
    password_reset_link_sent: "Password reset link has been sent to your email. Please check your inbox to proceed.",
    link_valid_for_note: "Note that the link will be valid for one hour.",
    please_enter_email: "Please enter your email",
    my_profile: "My Profile",
    change_password: "Change password",
    logout: "Logout",
    password_changed_success: "Password successfully changed. Click the 'Login' button to log in with your new credentials.",
    password_reset_link_expire: "The password reset link has expired or contains an invalid token.",
    mark_as_bookmark_message: `To bookmark this page:\n - Press Ctrl/Cmd + D, or\n - Click on the star icon in the address bar, or\n - Open the browser menu and select 'Bookmark This Page'.`,
    lock_temporarily: "Lock Temporarily",
    interview_temporarily_locked: "Interview Temporarily Locked",
    interview_locked_subtext: `Thank you so much for participating in the ${constant.app_name}! On behalf of our team, we are very grateful that you spent some time participating in the study. We wanted to inform you that the interview you tried to access is currently locked. If you have any questions or need assistance, feel free to reach out to our team via email at ${constant.app_email} or phone ${constant.app_phone}.`,
    unsaved_changes_message: "You have unsaved changes. Are you sure you want to leave?",
    search: "Search",
    find: "Find",
    clear: "Clear",
    study_status: "Study Status",
    url_copied_success: "URL copied successfully!",
    send_immediately: "Send Immediately",
    send_after_lapse: "Send after lapse of time:",
    send_at_exact: "Send at exact date/time",
    send_before_six: "Send before 6 hours of the scheduled time",
    send_before_one: "Send before 1 hours of the scheduled time",
    just_once: "Just Once",
    multiple_times: "Multiple times: Send every",
    after_initially_sent: "after initially being sent",
    optional: "Optional",
    click_to_remove_email: "Click to remove email",
    or: "Or",
    take_picture: "Take Picture",
    camera: "Camera",
    selected_files: "Selected Files",
    retake: "Retake",
    capture_photo: "Capture Photo",
    switch_to: "Switch To",
    done: "Done",
    front: "Front",
    rear: "Rear",
    stop: "Stop",
    notification: "Notification",
    unsave_biospecimen_warning_message: "You have unsaved biospecimen changes. Are you sure you want to leave?",
    unsave_specimen_warning_message: "You have unsaved specimen type details changes. Are you sure you want to leave?",
    unsave_aliquoting_specimen_warning_message: "You have unsaved aliquoting specimen changes. Are you sure you want to leave?",
    biospecimen_saved: "Biospecimen saved successfully!",
    specimen_type_saved: "Specimen type details saved successfully!",
    aliquot_specimen_saved: "Aliquot Specimen type saved successfully!",
    are_you_sure_to_delete_this_entry: "Are you sure to delete this entry?",
    and: 'and',
    return_later: 'Return later',
    minimum: 'Minimum',
    maximum: "Maximum",
    minimum_value_info_text: "Required minimum value of the question",
    maximum_value_info_text: "Required maximum value of the question",
    error_messages: "Error Messages (Optional)",
    error: "Error",
    date_format: "Date/Datetime/Time",
    alpha: "Letters Only",
    numeric: "Number",
    digits: "MRN (10 digits/generic) / Phone",
    integer: "Integer",
    decimal: "Decimal Number",
    regex: "Zipcode",
    after_or_equal: "Minimum Date",
    before_or_equal: "Maximum Date",
    min: "Minimum Number",
    max: "Maximum Number",
    read_resource_guide: `- To read the English resource guide, <a href='${constant.english_resource_guide_link}'> click here </a>`,
    interview_complete_1: "You have completed your ",
    interview_complete_2: ` for the ${constant.app_name_full}. `,
    interview_complete_3: `To thank you for your participation, check out the NJKS resource guide, English <a href='${constant.english_resource_guide_link}'>click here</a>, Espanol <a href='${constant.spanish_resource_guide_link}'> click here </a> which is full of information to support healthy pregnancies and families in New Jersey.`,
    interview_complete_4: `You should receive your first Biospecimen Kit in the mail in 1-2 weeks. To learn more about what to expect, watch our ${constant.app_name} Biospecimen Box Opening Video <a href='${constant.biospecimen_box_opening_video_link}'>here</a>. If you have any questions or concerns, contact us at ${constant.email_app_email} or ${constant.email_app_phone}. You can access your Participant Dashboard at <a href='${constant.app_link}'>Login</a>. <br/> <br/> <strong> Thank you for supporting the ${constant.research_study_name}! </strong>`,
    questionnaire: 'Questionnaire',
    form: "Form",
    header: "Header",
    footer: "Footer",
    show_popup_message: "Show Popup Message",
    popup_message: "Popup Message",
    popup_condition: "Popup Condition",
    popup_message_info_text: "Show popup message when question is answered",
    operator: "Operator",
    please_enter_exact: "Please Enter Exact",
    ok: "Ok",
    url_copied: "Url copied successfully!",
    failed_url_copy: "Failed to copy URL!",
    remap_languages: "Remap Languages",
    remap_languages_successfully: "Remap languages successfully!",
    remap_languages_failed: "Failed to remap languages!",
    are_you_sure_to_remap: "Are you sure to remap languages of instruments?",
    study_introduction: "Study Introduction",
    first_name: "First Name",
    last_name: "Last Name",
    cell_phone: "Cell Phone",
    home_phone: "Home Phone",
    work_phone: "Work Phone",
    this_is_required_field: "This is a required field",
    if_you_are_not_eligible_for_study: "If you are not eligible for this study and believe this is an error, please don't hesitate to contact us.",
    to_continue_survey_check_email: "To continue your survey, log in using the credentials provided in the email.",
    login_credential_sent_to_your_below_contact_info: "Your login credentials have been sent to your registered email and phone number as shown below:",
    you_are_best_we_appreciate: "You’re the Best! We really appreciate you taking the time to complete this interview.",
    to_learn_more_about_study: "To learn more about the study,",
    click_here: "Click here.",
    kit_will_be_sent_to_address: "Your kit will be sent to the address you provided. If the address is not correct, please log in to your dashboard to update your contact information or reach out to the study team.",
    resources: "Resources",
    resources_text: `<h3 class='text-center'> Lorem Ipsum </h3> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. <br/> Lorem ipsum dolor sit amet <a target='__blank' href='https://google.com'>Click Here</a> <br/> Lorem ipsum dolor sit amet <a target='__blank' href='https://google.com'>Click Here</a>`,
    spanish_interview_name: "Spanish Interview Name",
    volume: "Volume",
    unsigned_giftcard_notifications_disabled: 'Disable Unsigned Gift Card Notifications',
    unsigned_giftcard_notificatipns_disabled_warning_message: "Please note that unsigned gift card notifications have been disabled, and participants will no longer receive gift card reminder notifications.",
    notification_history: "Notification History",
    sms: "SMS",
    sent_at: "Sent at",



  },

}

export default language
